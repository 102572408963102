import Star from './Star';

function Score(props) {
  return (
    <span>
      <Star score={props.score>0 ? 1 : 0} />
      <Star score={props.score>1 ? 1 : 0} />
      <Star score={props.score>2 ? 1 : 0} />
      <Star score={props.score>3 ? 1 : 0} />
    </span>
  );
}

export default Score;
