import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import PosNegScore from './PosNegScore';
import StarLine from './StarLine'

function HolisticdScore(props) {
  if (props.criteria == null) {
    return null;
  }
  var totalScore = 0;
  var succeedScore = 0;
  for (var eachCriteria in props.criteria) {
    if (props.criteria[eachCriteria].is_satisfied == true) {
      succeedScore += 1;
    }
    totalScore += 1;
  }
  return (
    <div>
      <h4><StarLine total={totalScore} numStar={succeedScore} /></h4>
    </div>
  )
}

export default HolisticdScore;
