import Star from './Star'

function HeaderedPosNegScore(props) {
  if (props.header == null || props.score == null) {
    return null;
  }
  return (
    <div>
      <h5><Star score={props.score ? 1 : 0} />: {props.header}</h5>
    </div>
  );
}

export default HeaderedPosNegScore;
