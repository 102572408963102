import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';

import { NavLink } from 'react-router-dom';

function Navibar(props) {
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          <img style={{ 'height': '2rem' }} src={"/deepgrade-logo.svg"} />
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={NavLink} to="/english">英作文採点デモ</Nav.Link>
            <Nav.Link as={NavLink} to="/japanese">国語記述採点デモ</Nav.Link>
          </Nav>
          
        </Navbar.Collapse>
        <div className='justify-content-end'>
        <Button 
          className="m-2"
          variant="primary"
          href="https://www.edulab-inc.com/deepgrade"
        >
          特設サイト
        </Button>
        <Button 
          className="m-2"
          variant="primary"
          href="https://share.hsforms.com/1hAxe_FgsSuyyQE2xv7KhFg8mxiv"
        >
          お問い合わせ
        </Button>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </div>
      </Container>
    </Navbar>
  )
}

export default Navibar;