import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaceSmile as farFaceSmile } from '@fortawesome/free-regular-svg-icons'
import { faFaceFrown as farFaceFrown } from '@fortawesome/free-regular-svg-icons'
import { faFaceDizzy as farFaceDizzy } from '@fortawesome/free-regular-svg-icons'


function SentenceScore(props) {
  let icon = null;
  switch (props.score) {
    case 0:
      icon = <FontAwesomeIcon icon={farFaceSmile} style={{color: "green"}}/>
      break;
    case 1:
      icon = <FontAwesomeIcon icon={farFaceFrown} style={{color: "orange"}}/>
      break;
    case 2:
      icon = <FontAwesomeIcon icon={farFaceDizzy} style={{color: "red"}}/>
      break;
  }
  return (<div><h5>{props.header} {icon}</h5></div>);
}

export default SentenceScore;
