import TextDiff from './TextDiff';

function TextCorrection(props) {
  let org_txt = props.org_txt;
  let cor_txt = props.cor_txt;
  const mergedDiff = new TextDiff().getDiff(org_txt, cor_txt);
  return mergedDiff.map((part, i) => {
    // green for additions, red for deletions
    // black for common parts
    const color = part.added ? 'green' : part.removed ? 'red' : 'black';
    const decoration = part.removed ? 'line-through':'none'
    return <span style={{color: color, 'fontFamily': "monospace", 'textDecoration': decoration}} key={i}>{part.value}</span>;
  });
}

export default TextCorrection;
