import React from "react";
import { criteriaData } from "./CriteriaData";
import CriteriaEachCommentary from "./CriteriaEachCommentary";
import Card from 'react-bootstrap/Card';

class CriteriaCommentary extends React.Component {
  render() {
    if (
      this.props.criteria == null 
      || this.props.qnum == null 
      || this.props.original_essay == null
    ) {
      return null;
    }

    let eachCriterias = [];
    eachCriterias = this.props.criteria.map((eachCriteria, index) => {
      return (
        <div style={{marginBottom: '2rem'}}>
          <CriteriaEachCommentary 
            criteria={eachCriteria} 
            criteriaData={criteriaData[this.props.qnum][eachCriteria.No]} 
            original_essay={this.props.original_essay}/>
        </div>
      )
    });
    if (eachCriterias.length === 0) {
      return null;
    }
    return (
      <Card>
        <Card.Header as="h3">観点別評価・解説</Card.Header>
        <Card.Body>
          {eachCriterias}
        </Card.Body>
      </Card>
    )
  }
}

export default CriteriaCommentary;