import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

function QuestionInformation(props) {
  let elements = [
    "私は納豆をごはんにのせて食べるのが大好きです。今回は大好きな納豆について、納豆を自分で作る方法と、その歴史について調べました。納豆は、実は自分の家でも作ることができます。",
    "納豆を作るためには、しっかりと蒸した大豆と納豆きんが必要です。大豆に納豆きんを加えて、40～45℃で湿度90％以上のところに置いて1日か2日待つと、納豆ができあがります。大事なのは温度や湿度、時間のバランスです。温度や湿度が足りないと、大豆がかんそうしてしまったり、時間をかけすぎると、臭くなってしまいます。",
    "昔は、蒸した大豆を稲わらで包んで、ほどよい温度を保って一日置いて納豆を作っていました。稲わらについていた納豆きんが大豆に移って、きんが増えることで納豆ができたのです。",
    "納豆のはじまりについては、色々な話があるそうです。一つは、馬のえさの大豆が残ったため、聖徳太子がわらで包んでおいたら納豆ができた、という話です。もう一つは、昔の戦いの時に、農民が提供した大豆のわら包みが、糸を引いて良い匂いが出てきて、それが食べられるようになった、という話です。どちらの話も、稲わらについた納豆きんが自然に増えて納豆ができたというところがいっしょです。",
    "しかし最近は、人工的に納豆きんを増やして納豆を作ることが多くなりました。それを初めて考えたのは半澤洵（はんざわじゅん）さんという人で、納豆きんを人工的に増やす方法と、きれいで安心して食べられる納豆の作り方を研究しました。",
    "蒸した大豆に人工的に増やした納豆きんをかけて容器に入れ、適度な温度で6時間ほど保温すると、納豆きんが増えて大豆が発こうします。そして18～24時間後に容器を冷やすことで発こうを止めて、出荷する時は10℃以下の温度に保つことで、納豆がおいしいままになるよう工夫しています。",
    "今回納豆について調べたことで、納豆を作る際の工夫や、歴史を知ることができ、納豆のことがさらに好きになりました。次回は、納豆以外の食べ物についても、作り方や歴史を調べてみたいです。"
  ]
  const element_part = elements.map((element, index) => {
    return (
      <div style={{ 'textIndent': '1rem'}}>{element}</div>
    )
  });
  return (
    <Card>
      <Card.Header as="h5">問題</Card.Header>
      <Card.Body>
      <h5>クラスの友達が、納豆がどのように作られるかについて調べてまとめた次の【作文】を読んで、あとの設問に答えましょう。</h5>
      <Card style={{'marginTop': '1rem'}}>
        <Card.Header>【作文】</Card.Header>
        <Card.Body>
          {element_part}
        </Card.Body>
      </Card>
      <div style={{'textAlign': 'right', 'marginBottom': '1rem', 'fontSize': '90%'}}>※【作文】の文章は<a href="https://ja.wikipedia.org/w/index.php?title=%E7%B4%8D%E8%B1%86&oldid=96503178#%E4%BD%9C%E3%82%8A%E6%96%B9">納豆#作り方</a>を翻案し作成しました。この文章は<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC BY-SA 3.0</a>に従い利用できます。</div>
      <h5 style={{ 'marginTop': '2rem' }}>設問</h5>
      <h5>【作文】を読んだ感想を、次の条件に合わせて書きましょう。</h5>
      <h5>〈条件〉</h5>
      <h5>〇 納豆の作り方について分かったことを、【作文】の内容から、言葉や文を取り上げて書くこと。</h5>
      <h5>〇 丁寧な言葉で書くこと。</h5>
      <h5>〇 四十字以上、六十字以内にまとめて書くこと。</h5>
      </Card.Body>
    </Card>
  )
}

export default QuestionInformation;