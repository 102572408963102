import './App.css';

import React, { Component, useState } from "react";
import ReactDOM from 'react-dom/client'
import ReactDOMServer from 'react-dom/server'
import Container from 'react-bootstrap/Container';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Dna } from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleDot as fasCircle } from '@fortawesome/free-solid-svg-icons'
import Card from 'react-bootstrap/Card';
import ComplexityScore from './ComplexityScore';
import HeaderedScore from './HeaderedScore';
import SentenceCommentary from './SentenceCommentary';
import { SampleQuestion, SampleEssay, SampleResult } from './SampleData';
import QuestionInformation from './QuestionInformation';
import HolisticdScore from './HolisticScore';
import CriteriaCommentary from './CriteriaCommentary';

class Content extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question: SampleQuestion,
      essay: SampleEssay['誤答例'],
      loading: false,
      ruikei: null,
      holistic_score: null,
      criteria: null,
      answer_text: null,

      content: 0,
      coherence: 0,
      grammar: 0,
      vocabulary: 0,
      grammar_complexity: null,
      vocabulary_complex: null,
      contentResult: {
        p0_checklist_results: null,
        p1_checklist_results: null,
      },
      coherenceResult: null,

      showResult: true
    };
  }

  componentDidMount() {
    this.displayResult(SampleResult['誤答例']);
  }

  // handleQuestionChange = (event) => {
  //   this.setState({
  //     question : event.target.value,
  //     essay : this.state.essay,
  //     loading : false,
  //     ruikei: null,
  //     holistic_score: null,
  //     criterias: null,
  //     showResult : true,
  //   });
  // }

  handleExampleChange = (event) => {
    if (event.target.innerHTML in SampleEssay 
        && event.target.innerHTML in SampleResult) {
      this.setState({
        essay: SampleEssay[event.target.innerHTML],
      });
      this.displayResult(SampleResult[event.target.innerHTML]);
    }
    else{
      this.setState({
        essay: '',
        loading: false,
        showResult: false,
      });
    }
  }

  handleEssayChange = (event) => {
    this.setState({
      question: this.state.question,
      essay: event.target.value,
      loading: false,
      ruikei: this.state.ruikei,
      holistic_score: this.state.holistic_score,
      criteria: this.state.criteria,
      showResult: true,
    });
  }

  handleContentSubmit = async (event) => {
    this.setState({
      question: this.state.question,
      essay: this.state.essay,
      loading: true,
      ruikei: this.state.ruikei,
      holistic_score: this.state.holistic_score,
      criteria: this.state.criteria,
      showResult: false,
    });

    const reqOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({
        question: this.state.question,
        essay: this.state.essay,
      })
    };
    const response = await fetch("/score/japanese", reqOptions);
    const result = await response.json();

    this.displayResult(result);
  }

  displayResult(result) {
    // let grade = "G20";

    // let grammar_complex = null;
    // if (grade == "G15") {
    //   grammar_complex = result.grammar_vocabulary.grammar_complexity_score >= 4;
    // } else if (grade == "G20") {
    //   grammar_complex = result.grammar_vocabulary.grammar_complexity_score >= 1;
    // }

    // let vocabulary_complex = null;
    // if (grade == "G15") {
    //   vocabulary_complex = result.grammar_vocabulary.vocabulary_complexity_score >= 0.37;
    // } else if (grade == "G20") {
    //   vocabulary_complex = result.grammar_vocabulary.vocabulary_complexity_score >= 0.15;
    // }

    // let p0_org_txt = '';
    // let p0_cor_txt = '';
    // let p1_org_txt = '';
    // let p1_cor_txt = '';
    // let org_txt = '';
    // let cor_txt = '';
    // let sentences = result.grammar_vocabulary.sentences;
    // if (sentences) {
    //   for (let i = 0; i < sentences.length; i++) {
    //     org_txt += sentences[i].org_sent + ' ';
    //     cor_txt += sentences[i].cor_sent + ' ';
    //     if (sentences[i].tag == 'R0' || sentences[i].tag == 'EX0') {
    //       p0_org_txt += sentences[i].org_sent + ' ';
    //       p0_cor_txt += sentences[i].cor_sent + ' ';
    //     }
    //     if (sentences[i].tag == 'R1' || sentences[i].tag == 'EX1') {
    //       p1_org_txt += sentences[i].org_sent + ' ';
    //       p1_cor_txt += sentences[i].cor_sent + ' ';
    //     }
    //   }
    // }

    this.setState({
      // question: this.state.question,
      // essay: this.state.essay,
      loading: false,
      ruikei: result.ruikei,
      holistic_score: result.holistic_score,
      criteria: result.criteria,
      showResult: true,
      answer_text: result.answer_text,

      // content: result.content.sys_content,
      // coherence: result.coherence.sys_coherence,
      // grammar: result.grammar_vocabulary.sys_grammar,
      // vocabulary: result.grammar_vocabulary.sys_vocabulary,
      // grammar_complex: grammar_complex,
      // vocabulary_complex: vocabulary_complex,
      // contentResult: result.content,
      // coherenceResult: result.coherence,
      // p0_org_txt: p0_org_txt,
      // p0_cor_txt: p0_cor_txt,
      // p1_org_txt: p1_org_txt,
      // p1_cor_txt: p1_cor_txt,
      // org_txt: org_txt,
      // cor_txt: cor_txt,
      // showResult : true,
    })
    // const breakdown = document.getElementById('breakdown');
    // const fragment = document.createDocumentFragment();
    // if (result.grammar_vocabulary.sentences) {
    //   for (let sent of result.grammar_vocabulary.sentences) {
    //     const div_sent = document.createElement('div');
    //     div_sent.innerHTML = ReactDOMServer.renderToString(<SentenceCommentary sentence={sent}/>);

    //     fragment.appendChild(div_sent);
    //   }
    // }
    // breakdown.replaceChildren(fragment);

  }

  handleTextAreaSize(e) {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
    // e.target.style.height = `${Math.min(e.target.scrollHeight, 800)}px`;
  }

  render() {
    return (
      <Container className="p-5">
        <Container className="p-3">
          <h1>国語記述自動採点サービス（デモ版）</h1>
        </Container>
        <Container className="p-3">
          <QuestionInformation />
        </Container>
        <Container>
          
        </Container>
        <Container className="p-3">
          <ButtonGroup aria-label="example" className='pb-3 pt-3'>
            <Button variant="secondary" onClick={this.handleExampleChange}>誤答例</Button>
            <Button variant="secondary" onClick={this.handleExampleChange}>正答例</Button>
          </ButtonGroup>
          <textarea
            className="form-control" id="text" name="text" value={this.state.essay}
            onChange={this.handleEssayChange} rows={3} maxLength={100}
            onKeyDown={this.handleTextAreaSize} placeholder="答えを書きましょう。"
            autofocus="true" onFocus={this.handleTextAreaSize} />
        </Container>
        <Container className="p-3">
          <button
            className={`text-capitalize btn btn-primary btn-md fw-bold`}
            disabled={!this.state.question || !this.state.essay}
            onClick={this.handleContentSubmit}>
            採点
          </button>
        </Container>
        <Container className="p-3" style={{ display: this.state.loading ? '' : 'none' }}>
          <Dna height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper" />
        </Container>
        <Container className="p-3" style={{ display: this.state.showResult ? '' : 'none' }} >
          <Card>
            <Card.Header as="h3">総合評価：</Card.Header>
            <Card.Body>
              <HolisticdScore criteria={this.state.criteria} />
            </Card.Body>
          </Card>
        </Container>
        <Container className="p-3" style={{ display: this.state.showResult ? '' : 'none' }} >
          <CriteriaCommentary 
            criteria={this.state.criteria} 
            qnum={this.state.question.question_no} 
            original_essay={this.state.answer_text}/>
        </Container>
      </Container>
    );
  }
}

class App extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: '#F4F7FD' }}>
        <Content />
      </div>
    );
  }
}


export default App;
