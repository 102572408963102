function WordTransition(props){
    if (props.before == null && props.after == null){
        return null;
    }

    let insertion = '挿入';
    let removal = '削除';
    if (props.insertion != null){
        insertion = props.insertion;
    }
    if (props.removal != null){
        removal = props.removal;
    }

    let blanks = [""];
    if (props.blanks!= null){
        blanks = props.blanks;
    }

    if (props.before == null || blanks.includes(props.before)){
        return <span>{props.after}（{insertion}）</span>
    }
    if (props.after == null || blanks.includes(props.after)){
        return <span>{props.before}（{removal}）</span>
    }
    return <span>{props.before}→{props.after}</span>
}

export default WordTransition;