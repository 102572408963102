function Footer() {
  return (
    <div style={{ backgroundColor: '#F4F7FD' }}>
      <footer className="text-center text-lg-start text-muted p-5">
        <div className="text-center">
          <a className="text-reset fw-bold" href="https://www.edulab-inc.com/privacy/">プライバシーポリシー</a>
        </div>
        <div className="text-center">
          <a className="text-reset fw-bold" href="https://www.edulab-inc.com/security/">セキュリティポリシー</a>
        </div>
        <div className="text-center">
          <a className="text-reset fw-bold" href="https://edulab-inc.com/">© 2023 EduLab Inc.</a>
        </div>
      </footer>
    </div>
  )
}

export default Footer;