var Statement = {
  "level4": "以下の質問に対するあなたの意見を120~150語の英文で書いてみましょう。また次のポイントから2つを選び、意見に取り入れましょう。文章にはリード文、あなたの意見を裏付ける理由を二つ、結論を含めてください。",
  "level3": "以下の質問に対するあなたの意見を80語~100語の英文で書いてみましょう。文章にはリード文、あなたの意見を裏付ける理由を二つ、結論を含めてください。",
  "level2": "以下の質問に対するあなたの意見を50語~60語の英文で書いてみましょう。文章にはリード文、あなたの意見を裏付ける理由を二つ、結論を含めてください。",
  "level1": "以下の質問に対するあなたの意見を25語~35語の英文で書いてみましょう。文章にはリード文、あなたの意見を裏付ける理由を二つ含めてください。"
}

var LengthLimitation = {
  "level4": 1050,
  "level3": 700,
  "level2": 420,
  "level1": 245
}

var SampleEssay = {
  "level4": {
    "1": [
      'I agree that mail delivery on weekends should be abolished. Because mail delivery on weekends makes staff bad condition. They should take a rest on weekends.',
      'First, work of delivery is hard. So they should take a rest on weekends for concentrating for working. And they have excessive work by amount of mail. So mail delivery on weekends should be abolished.',
      'Second, amount of mail on weekends is a little than weekday. So it should be delivered on weekday. If mail delivery on weekends is abolished, they can deliver a mail on weekends with weekday. It reduce a stress of the staff.',
      'In conclusion, I agree that mail delivery on weekends should be abolished. '
    ].join('\n')
  },
  "level3": {
    "1": 'I think students should not have no homework. First, it is important for children to have free time and choose what they want after school. In addition, having no homework will save teachers\' time. If there is homework, teachers spend many time on homework. Sometime it takes more than one or two hours to grade homework. In conclusion, no homework will make both students and teachers happy, so students should have no homework.'
  },
  "level2": {
    "1": 'I think traveling with friends is better than traveling with family for high school students. I have two reasons. First, it is fun to plan a trip with friends. We can talk about school and club activities. Second, we can cost down. Therefore, traveling with friends is better for high school students.'
  },
  "level1": {
    "1": 'Living room is the best place to study for me. I have two reasons. First, my mother teaches me study. Second, there is very quiet and I can relax.'
  }
}

var SampleResult = {
  "level4": {
    "1": {
      "coherence": {
        "all_error_num": 1,
        "all_error_ratio": 0.08333333333333333,
        "bf_error_pairs": [
          [
            "p0_EX_2",
            "p1_EX_0"
          ]
        ],
        "checklist_1": 0,
        "checklist_18": 0,
        "checklist_19": 0,
        "checklist_2": 0,
        "checklist_20": 0,
        "checklist_21": 0,
        "checklist_22": 0,
        "checklist_23": 0,
        "checklist_24": 0,
        "checklist_25": 0,
        "checklist_26": 1,
        "checklist_27": 0,
        "checklist_28": 0,
        "correct_sent_num": 11,
        "minimal_essay_score": 0,
        "num_wo_conj_sent": 4,
        "para_score": 0,
        "ratio_simple_conj": 1.0,
        "ratio_wo_conj_sent": 0.4444444444444444,
        "sys_coherence": 3
      },
      "content": {
        "abnormal_response_score": null,
        "checklist_15": 0,
        "checklist_16": 0,
        "checklist_17": 0,
        "len_filter_score": -1.0,
        "non_english_score": -1,
        "offtopic_score": -1,
        "opinion_label": 0,
        "p0_checklist_results": {
          "checklist_10": 0,
          "checklist_11": 0,
          "checklist_12": 0,
          "checklist_345": 0,
          "checklist_4": 1,
          "checklist_9": 0,
          "checklist_all": 1,
          "explanation_len": 29,
          "relevance_score": 0.6054692268371582,
          "sent_feats": [
            0.0,
            0.3333333333333333
          ]
        },
        "p1_checklist_results": {
          "checklist_10": 0,
          "checklist_11": 0,
          "checklist_12": 0,
          "checklist_345": 0,
          "checklist_4": 0,
          "checklist_9": 0,
          "checklist_all": 0,
          "explanation_len": 30,
          "relevance_score": 0.3246949315071106,
          "sent_feats": [
            1.0,
            0.0
          ]
        },
        "reason_with_ex": 1,
        "reason_without_ex": 0,
        "sys_content": 3
      },
      "grammar_vocabulary": {
        "grammar_checklist_items": {
          "grammar_checklist_30": 0,
          "grammar_checklist_31": 0,
          "grammar_checklist_32": 0,
          "grammar_checklist_33": 0,
          "grammar_checklist_34": 1,
          "grammar_checklist_35": 0,
          "grammar_checklist_36": 0,
          "grammar_checklist_37": 0,
          "grammar_checklist_38": 0
        },
        "grammar_complexity_score": 2,
        "grammar_sentence_scores": [
          0,
          1,
          0,
          0,
          1,
          0,
          0,
          0
        ],
        "max_sent_len": 19,
        "mean_sent_len": 11.875,
        "n_orth_error": 0,
        "n_spell_error": 0,
        "numwds": 114,
        "sentences": [
          {
            "both_err": [],
            "cor_sent": "I agree that mail delivery on weekends should be abolished.",
            "err_ids": [
              11
            ],
            "error_span": 0,
            "error_types": [
              "Orig: [11, 12, ' '], Cor: [11, 11, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 1,
            "grammar_err": [],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 0,
            "org_sent": "I agree that mail delivery on weekends should be abolished.  ",
            "r_err": 0.0,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "Opinion",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [
              "R:VERB"
            ],
            "cor_sent": "Because mail delivery on weekends puts the staff in bad condition.",
            "err_ids": [
              5,
              10
            ],
            "error_span": 1,
            "error_types": [
              "Orig: [5, 6, 'makes'], Cor: [5, 6, 'puts'], Type: 'R:VERB'",
              "Orig: [6, 6, ''], Cor: [6, 7, 'the'], Type: 'M:DET'",
              "Orig: [7, 7, ''], Cor: [8, 9, 'in'], Type: 'M:PREP'",
              "Orig: [10, 11, ' '], Cor: [12, 12, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 1,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [
              "M:PREP"
            ],
            "grammar_sentence_score": 1,
            "is_grammar_error_critical": 1,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 1,
            "n_err": 2,
            "org_sent": "Because mail delivery on weekends makes staff bad condition.  ",
            "r_err": 0.2222222222222222,
            "sentence_score": 1,
            "sentence_w_verr": 1,
            "tag": "Opinion",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 1
          },
          {
            "both_err": [],
            "cor_sent": "They should take a rest on weekends.",
            "err_ids": [
              8
            ],
            "error_span": 0,
            "error_types": [
              "Orig: [8, 9, '\\n'], Cor: [8, 8, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 0,
            "org_sent": "They should take a rest on weekends. \n",
            "r_err": 0.0,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "Opinion",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [],
            "cor_sent": "First, the work of delivery is hard.",
            "err_ids": [
              8
            ],
            "error_span": 0,
            "error_types": [
              "Orig: [2, 2, ''], Cor: [2, 3, 'the'], Type: 'M:DET'",
              "Orig: [8, 9, ' '], Cor: [9, 9, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 0,
            "org_sent": "First, work of delivery is hard.  ",
            "r_err": 0.0,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "R0",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [
              "R:PART"
            ],
            "cor_sent": "So they should take a rest on weekends to concentrate on their work.",
            "err_ids": [
              8,
              9,
              10,
              11,
              13
            ],
            "error_span": 4,
            "error_types": [
              "Orig: [8, 9, 'for'], Cor: [8, 9, 'to'], Type: 'R:PART'",
              "Orig: [9, 10, 'concentrating'], Cor: [9, 10, 'concentrate'], Type: 'R:VERB:FORM'",
              "Orig: [10, 12, 'for working'], Cor: [10, 13, 'on their work'], Type: 'R:OTHER'",
              "Orig: [13, 14, ' '], Cor: [14, 14, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 1,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 1,
            "grammar_err": [
              "R:VERB:FORM"
            ],
            "grammar_sentence_score": 1,
            "is_grammar_error_critical": 1,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 1,
            "n_err": 3,
            "org_sent": "So they should take a rest on weekends for concentrating for working.  ",
            "r_err": 0.25,
            "sentence_score": 1,
            "sentence_w_verr": 1,
            "tag": "EX0",
            "vocabulary_err": [
              "R:OTHER"
            ],
            "vocabulary_sentence_score": 1
          },
          {
            "both_err": [],
            "cor_sent": "And they have excessive work due to the amount of mail.",
            "err_ids": [
              5,
              10
            ],
            "error_span": 1,
            "error_types": [
              "Orig: [5, 6, 'by'], Cor: [5, 7, 'due to'], Type: 'R:PREP'",
              "Orig: [6, 6, ''], Cor: [7, 8, 'the'], Type: 'M:DET'",
              "Orig: [10, 11, ' '], Cor: [12, 12, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [
              "R:PREP"
            ],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 1,
            "org_sent": "And they have excessive work by amount of mail.  ",
            "r_err": 0.1111111111111111,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "EX0",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [],
            "cor_sent": "So mail delivery on weekends should be abolished.",
            "err_ids": [
              9
            ],
            "error_span": 0,
            "error_types": [
              "Orig: [9, 10, '\\n'], Cor: [9, 9, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 0,
            "org_sent": "So mail delivery on weekends should be abolished. \n",
            "r_err": 0.0,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "EX0",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [],
            "cor_sent": "Second, the amount of mail on weekends is less than on weekdays.",
            "err_ids": [
              8,
              9,
              11,
              13
            ],
            "error_span": 3,
            "error_types": [
              "Orig: [2, 2, ''], Cor: [2, 3, 'the'], Type: 'M:DET'",
              "Orig: [8, 10, 'a little'], Cor: [9, 10, 'less'], Type: 'R:OTHER'",
              "Orig: [11, 11, ''], Cor: [11, 12, 'on'], Type: 'M:PREP'",
              "Orig: [11, 12, 'weekday'], Cor: [12, 13, 'weekdays'], Type: 'R:NOUN:NUM'",
              "Orig: [13, 14, ' '], Cor: [14, 14, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 1,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [
              "M:PREP",
              "R:NOUN:NUM"
            ],
            "grammar_sentence_score": 1,
            "is_grammar_error_critical": 1,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 1,
            "n_err": 3,
            "org_sent": "Second, amount of mail on weekends is a little than weekday.  ",
            "r_err": 0.2727272727272727,
            "sentence_score": 1,
            "sentence_w_verr": 0,
            "tag": "R1",
            "vocabulary_err": [
              "R:OTHER"
            ],
            "vocabulary_sentence_score": 1
          },
          {
            "both_err": [],
            "cor_sent": "So it should be delivered on weekdays.",
            "err_ids": [
              6,
              8
            ],
            "error_span": 1,
            "error_types": [
              "Orig: [6, 7, 'weekday'], Cor: [6, 7, 'weekdays'], Type: 'R:NOUN:NUM'",
              "Orig: [8, 9, ' '], Cor: [8, 8, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [
              "R:NOUN:NUM"
            ],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 1,
            "org_sent": "So it should be delivered on weekday.  ",
            "r_err": 0.14285714285714285,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "EX1",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [],
            "cor_sent": "If mail delivery on weekends is abolished, they can deliver mail on weekends with weekdays.",
            "err_ids": [
              11,
              16,
              18
            ],
            "error_span": 1,
            "error_types": [
              "Orig: [11, 12, 'a'], Cor: [11, 11, ''], Type: 'U:DET'",
              "Orig: [16, 17, 'weekday'], Cor: [15, 16, 'weekdays'], Type: 'R:NOUN:NUM'",
              "Orig: [18, 19, ' '], Cor: [17, 17, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 1,
            "grammar_err": [
              "R:NOUN:NUM"
            ],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 1,
            "org_sent": "If mail delivery on weekends is abolished, they can deliver a mail on weekends with weekday.  ",
            "r_err": 0.0625,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "EX1",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [],
            "cor_sent": "It reduces the stress of the staff.",
            "err_ids": [
              1,
              2,
              8
            ],
            "error_span": 1,
            "error_types": [
              "Orig: [1, 2, 'reduce'], Cor: [1, 2, 'reduces'], Type: 'R:VERB:SVA'",
              "Orig: [2, 3, 'a'], Cor: [2, 3, 'the'], Type: 'R:DET'",
              "Orig: [8, 9, '\\n'], Cor: [8, 8, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [
              "R:VERB:SVA"
            ],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 1,
            "org_sent": "It reduce a stress of the staff. \n",
            "r_err": 0.14285714285714285,
            "sentence_score": 0,
            "sentence_w_verr": 1,
            "tag": "EX1",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [],
            "cor_sent": "In conclusion, I agree that mail delivery on weekends should be abolished.",
            "err_ids": [
              14
            ],
            "error_span": 0,
            "error_types": [
              "Orig: [14, 15, ' '], Cor: [14, 14, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 1,
            "grammar_err": [],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 0,
            "org_sent": "In conclusion, I agree that mail delivery on weekends should be abolished.  ",
            "r_err": 0.0,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "Conclusion",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          }
        ],
        "sys_grammar": 3,
        "sys_grammar_max_sentence_score": 1,
        "sys_grammar_mean_sentence_score": 0.25,
        "sys_vocabulary": 3,
        "sys_vocabulary_max_sentence_score": 1,
        "sys_vocabulary_mean_sentence_score": 0.25,
        "vocabulary_checklist_items": {
          "vocabulary_checklist_30": 0,
          "vocabulary_checklist_31": 0,
          "vocabulary_checklist_32": 0,
          "vocabulary_checklist_33": 0,
          "vocabulary_checklist_34": 1,
          "vocabulary_checklist_35": 0,
          "vocabulary_checklist_36": 0,
          "vocabulary_checklist_37": 0,
          "vocabulary_checklist_38": 0
        },
        "vocabulary_complexity_score": 0.47058823529411764,
        "vocabulary_sentence_scores": [
          0,
          1,
          0,
          0,
          1,
          0,
          0,
          0
        ]
      },
      "input": {
        "conclusion": "In conclusion, I agree that mail delivery on weekends should be abolished.",
        "conclusion_position": "yes",
        "input_text": "I agree that mail delivery on weekends should be abolished.  Because mail delivery on weekends makes staff bad condition.  They should take a rest on weekends. \nFirst, work of delivery is hard.  So they should take a rest on weekends for concentrating for working.  And they have excessive work by amount of mail.  So mail delivery on weekends should be abolished. \nSecond, amount of mail on weekends is a little than weekday.  So it should be delivered on weekday.  If mail delivery on weekends is abolished, they can deliver a mail on weekends with weekday.  It reduce a stress of the staff. \nIn conclusion, I agree that mail delivery on weekends should be abolished.  ",
        "opinion": "I agree that mail delivery on weekends should be abolished.  Because mail delivery on weekends makes staff bad condition.  They should take a rest on weekends.",
        "opinion_position": "yes",
        "p0": {
          "EX": "So they should take a rest on weekends for concentrating for working. And they have excessive work by amount of mail. So mail delivery on weekends should be abolished.",
          "EX_sent": [
            {
              "both_err": [
                "R:PART"
              ],
              "cor_sent": "So they should take a rest on weekends to concentrate on their work.",
              "cor_tokens": {
                "ents": [
                  {
                    "end": 38,
                    "label": "DATE",
                    "start": 30
                  }
                ],
                "sents": [
                  {
                    "end": 68,
                    "start": 0
                  }
                ],
                "text": "So they should take a rest on weekends to concentrate on their work.",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 2,
                    "head": 3,
                    "id": 0,
                    "lemma": "so",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "nsubj",
                    "end": 7,
                    "head": 3,
                    "id": 1,
                    "lemma": "they",
                    "morph": "Case=Nom|Number=Plur|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 3,
                    "tag": "PRP"
                  },
                  {
                    "dep": "aux",
                    "end": 14,
                    "head": 3,
                    "id": 2,
                    "lemma": "should",
                    "morph": "VerbForm=Fin",
                    "pos": "AUX",
                    "start": 8,
                    "tag": "MD"
                  },
                  {
                    "dep": "ROOT",
                    "end": 19,
                    "head": 3,
                    "id": 3,
                    "lemma": "take",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 15,
                    "tag": "VB"
                  },
                  {
                    "dep": "det",
                    "end": 21,
                    "head": 5,
                    "id": 4,
                    "lemma": "a",
                    "morph": "Definite=Ind|PronType=Art",
                    "pos": "DET",
                    "start": 20,
                    "tag": "DT"
                  },
                  {
                    "dep": "dobj",
                    "end": 26,
                    "head": 3,
                    "id": 5,
                    "lemma": "rest",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 22,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 29,
                    "head": 3,
                    "id": 6,
                    "lemma": "on",
                    "morph": "",
                    "pos": "ADP",
                    "start": 27,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 38,
                    "head": 6,
                    "id": 7,
                    "lemma": "weekend",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 30,
                    "tag": "NNS"
                  },
                  {
                    "dep": "aux",
                    "end": 41,
                    "head": 9,
                    "id": 8,
                    "lemma": "to",
                    "morph": "",
                    "pos": "PART",
                    "start": 39,
                    "tag": "TO"
                  },
                  {
                    "dep": "advcl",
                    "end": 53,
                    "head": 3,
                    "id": 9,
                    "lemma": "concentrate",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 42,
                    "tag": "VB"
                  },
                  {
                    "dep": "prep",
                    "end": 56,
                    "head": 9,
                    "id": 10,
                    "lemma": "on",
                    "morph": "",
                    "pos": "ADP",
                    "start": 54,
                    "tag": "IN"
                  },
                  {
                    "dep": "poss",
                    "end": 62,
                    "head": 12,
                    "id": 11,
                    "lemma": "their",
                    "morph": "Number=Plur|Person=3|Poss=Yes|PronType=Prs",
                    "pos": "PRON",
                    "start": 57,
                    "tag": "PRP$"
                  },
                  {
                    "dep": "pobj",
                    "end": 67,
                    "head": 10,
                    "id": 12,
                    "lemma": "work",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 63,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 68,
                    "head": 3,
                    "id": 13,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 67,
                    "tag": "."
                  }
                ]
              },
              "edits": [
                "Orig: [8, 9, 'for'], Cor: [8, 9, 'to'], Type: 'R:PART'",
                "Orig: [9, 10, 'concentrating'], Cor: [9, 10, 'concentrate'], Type: 'R:VERB:FORM'",
                "Orig: [10, 12, 'for working'], Cor: [10, 13, 'on their work'], Type: 'R:OTHER'",
                "Orig: [13, 14, ' '], Cor: [14, 14, ''], Type: 'U:SPACE'"
              ],
              "err_ids": [
                8,
                9,
                10,
                11,
                13
              ],
              "error_span": 4,
              "error_types": [
                "Orig: [8, 9, 'for'], Cor: [8, 9, 'to'], Type: 'R:PART'",
                "Orig: [9, 10, 'concentrating'], Cor: [9, 10, 'concentrate'], Type: 'R:VERB:FORM'",
                "Orig: [10, 12, 'for working'], Cor: [10, 13, 'on their work'], Type: 'R:OTHER'",
                "Orig: [13, 14, ' '], Cor: [14, 14, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 1,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 1,
              "grammar_err": [
                "R:VERB:FORM"
              ],
              "grammar_sentence_score": 1,
              "is_grammar_error_critical": 1,
              "is_incomplete_sentence": 0,
              "is_startwith_because": 0,
              "is_vocabulary_error_critical": 1,
              "n_err": 3,
              "org_sent": "So they should take a rest on weekends for concentrating for working.  ",
              "org_tokens": {
                "ents": [
                  {
                    "end": 38,
                    "label": "DATE",
                    "start": 30
                  }
                ],
                "sents": [
                  {
                    "end": 71,
                    "start": 0
                  }
                ],
                "text": "So they should take a rest on weekends for concentrating for working.  ",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 2,
                    "head": 3,
                    "id": 0,
                    "lemma": "so",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "nsubj",
                    "end": 7,
                    "head": 3,
                    "id": 1,
                    "lemma": "they",
                    "morph": "Case=Nom|Number=Plur|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 3,
                    "tag": "PRP"
                  },
                  {
                    "dep": "aux",
                    "end": 14,
                    "head": 3,
                    "id": 2,
                    "lemma": "should",
                    "morph": "VerbForm=Fin",
                    "pos": "AUX",
                    "start": 8,
                    "tag": "MD"
                  },
                  {
                    "dep": "ROOT",
                    "end": 19,
                    "head": 3,
                    "id": 3,
                    "lemma": "take",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 15,
                    "tag": "VB"
                  },
                  {
                    "dep": "det",
                    "end": 21,
                    "head": 5,
                    "id": 4,
                    "lemma": "a",
                    "morph": "Definite=Ind|PronType=Art",
                    "pos": "DET",
                    "start": 20,
                    "tag": "DT"
                  },
                  {
                    "dep": "dobj",
                    "end": 26,
                    "head": 3,
                    "id": 5,
                    "lemma": "rest",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 22,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 29,
                    "head": 3,
                    "id": 6,
                    "lemma": "on",
                    "morph": "",
                    "pos": "ADP",
                    "start": 27,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 38,
                    "head": 6,
                    "id": 7,
                    "lemma": "weekend",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 30,
                    "tag": "NNS"
                  },
                  {
                    "dep": "prep",
                    "end": 42,
                    "head": 3,
                    "id": 8,
                    "lemma": "for",
                    "morph": "",
                    "pos": "ADP",
                    "start": 39,
                    "tag": "IN"
                  },
                  {
                    "dep": "pcomp",
                    "end": 56,
                    "head": 8,
                    "id": 9,
                    "lemma": "concentrate",
                    "morph": "Aspect=Prog|Tense=Pres|VerbForm=Part",
                    "pos": "VERB",
                    "start": 43,
                    "tag": "VBG"
                  },
                  {
                    "dep": "prep",
                    "end": 60,
                    "head": 9,
                    "id": 10,
                    "lemma": "for",
                    "morph": "",
                    "pos": "ADP",
                    "start": 57,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 68,
                    "head": 10,
                    "id": 11,
                    "lemma": "work",
                    "morph": "Aspect=Prog|Tense=Pres|VerbForm=Part",
                    "pos": "VERB",
                    "start": 61,
                    "tag": "VBG"
                  },
                  {
                    "dep": "punct",
                    "end": 69,
                    "head": 3,
                    "id": 12,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 68,
                    "tag": "."
                  },
                  {
                    "dep": "dep",
                    "end": 71,
                    "head": 12,
                    "id": 13,
                    "lemma": " ",
                    "morph": "",
                    "pos": "SPACE",
                    "start": 70,
                    "tag": "_SP"
                  }
                ]
              },
              "r_err": 0.25,
              "sentence_score": 1,
              "sentence_w_verr": 1,
              "simple_sent_1": 0,
              "simple_sent_2": 0,
              "tag": "EX0",
              "vocabulary_err": [
                "R:OTHER"
              ],
              "vocabulary_sentence_score": 1
            },
            {
              "both_err": [],
              "cor_sent": "And they have excessive work due to the amount of mail.",
              "cor_tokens": {
                "ents": [],
                "sents": [
                  {
                    "end": 55,
                    "start": 0
                  }
                ],
                "text": "And they have excessive work due to the amount of mail.",
                "tokens": [
                  {
                    "dep": "cc",
                    "end": 3,
                    "head": 2,
                    "id": 0,
                    "lemma": "and",
                    "morph": "ConjType=Cmp",
                    "pos": "CCONJ",
                    "start": 0,
                    "tag": "CC"
                  },
                  {
                    "dep": "nsubj",
                    "end": 8,
                    "head": 2,
                    "id": 1,
                    "lemma": "they",
                    "morph": "Case=Nom|Number=Plur|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 4,
                    "tag": "PRP"
                  },
                  {
                    "dep": "ROOT",
                    "end": 13,
                    "head": 2,
                    "id": 2,
                    "lemma": "have",
                    "morph": "Mood=Ind|Tense=Pres|VerbForm=Fin",
                    "pos": "VERB",
                    "start": 9,
                    "tag": "VBP"
                  },
                  {
                    "dep": "amod",
                    "end": 23,
                    "head": 4,
                    "id": 3,
                    "lemma": "excessive",
                    "morph": "Degree=Pos",
                    "pos": "ADJ",
                    "start": 14,
                    "tag": "JJ"
                  },
                  {
                    "dep": "dobj",
                    "end": 28,
                    "head": 2,
                    "id": 4,
                    "lemma": "work",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 24,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 32,
                    "head": 2,
                    "id": 5,
                    "lemma": "due",
                    "morph": "",
                    "pos": "ADP",
                    "start": 29,
                    "tag": "IN"
                  },
                  {
                    "dep": "pcomp",
                    "end": 35,
                    "head": 5,
                    "id": 6,
                    "lemma": "to",
                    "morph": "",
                    "pos": "ADP",
                    "start": 33,
                    "tag": "IN"
                  },
                  {
                    "dep": "det",
                    "end": 39,
                    "head": 8,
                    "id": 7,
                    "lemma": "the",
                    "morph": "Definite=Def|PronType=Art",
                    "pos": "DET",
                    "start": 36,
                    "tag": "DT"
                  },
                  {
                    "dep": "pobj",
                    "end": 46,
                    "head": 5,
                    "id": 8,
                    "lemma": "amount",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 40,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 49,
                    "head": 8,
                    "id": 9,
                    "lemma": "of",
                    "morph": "",
                    "pos": "ADP",
                    "start": 47,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 54,
                    "head": 9,
                    "id": 10,
                    "lemma": "mail",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 50,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 55,
                    "head": 2,
                    "id": 11,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 54,
                    "tag": "."
                  }
                ]
              },
              "edits": [
                "Orig: [5, 6, 'by'], Cor: [5, 7, 'due to'], Type: 'R:PREP'",
                "Orig: [6, 6, ''], Cor: [7, 8, 'the'], Type: 'M:DET'",
                "Orig: [10, 11, ' '], Cor: [12, 12, ''], Type: 'U:SPACE'"
              ],
              "err_ids": [
                5,
                10
              ],
              "error_span": 1,
              "error_types": [
                "Orig: [5, 6, 'by'], Cor: [5, 7, 'due to'], Type: 'R:PREP'",
                "Orig: [6, 6, ''], Cor: [7, 8, 'the'], Type: 'M:DET'",
                "Orig: [10, 11, ' '], Cor: [12, 12, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [
                "R:PREP"
              ],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_startwith_because": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "And they have excessive work by amount of mail.  ",
              "org_tokens": {
                "ents": [],
                "sents": [
                  {
                    "end": 49,
                    "start": 0
                  }
                ],
                "text": "And they have excessive work by amount of mail.  ",
                "tokens": [
                  {
                    "dep": "cc",
                    "end": 3,
                    "head": 2,
                    "id": 0,
                    "lemma": "and",
                    "morph": "ConjType=Cmp",
                    "pos": "CCONJ",
                    "start": 0,
                    "tag": "CC"
                  },
                  {
                    "dep": "nsubj",
                    "end": 8,
                    "head": 2,
                    "id": 1,
                    "lemma": "they",
                    "morph": "Case=Nom|Number=Plur|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 4,
                    "tag": "PRP"
                  },
                  {
                    "dep": "ROOT",
                    "end": 13,
                    "head": 2,
                    "id": 2,
                    "lemma": "have",
                    "morph": "Mood=Ind|Tense=Pres|VerbForm=Fin",
                    "pos": "VERB",
                    "start": 9,
                    "tag": "VBP"
                  },
                  {
                    "dep": "amod",
                    "end": 23,
                    "head": 4,
                    "id": 3,
                    "lemma": "excessive",
                    "morph": "Degree=Pos",
                    "pos": "ADJ",
                    "start": 14,
                    "tag": "JJ"
                  },
                  {
                    "dep": "dobj",
                    "end": 28,
                    "head": 2,
                    "id": 4,
                    "lemma": "work",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 24,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 31,
                    "head": 2,
                    "id": 5,
                    "lemma": "by",
                    "morph": "",
                    "pos": "ADP",
                    "start": 29,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 38,
                    "head": 5,
                    "id": 6,
                    "lemma": "amount",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 32,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 41,
                    "head": 6,
                    "id": 7,
                    "lemma": "of",
                    "morph": "",
                    "pos": "ADP",
                    "start": 39,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 46,
                    "head": 7,
                    "id": 8,
                    "lemma": "mail",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 42,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 47,
                    "head": 2,
                    "id": 9,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 46,
                    "tag": "."
                  },
                  {
                    "dep": "dep",
                    "end": 49,
                    "head": 9,
                    "id": 10,
                    "lemma": " ",
                    "morph": "",
                    "pos": "SPACE",
                    "start": 48,
                    "tag": "_SP"
                  }
                ]
              },
              "r_err": 0.1111111111111111,
              "sentence_score": 0,
              "sentence_w_verr": 0,
              "simple_sent_1": 0,
              "simple_sent_2": 0,
              "tag": "EX0",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "So mail delivery on weekends should be abolished.",
              "cor_tokens": {
                "ents": [
                  {
                    "end": 28,
                    "label": "DATE",
                    "start": 20
                  }
                ],
                "sents": [
                  {
                    "end": 49,
                    "start": 0
                  }
                ],
                "text": "So mail delivery on weekends should be abolished.",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 2,
                    "head": 7,
                    "id": 0,
                    "lemma": "so",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "compound",
                    "end": 7,
                    "head": 2,
                    "id": 1,
                    "lemma": "mail",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 3,
                    "tag": "NN"
                  },
                  {
                    "dep": "nsubjpass",
                    "end": 16,
                    "head": 7,
                    "id": 2,
                    "lemma": "delivery",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 8,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 19,
                    "head": 2,
                    "id": 3,
                    "lemma": "on",
                    "morph": "",
                    "pos": "ADP",
                    "start": 17,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 28,
                    "head": 3,
                    "id": 4,
                    "lemma": "weekend",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 20,
                    "tag": "NNS"
                  },
                  {
                    "dep": "aux",
                    "end": 35,
                    "head": 7,
                    "id": 5,
                    "lemma": "should",
                    "morph": "VerbForm=Fin",
                    "pos": "AUX",
                    "start": 29,
                    "tag": "MD"
                  },
                  {
                    "dep": "auxpass",
                    "end": 38,
                    "head": 7,
                    "id": 6,
                    "lemma": "be",
                    "morph": "VerbForm=Inf",
                    "pos": "AUX",
                    "start": 36,
                    "tag": "VB"
                  },
                  {
                    "dep": "ROOT",
                    "end": 48,
                    "head": 7,
                    "id": 7,
                    "lemma": "abolish",
                    "morph": "Aspect=Perf|Tense=Past|VerbForm=Part",
                    "pos": "VERB",
                    "start": 39,
                    "tag": "VBN"
                  },
                  {
                    "dep": "punct",
                    "end": 49,
                    "head": 7,
                    "id": 8,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 48,
                    "tag": "."
                  }
                ]
              },
              "edits": [
                "Orig: [9, 10, '\\n'], Cor: [9, 9, ''], Type: 'U:SPACE'"
              ],
              "err_ids": [
                9
              ],
              "error_span": 0,
              "error_types": [
                "Orig: [9, 10, '\\n'], Cor: [9, 9, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_startwith_because": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "So mail delivery on weekends should be abolished. \n",
              "org_tokens": {
                "ents": [
                  {
                    "end": 28,
                    "label": "DATE",
                    "start": 20
                  }
                ],
                "sents": [
                  {
                    "end": 51,
                    "start": 0
                  }
                ],
                "text": "So mail delivery on weekends should be abolished. \n",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 2,
                    "head": 7,
                    "id": 0,
                    "lemma": "so",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "compound",
                    "end": 7,
                    "head": 2,
                    "id": 1,
                    "lemma": "mail",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 3,
                    "tag": "NN"
                  },
                  {
                    "dep": "nsubjpass",
                    "end": 16,
                    "head": 7,
                    "id": 2,
                    "lemma": "delivery",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 8,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 19,
                    "head": 2,
                    "id": 3,
                    "lemma": "on",
                    "morph": "",
                    "pos": "ADP",
                    "start": 17,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 28,
                    "head": 3,
                    "id": 4,
                    "lemma": "weekend",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 20,
                    "tag": "NNS"
                  },
                  {
                    "dep": "aux",
                    "end": 35,
                    "head": 7,
                    "id": 5,
                    "lemma": "should",
                    "morph": "VerbForm=Fin",
                    "pos": "AUX",
                    "start": 29,
                    "tag": "MD"
                  },
                  {
                    "dep": "auxpass",
                    "end": 38,
                    "head": 7,
                    "id": 6,
                    "lemma": "be",
                    "morph": "VerbForm=Inf",
                    "pos": "AUX",
                    "start": 36,
                    "tag": "VB"
                  },
                  {
                    "dep": "ROOT",
                    "end": 48,
                    "head": 7,
                    "id": 7,
                    "lemma": "abolish",
                    "morph": "Aspect=Perf|Tense=Past|VerbForm=Part",
                    "pos": "VERB",
                    "start": 39,
                    "tag": "VBN"
                  },
                  {
                    "dep": "punct",
                    "end": 49,
                    "head": 7,
                    "id": 8,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 48,
                    "tag": "."
                  },
                  {
                    "dep": "dep",
                    "end": 51,
                    "head": 8,
                    "id": 9,
                    "lemma": "\n",
                    "morph": "",
                    "pos": "SPACE",
                    "start": 50,
                    "tag": "_SP"
                  }
                ]
              },
              "r_err": 0.0,
              "sentence_score": 0,
              "sentence_w_verr": 0,
              "simple_sent_1": 0,
              "simple_sent_2": 0,
              "tag": "EX0",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            }
          ],
          "R": "First, work of delivery is hard.",
          "R_sent": [
            {
              "both_err": [],
              "cor_sent": "First, the work of delivery is hard.",
              "cor_tokens": {
                "ents": [
                  {
                    "end": 5,
                    "label": "ORDINAL",
                    "start": 0
                  }
                ],
                "sents": [
                  {
                    "end": 36,
                    "start": 0
                  }
                ],
                "text": "First, the work of delivery is hard.",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 5,
                    "head": 6,
                    "id": 0,
                    "lemma": "first",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "punct",
                    "end": 6,
                    "head": 6,
                    "id": 1,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 5,
                    "tag": ","
                  },
                  {
                    "dep": "det",
                    "end": 10,
                    "head": 3,
                    "id": 2,
                    "lemma": "the",
                    "morph": "Definite=Def|PronType=Art",
                    "pos": "DET",
                    "start": 7,
                    "tag": "DT"
                  },
                  {
                    "dep": "nsubj",
                    "end": 15,
                    "head": 6,
                    "id": 3,
                    "lemma": "work",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 11,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 18,
                    "head": 3,
                    "id": 4,
                    "lemma": "of",
                    "morph": "",
                    "pos": "ADP",
                    "start": 16,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 27,
                    "head": 4,
                    "id": 5,
                    "lemma": "delivery",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 19,
                    "tag": "NN"
                  },
                  {
                    "dep": "ROOT",
                    "end": 30,
                    "head": 6,
                    "id": 6,
                    "lemma": "be",
                    "morph": "Mood=Ind|Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "AUX",
                    "start": 28,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "acomp",
                    "end": 35,
                    "head": 6,
                    "id": 7,
                    "lemma": "hard",
                    "morph": "Degree=Pos",
                    "pos": "ADJ",
                    "start": 31,
                    "tag": "JJ"
                  },
                  {
                    "dep": "punct",
                    "end": 36,
                    "head": 6,
                    "id": 8,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 35,
                    "tag": "."
                  }
                ]
              },
              "edits": [
                "Orig: [2, 2, ''], Cor: [2, 3, 'the'], Type: 'M:DET'",
                "Orig: [8, 9, ' '], Cor: [9, 9, ''], Type: 'U:SPACE'"
              ],
              "err_ids": [
                8
              ],
              "error_span": 0,
              "error_types": [
                "Orig: [2, 2, ''], Cor: [2, 3, 'the'], Type: 'M:DET'",
                "Orig: [8, 9, ' '], Cor: [9, 9, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_startwith_because": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "First, work of delivery is hard.  ",
              "org_tokens": {
                "ents": [
                  {
                    "end": 5,
                    "label": "ORDINAL",
                    "start": 0
                  }
                ],
                "sents": [
                  {
                    "end": 34,
                    "start": 0
                  }
                ],
                "text": "First, work of delivery is hard.  ",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 5,
                    "head": 5,
                    "id": 0,
                    "lemma": "first",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "punct",
                    "end": 6,
                    "head": 5,
                    "id": 1,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 5,
                    "tag": ","
                  },
                  {
                    "dep": "nsubj",
                    "end": 11,
                    "head": 5,
                    "id": 2,
                    "lemma": "work",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 7,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 14,
                    "head": 2,
                    "id": 3,
                    "lemma": "of",
                    "morph": "",
                    "pos": "ADP",
                    "start": 12,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 23,
                    "head": 3,
                    "id": 4,
                    "lemma": "delivery",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 15,
                    "tag": "NN"
                  },
                  {
                    "dep": "ROOT",
                    "end": 26,
                    "head": 5,
                    "id": 5,
                    "lemma": "be",
                    "morph": "Mood=Ind|Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "AUX",
                    "start": 24,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "acomp",
                    "end": 31,
                    "head": 5,
                    "id": 6,
                    "lemma": "hard",
                    "morph": "Degree=Pos",
                    "pos": "ADJ",
                    "start": 27,
                    "tag": "JJ"
                  },
                  {
                    "dep": "punct",
                    "end": 32,
                    "head": 5,
                    "id": 7,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 31,
                    "tag": "."
                  },
                  {
                    "dep": "dep",
                    "end": 34,
                    "head": 7,
                    "id": 8,
                    "lemma": " ",
                    "morph": "",
                    "pos": "SPACE",
                    "start": 33,
                    "tag": "_SP"
                  }
                ]
              },
              "r_err": 0.0,
              "sentence_score": 0,
              "sentence_w_verr": 0,
              "simple_sent_1": 0,
              "simple_sent_2": 0,
              "tag": "R0",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            }
          ],
          "co_EX": "So mail delivery staff should take a rest on weekends for concentrating for working. And mail delivery staff have excessive work by amount of mail. So mail delivery on weekends should be abolished.",
          "co_R": "First, work of delivery is hard.",
          "no_conj_EX": "So they should take a rest on weekends for concentrating for working And they have excessive work by amount of mail So mail delivery on weekends should be abolished.",
          "no_conj_R": "Work of delivery is hard.",
          "no_conj_co_EX": "So mail delivery staff should take a rest on weekends for concentrating for working And mail delivery staff have excessive work by amount of mail So mail delivery on weekends should be abolished.",
          "no_conj_co_R": "Work of delivery is hard."
        },
        "p1": {
          "EX": "So it should be delivered on weekday. If mail delivery on weekends is abolished, they can deliver a mail on weekends with weekday. It reduce a stress of the staff.",
          "EX_sent": [
            {
              "both_err": [],
              "cor_sent": "So it should be delivered on weekdays.",
              "cor_tokens": {
                "ents": [
                  {
                    "end": 37,
                    "label": "DATE",
                    "start": 29
                  }
                ],
                "sents": [
                  {
                    "end": 38,
                    "start": 0
                  }
                ],
                "text": "So it should be delivered on weekdays.",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 2,
                    "head": 4,
                    "id": 0,
                    "lemma": "so",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "nsubjpass",
                    "end": 5,
                    "head": 4,
                    "id": 1,
                    "lemma": "it",
                    "morph": "Gender=Neut|Number=Sing|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 3,
                    "tag": "PRP"
                  },
                  {
                    "dep": "aux",
                    "end": 12,
                    "head": 4,
                    "id": 2,
                    "lemma": "should",
                    "morph": "VerbForm=Fin",
                    "pos": "AUX",
                    "start": 6,
                    "tag": "MD"
                  },
                  {
                    "dep": "auxpass",
                    "end": 15,
                    "head": 4,
                    "id": 3,
                    "lemma": "be",
                    "morph": "VerbForm=Inf",
                    "pos": "AUX",
                    "start": 13,
                    "tag": "VB"
                  },
                  {
                    "dep": "ROOT",
                    "end": 25,
                    "head": 4,
                    "id": 4,
                    "lemma": "deliver",
                    "morph": "Aspect=Perf|Tense=Past|VerbForm=Part",
                    "pos": "VERB",
                    "start": 16,
                    "tag": "VBN"
                  },
                  {
                    "dep": "prep",
                    "end": 28,
                    "head": 4,
                    "id": 5,
                    "lemma": "on",
                    "morph": "",
                    "pos": "ADP",
                    "start": 26,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 37,
                    "head": 5,
                    "id": 6,
                    "lemma": "weekday",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 29,
                    "tag": "NNS"
                  },
                  {
                    "dep": "punct",
                    "end": 38,
                    "head": 4,
                    "id": 7,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 37,
                    "tag": "."
                  }
                ]
              },
              "edits": [
                "Orig: [6, 7, 'weekday'], Cor: [6, 7, 'weekdays'], Type: 'R:NOUN:NUM'",
                "Orig: [8, 9, ' '], Cor: [8, 8, ''], Type: 'U:SPACE'"
              ],
              "err_ids": [
                6,
                8
              ],
              "error_span": 1,
              "error_types": [
                "Orig: [6, 7, 'weekday'], Cor: [6, 7, 'weekdays'], Type: 'R:NOUN:NUM'",
                "Orig: [8, 9, ' '], Cor: [8, 8, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [
                "R:NOUN:NUM"
              ],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_startwith_because": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "So it should be delivered on weekday.  ",
              "org_tokens": {
                "ents": [
                  {
                    "end": 36,
                    "label": "DATE",
                    "start": 29
                  }
                ],
                "sents": [
                  {
                    "end": 39,
                    "start": 0
                  }
                ],
                "text": "So it should be delivered on weekday.  ",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 2,
                    "head": 4,
                    "id": 0,
                    "lemma": "so",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "nsubjpass",
                    "end": 5,
                    "head": 4,
                    "id": 1,
                    "lemma": "it",
                    "morph": "Gender=Neut|Number=Sing|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 3,
                    "tag": "PRP"
                  },
                  {
                    "dep": "aux",
                    "end": 12,
                    "head": 4,
                    "id": 2,
                    "lemma": "should",
                    "morph": "VerbForm=Fin",
                    "pos": "AUX",
                    "start": 6,
                    "tag": "MD"
                  },
                  {
                    "dep": "auxpass",
                    "end": 15,
                    "head": 4,
                    "id": 3,
                    "lemma": "be",
                    "morph": "VerbForm=Inf",
                    "pos": "AUX",
                    "start": 13,
                    "tag": "VB"
                  },
                  {
                    "dep": "ROOT",
                    "end": 25,
                    "head": 4,
                    "id": 4,
                    "lemma": "deliver",
                    "morph": "Aspect=Perf|Tense=Past|VerbForm=Part",
                    "pos": "VERB",
                    "start": 16,
                    "tag": "VBN"
                  },
                  {
                    "dep": "prep",
                    "end": 28,
                    "head": 4,
                    "id": 5,
                    "lemma": "on",
                    "morph": "",
                    "pos": "ADP",
                    "start": 26,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 36,
                    "head": 5,
                    "id": 6,
                    "lemma": "weekday",
                    "morph": "Number=Sing",
                    "pos": "PROPN",
                    "start": 29,
                    "tag": "NNP"
                  },
                  {
                    "dep": "punct",
                    "end": 37,
                    "head": 4,
                    "id": 7,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 36,
                    "tag": "."
                  },
                  {
                    "dep": "dep",
                    "end": 39,
                    "head": 7,
                    "id": 8,
                    "lemma": " ",
                    "morph": "",
                    "pos": "SPACE",
                    "start": 38,
                    "tag": "_SP"
                  }
                ]
              },
              "r_err": 0.14285714285714285,
              "sentence_score": 0,
              "sentence_w_verr": 0,
              "simple_sent_1": 0,
              "simple_sent_2": 0,
              "tag": "EX1",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "If mail delivery on weekends is abolished, they can deliver mail on weekends with weekdays.",
              "cor_tokens": {
                "ents": [
                  {
                    "end": 28,
                    "label": "DATE",
                    "start": 20
                  },
                  {
                    "end": 76,
                    "label": "DATE",
                    "start": 68
                  }
                ],
                "sents": [
                  {
                    "end": 91,
                    "start": 0
                  }
                ],
                "text": "If mail delivery on weekends is abolished, they can deliver mail on weekends with weekdays.",
                "tokens": [
                  {
                    "dep": "mark",
                    "end": 2,
                    "head": 6,
                    "id": 0,
                    "lemma": "if",
                    "morph": "",
                    "pos": "SCONJ",
                    "start": 0,
                    "tag": "IN"
                  },
                  {
                    "dep": "compound",
                    "end": 7,
                    "head": 2,
                    "id": 1,
                    "lemma": "mail",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 3,
                    "tag": "NN"
                  },
                  {
                    "dep": "nsubjpass",
                    "end": 16,
                    "head": 6,
                    "id": 2,
                    "lemma": "delivery",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 8,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 19,
                    "head": 2,
                    "id": 3,
                    "lemma": "on",
                    "morph": "",
                    "pos": "ADP",
                    "start": 17,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 28,
                    "head": 3,
                    "id": 4,
                    "lemma": "weekend",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 20,
                    "tag": "NNS"
                  },
                  {
                    "dep": "auxpass",
                    "end": 31,
                    "head": 6,
                    "id": 5,
                    "lemma": "be",
                    "morph": "Mood=Ind|Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "AUX",
                    "start": 29,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "advcl",
                    "end": 41,
                    "head": 10,
                    "id": 6,
                    "lemma": "abolish",
                    "morph": "Aspect=Perf|Tense=Past|VerbForm=Part",
                    "pos": "VERB",
                    "start": 32,
                    "tag": "VBN"
                  },
                  {
                    "dep": "punct",
                    "end": 42,
                    "head": 10,
                    "id": 7,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 41,
                    "tag": ","
                  },
                  {
                    "dep": "nsubj",
                    "end": 47,
                    "head": 10,
                    "id": 8,
                    "lemma": "they",
                    "morph": "Case=Nom|Number=Plur|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 43,
                    "tag": "PRP"
                  },
                  {
                    "dep": "aux",
                    "end": 51,
                    "head": 10,
                    "id": 9,
                    "lemma": "can",
                    "morph": "VerbForm=Fin",
                    "pos": "AUX",
                    "start": 48,
                    "tag": "MD"
                  },
                  {
                    "dep": "ROOT",
                    "end": 59,
                    "head": 10,
                    "id": 10,
                    "lemma": "deliver",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 52,
                    "tag": "VB"
                  },
                  {
                    "dep": "dobj",
                    "end": 64,
                    "head": 10,
                    "id": 11,
                    "lemma": "mail",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 60,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 67,
                    "head": 10,
                    "id": 12,
                    "lemma": "on",
                    "morph": "",
                    "pos": "ADP",
                    "start": 65,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 76,
                    "head": 12,
                    "id": 13,
                    "lemma": "weekend",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 68,
                    "tag": "NNS"
                  },
                  {
                    "dep": "prep",
                    "end": 81,
                    "head": 10,
                    "id": 14,
                    "lemma": "with",
                    "morph": "",
                    "pos": "ADP",
                    "start": 77,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 90,
                    "head": 14,
                    "id": 15,
                    "lemma": "weekday",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 82,
                    "tag": "NNS"
                  },
                  {
                    "dep": "punct",
                    "end": 91,
                    "head": 10,
                    "id": 16,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 90,
                    "tag": "."
                  }
                ]
              },
              "edits": [
                "Orig: [11, 12, 'a'], Cor: [11, 11, ''], Type: 'U:DET'",
                "Orig: [16, 17, 'weekday'], Cor: [15, 16, 'weekdays'], Type: 'R:NOUN:NUM'",
                "Orig: [18, 19, ' '], Cor: [17, 17, ''], Type: 'U:SPACE'"
              ],
              "err_ids": [
                11,
                16,
                18
              ],
              "error_span": 1,
              "error_types": [
                "Orig: [11, 12, 'a'], Cor: [11, 11, ''], Type: 'U:DET'",
                "Orig: [16, 17, 'weekday'], Cor: [15, 16, 'weekdays'], Type: 'R:NOUN:NUM'",
                "Orig: [18, 19, ' '], Cor: [17, 17, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 1,
              "grammar_err": [
                "R:NOUN:NUM"
              ],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_startwith_because": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "If mail delivery on weekends is abolished, they can deliver a mail on weekends with weekday.  ",
              "org_tokens": {
                "ents": [
                  {
                    "end": 28,
                    "label": "DATE",
                    "start": 20
                  },
                  {
                    "end": 78,
                    "label": "DATE",
                    "start": 70
                  },
                  {
                    "end": 91,
                    "label": "DATE",
                    "start": 84
                  }
                ],
                "sents": [
                  {
                    "end": 94,
                    "start": 0
                  }
                ],
                "text": "If mail delivery on weekends is abolished, they can deliver a mail on weekends with weekday.  ",
                "tokens": [
                  {
                    "dep": "mark",
                    "end": 2,
                    "head": 6,
                    "id": 0,
                    "lemma": "if",
                    "morph": "",
                    "pos": "SCONJ",
                    "start": 0,
                    "tag": "IN"
                  },
                  {
                    "dep": "compound",
                    "end": 7,
                    "head": 2,
                    "id": 1,
                    "lemma": "mail",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 3,
                    "tag": "NN"
                  },
                  {
                    "dep": "nsubjpass",
                    "end": 16,
                    "head": 6,
                    "id": 2,
                    "lemma": "delivery",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 8,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 19,
                    "head": 2,
                    "id": 3,
                    "lemma": "on",
                    "morph": "",
                    "pos": "ADP",
                    "start": 17,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 28,
                    "head": 3,
                    "id": 4,
                    "lemma": "weekend",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 20,
                    "tag": "NNS"
                  },
                  {
                    "dep": "auxpass",
                    "end": 31,
                    "head": 6,
                    "id": 5,
                    "lemma": "be",
                    "morph": "Mood=Ind|Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "AUX",
                    "start": 29,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "advcl",
                    "end": 41,
                    "head": 10,
                    "id": 6,
                    "lemma": "abolish",
                    "morph": "Aspect=Perf|Tense=Past|VerbForm=Part",
                    "pos": "VERB",
                    "start": 32,
                    "tag": "VBN"
                  },
                  {
                    "dep": "punct",
                    "end": 42,
                    "head": 10,
                    "id": 7,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 41,
                    "tag": ","
                  },
                  {
                    "dep": "nsubj",
                    "end": 47,
                    "head": 10,
                    "id": 8,
                    "lemma": "they",
                    "morph": "Case=Nom|Number=Plur|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 43,
                    "tag": "PRP"
                  },
                  {
                    "dep": "aux",
                    "end": 51,
                    "head": 10,
                    "id": 9,
                    "lemma": "can",
                    "morph": "VerbForm=Fin",
                    "pos": "AUX",
                    "start": 48,
                    "tag": "MD"
                  },
                  {
                    "dep": "ROOT",
                    "end": 59,
                    "head": 10,
                    "id": 10,
                    "lemma": "deliver",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 52,
                    "tag": "VB"
                  },
                  {
                    "dep": "det",
                    "end": 61,
                    "head": 12,
                    "id": 11,
                    "lemma": "a",
                    "morph": "Definite=Ind|PronType=Art",
                    "pos": "DET",
                    "start": 60,
                    "tag": "DT"
                  },
                  {
                    "dep": "dobj",
                    "end": 66,
                    "head": 10,
                    "id": 12,
                    "lemma": "mail",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 62,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 69,
                    "head": 10,
                    "id": 13,
                    "lemma": "on",
                    "morph": "",
                    "pos": "ADP",
                    "start": 67,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 78,
                    "head": 13,
                    "id": 14,
                    "lemma": "weekend",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 70,
                    "tag": "NNS"
                  },
                  {
                    "dep": "prep",
                    "end": 83,
                    "head": 10,
                    "id": 15,
                    "lemma": "with",
                    "morph": "",
                    "pos": "ADP",
                    "start": 79,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 91,
                    "head": 15,
                    "id": 16,
                    "lemma": "weekday",
                    "morph": "Number=Sing",
                    "pos": "PROPN",
                    "start": 84,
                    "tag": "NNP"
                  },
                  {
                    "dep": "punct",
                    "end": 92,
                    "head": 10,
                    "id": 17,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 91,
                    "tag": "."
                  },
                  {
                    "dep": "dep",
                    "end": 94,
                    "head": 17,
                    "id": 18,
                    "lemma": " ",
                    "morph": "",
                    "pos": "SPACE",
                    "start": 93,
                    "tag": "_SP"
                  }
                ]
              },
              "r_err": 0.0625,
              "sentence_score": 0,
              "sentence_w_verr": 0,
              "simple_sent_1": 0,
              "simple_sent_2": 0,
              "tag": "EX1",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            },
            {
              "both_err": [],
              "cor_sent": "It reduces the stress of the staff.",
              "cor_tokens": {
                "ents": [],
                "sents": [
                  {
                    "end": 35,
                    "start": 0
                  }
                ],
                "text": "It reduces the stress of the staff.",
                "tokens": [
                  {
                    "dep": "nsubj",
                    "end": 2,
                    "head": 1,
                    "id": 0,
                    "lemma": "it",
                    "morph": "Case=Nom|Gender=Neut|Number=Sing|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 0,
                    "tag": "PRP"
                  },
                  {
                    "dep": "ROOT",
                    "end": 10,
                    "head": 1,
                    "id": 1,
                    "lemma": "reduce",
                    "morph": "Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "VERB",
                    "start": 3,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "det",
                    "end": 14,
                    "head": 3,
                    "id": 2,
                    "lemma": "the",
                    "morph": "Definite=Def|PronType=Art",
                    "pos": "DET",
                    "start": 11,
                    "tag": "DT"
                  },
                  {
                    "dep": "dobj",
                    "end": 21,
                    "head": 1,
                    "id": 3,
                    "lemma": "stress",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 15,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 24,
                    "head": 3,
                    "id": 4,
                    "lemma": "of",
                    "morph": "",
                    "pos": "ADP",
                    "start": 22,
                    "tag": "IN"
                  },
                  {
                    "dep": "det",
                    "end": 28,
                    "head": 6,
                    "id": 5,
                    "lemma": "the",
                    "morph": "Definite=Def|PronType=Art",
                    "pos": "DET",
                    "start": 25,
                    "tag": "DT"
                  },
                  {
                    "dep": "pobj",
                    "end": 34,
                    "head": 4,
                    "id": 6,
                    "lemma": "staff",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 29,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 35,
                    "head": 1,
                    "id": 7,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 34,
                    "tag": "."
                  }
                ]
              },
              "edits": [
                "Orig: [1, 2, 'reduce'], Cor: [1, 2, 'reduces'], Type: 'R:VERB:SVA'",
                "Orig: [2, 3, 'a'], Cor: [2, 3, 'the'], Type: 'R:DET'",
                "Orig: [8, 9, '\\n'], Cor: [8, 8, ''], Type: 'U:SPACE'"
              ],
              "err_ids": [
                1,
                2,
                8
              ],
              "error_span": 1,
              "error_types": [
                "Orig: [1, 2, 'reduce'], Cor: [1, 2, 'reduces'], Type: 'R:VERB:SVA'",
                "Orig: [2, 3, 'a'], Cor: [2, 3, 'the'], Type: 'R:DET'",
                "Orig: [8, 9, '\\n'], Cor: [8, 8, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [
                "R:VERB:SVA"
              ],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_startwith_because": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "It reduce a stress of the staff. \n",
              "org_tokens": {
                "ents": [],
                "sents": [
                  {
                    "end": 34,
                    "start": 0
                  }
                ],
                "text": "It reduce a stress of the staff. \n",
                "tokens": [
                  {
                    "dep": "nsubj",
                    "end": 2,
                    "head": 1,
                    "id": 0,
                    "lemma": "it",
                    "morph": "Case=Nom|Gender=Neut|Number=Sing|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 0,
                    "tag": "PRP"
                  },
                  {
                    "dep": "ROOT",
                    "end": 9,
                    "head": 1,
                    "id": 1,
                    "lemma": "reduce",
                    "morph": "Tense=Pres|VerbForm=Fin",
                    "pos": "VERB",
                    "start": 3,
                    "tag": "VBP"
                  },
                  {
                    "dep": "det",
                    "end": 11,
                    "head": 3,
                    "id": 2,
                    "lemma": "a",
                    "morph": "Definite=Ind|PronType=Art",
                    "pos": "DET",
                    "start": 10,
                    "tag": "DT"
                  },
                  {
                    "dep": "dobj",
                    "end": 18,
                    "head": 1,
                    "id": 3,
                    "lemma": "stress",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 12,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 21,
                    "head": 3,
                    "id": 4,
                    "lemma": "of",
                    "morph": "",
                    "pos": "ADP",
                    "start": 19,
                    "tag": "IN"
                  },
                  {
                    "dep": "det",
                    "end": 25,
                    "head": 6,
                    "id": 5,
                    "lemma": "the",
                    "morph": "Definite=Def|PronType=Art",
                    "pos": "DET",
                    "start": 22,
                    "tag": "DT"
                  },
                  {
                    "dep": "pobj",
                    "end": 31,
                    "head": 4,
                    "id": 6,
                    "lemma": "staff",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 26,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 32,
                    "head": 1,
                    "id": 7,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 31,
                    "tag": "."
                  },
                  {
                    "dep": "dep",
                    "end": 34,
                    "head": 7,
                    "id": 8,
                    "lemma": "\n",
                    "morph": "",
                    "pos": "SPACE",
                    "start": 33,
                    "tag": "_SP"
                  }
                ]
              },
              "r_err": 0.14285714285714285,
              "sentence_score": 0,
              "sentence_w_verr": 1,
              "simple_sent_1": 0,
              "simple_sent_2": 0,
              "tag": "EX1",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            }
          ],
          "R": "Second, amount of mail on weekends is a little than weekday.",
          "R_sent": [
            {
              "both_err": [],
              "cor_sent": "Second, the amount of mail on weekends is less than on weekdays.",
              "cor_tokens": {
                "ents": [
                  {
                    "end": 6,
                    "label": "ORDINAL",
                    "start": 0
                  },
                  {
                    "end": 38,
                    "label": "DATE",
                    "start": 30
                  },
                  {
                    "end": 63,
                    "label": "DATE",
                    "start": 55
                  }
                ],
                "sents": [
                  {
                    "end": 64,
                    "start": 0
                  }
                ],
                "text": "Second, the amount of mail on weekends is less than on weekdays.",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 6,
                    "head": 8,
                    "id": 0,
                    "lemma": "second",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "punct",
                    "end": 7,
                    "head": 8,
                    "id": 1,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 6,
                    "tag": ","
                  },
                  {
                    "dep": "det",
                    "end": 11,
                    "head": 3,
                    "id": 2,
                    "lemma": "the",
                    "morph": "Definite=Def|PronType=Art",
                    "pos": "DET",
                    "start": 8,
                    "tag": "DT"
                  },
                  {
                    "dep": "nsubj",
                    "end": 18,
                    "head": 8,
                    "id": 3,
                    "lemma": "amount",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 12,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 21,
                    "head": 3,
                    "id": 4,
                    "lemma": "of",
                    "morph": "",
                    "pos": "ADP",
                    "start": 19,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 26,
                    "head": 4,
                    "id": 5,
                    "lemma": "mail",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 22,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 29,
                    "head": 3,
                    "id": 6,
                    "lemma": "on",
                    "morph": "",
                    "pos": "ADP",
                    "start": 27,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 38,
                    "head": 6,
                    "id": 7,
                    "lemma": "weekend",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 30,
                    "tag": "NNS"
                  },
                  {
                    "dep": "ROOT",
                    "end": 41,
                    "head": 8,
                    "id": 8,
                    "lemma": "be",
                    "morph": "Mood=Ind|Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "AUX",
                    "start": 39,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "acomp",
                    "end": 46,
                    "head": 8,
                    "id": 9,
                    "lemma": "less",
                    "morph": "Degree=Cmp",
                    "pos": "ADJ",
                    "start": 42,
                    "tag": "JJR"
                  },
                  {
                    "dep": "prep",
                    "end": 51,
                    "head": 9,
                    "id": 10,
                    "lemma": "than",
                    "morph": "",
                    "pos": "ADP",
                    "start": 47,
                    "tag": "IN"
                  },
                  {
                    "dep": "prep",
                    "end": 54,
                    "head": 10,
                    "id": 11,
                    "lemma": "on",
                    "morph": "",
                    "pos": "ADP",
                    "start": 52,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 63,
                    "head": 11,
                    "id": 12,
                    "lemma": "weekday",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 55,
                    "tag": "NNS"
                  },
                  {
                    "dep": "punct",
                    "end": 64,
                    "head": 8,
                    "id": 13,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 63,
                    "tag": "."
                  }
                ]
              },
              "edits": [
                "Orig: [2, 2, ''], Cor: [2, 3, 'the'], Type: 'M:DET'",
                "Orig: [8, 10, 'a little'], Cor: [9, 10, 'less'], Type: 'R:OTHER'",
                "Orig: [11, 11, ''], Cor: [11, 12, 'on'], Type: 'M:PREP'",
                "Orig: [11, 12, 'weekday'], Cor: [12, 13, 'weekdays'], Type: 'R:NOUN:NUM'",
                "Orig: [13, 14, ' '], Cor: [14, 14, ''], Type: 'U:SPACE'"
              ],
              "err_ids": [
                8,
                9,
                11,
                13
              ],
              "error_span": 3,
              "error_types": [
                "Orig: [2, 2, ''], Cor: [2, 3, 'the'], Type: 'M:DET'",
                "Orig: [8, 10, 'a little'], Cor: [9, 10, 'less'], Type: 'R:OTHER'",
                "Orig: [11, 11, ''], Cor: [11, 12, 'on'], Type: 'M:PREP'",
                "Orig: [11, 12, 'weekday'], Cor: [12, 13, 'weekdays'], Type: 'R:NOUN:NUM'",
                "Orig: [13, 14, ' '], Cor: [14, 14, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 1,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [
                "M:PREP",
                "R:NOUN:NUM"
              ],
              "grammar_sentence_score": 1,
              "is_grammar_error_critical": 1,
              "is_incomplete_sentence": 0,
              "is_startwith_because": 0,
              "is_vocabulary_error_critical": 1,
              "n_err": 3,
              "org_sent": "Second, amount of mail on weekends is a little than weekday.  ",
              "org_tokens": {
                "ents": [
                  {
                    "end": 6,
                    "label": "ORDINAL",
                    "start": 0
                  },
                  {
                    "end": 34,
                    "label": "DATE",
                    "start": 26
                  },
                  {
                    "end": 59,
                    "label": "DATE",
                    "start": 38
                  }
                ],
                "sents": [
                  {
                    "end": 62,
                    "start": 0
                  }
                ],
                "text": "Second, amount of mail on weekends is a little than weekday.  ",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 6,
                    "head": 7,
                    "id": 0,
                    "lemma": "second",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "punct",
                    "end": 7,
                    "head": 7,
                    "id": 1,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 6,
                    "tag": ","
                  },
                  {
                    "dep": "nsubj",
                    "end": 14,
                    "head": 7,
                    "id": 2,
                    "lemma": "amount",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 8,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 17,
                    "head": 2,
                    "id": 3,
                    "lemma": "of",
                    "morph": "",
                    "pos": "ADP",
                    "start": 15,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 22,
                    "head": 3,
                    "id": 4,
                    "lemma": "mail",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 18,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 25,
                    "head": 2,
                    "id": 5,
                    "lemma": "on",
                    "morph": "",
                    "pos": "ADP",
                    "start": 23,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 34,
                    "head": 5,
                    "id": 6,
                    "lemma": "weekend",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 26,
                    "tag": "NNS"
                  },
                  {
                    "dep": "ROOT",
                    "end": 37,
                    "head": 7,
                    "id": 7,
                    "lemma": "be",
                    "morph": "Mood=Ind|Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "AUX",
                    "start": 35,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "det",
                    "end": 39,
                    "head": 9,
                    "id": 8,
                    "lemma": "a",
                    "morph": "Definite=Ind|PronType=Art",
                    "pos": "DET",
                    "start": 38,
                    "tag": "DT"
                  },
                  {
                    "dep": "attr",
                    "end": 46,
                    "head": 7,
                    "id": 9,
                    "lemma": "little",
                    "morph": "Degree=Pos",
                    "pos": "ADJ",
                    "start": 40,
                    "tag": "JJ"
                  },
                  {
                    "dep": "prep",
                    "end": 51,
                    "head": 9,
                    "id": 10,
                    "lemma": "than",
                    "morph": "",
                    "pos": "ADP",
                    "start": 47,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 59,
                    "head": 10,
                    "id": 11,
                    "lemma": "weekday",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 52,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 60,
                    "head": 7,
                    "id": 12,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 59,
                    "tag": "."
                  },
                  {
                    "dep": "dep",
                    "end": 62,
                    "head": 12,
                    "id": 13,
                    "lemma": " ",
                    "morph": "",
                    "pos": "SPACE",
                    "start": 61,
                    "tag": "_SP"
                  }
                ]
              },
              "r_err": 0.2727272727272727,
              "sentence_score": 1,
              "sentence_w_verr": 0,
              "simple_sent_1": 0,
              "simple_sent_2": 0,
              "tag": "R1",
              "vocabulary_err": [
                "R:OTHER"
              ],
              "vocabulary_sentence_score": 1
            }
          ],
          "co_EX": "So mail delivery on weekends should be delivered on weekday. If mail delivery on weekends is abolished, mail delivery staff can deliver mail on weekends with weekday. It reduces a stress of the mail delivery staff.",
          "co_R": "Second, amount of mail on weekends is a little than weekday.",
          "no_conj_EX": "So it should be delivered on weekday If mail delivery on weekends is abolished they can deliver a mail on weekends with weekday It reduce a stress of the staff.",
          "no_conj_R": "Amount of mail on weekends is a little than weekday.",
          "no_conj_co_EX": "So mail delivery on weekends should be delivered on weekday If mail delivery on weekends is abolished mail delivery staff can deliver mail on weekends with weekday It reduces a stress of the mail delivery staff.",
          "no_conj_co_R": "Amount of mail on weekends is a little than weekday."
        },
        "p2": {},
        "question_data": {
          "keywords": [
            "convenience",
            "workload balance",
            "internet",
            "working conditions"
          ],
          "question_text": "Do you think mail delivery on weekends should be abolished?"
        }
      },
      "scoring_status": "Step4:scoring success"
    }
  },
  "level3": {
    "1": {
      "coherence": {
        "all_error_num": 0,
        "all_error_ratio": 0.0,
        "bf_error_pairs": [],
        "checklist_1": 0,
        "checklist_18": 0,
        "checklist_19": 0,
        "checklist_2": 0,
        "checklist_20": 0,
        "checklist_21": 0,
        "checklist_22": 0,
        "checklist_23": 1,
        "checklist_24": 0,
        "checklist_25": 0,
        "checklist_26": 0,
        "checklist_27": 0,
        "checklist_28": 0,
        "correct_sent_num": 6,
        "minimal_essay_score": 0,
        "num_wo_conj_sent": 2,
        "para_score": 0,
        "ratio_simple_conj": 0.3333333333333333,
        "ratio_wo_conj_sent": 0.4,
        "sys_coherence": 3
      },
      "content": {
        "abnormal_response_score": null,
        "checklist_15": null,
        "checklist_16": null,
        "checklist_17": null,
        "len_filter_score": -1.0,
        "non_english_score": -1,
        "offtopic_score": -1,
        "opinion_label": 0,
        "p0_checklist_results": {
          "checklist_10": 1,
          "checklist_11": 0,
          "checklist_12": 1,
          "checklist_345": 1,
          "checklist_4": 0,
          "checklist_9": 1,
          "checklist_all": 1,
          "explanation_len": 0,
          "relevance_score": 0.0,
          "sent_feats": [
            0.0,
            10
          ]
        },
        "p1_checklist_results": {
          "checklist_10": 0,
          "checklist_11": 0,
          "checklist_12": 0,
          "checklist_345": 0,
          "checklist_4": 0,
          "checklist_9": 0,
          "checklist_all": 0,
          "explanation_len": 22,
          "relevance_score": 0.0,
          "sent_feats": [
            0.0,
            0.5
          ]
        },
        "sys_content": 3
      },
      "grammar_vocabulary": {
        "grammar_checklist_items": {
          "grammar_checklist_30": 0,
          "grammar_checklist_31": 0,
          "grammar_checklist_32": 0,
          "grammar_checklist_33": 0,
          "grammar_checklist_34": 0,
          "grammar_checklist_35": 0,
          "grammar_checklist_36": 0,
          "grammar_checklist_37": 0,
          "grammar_checklist_38": 0
        },
        "grammar_complexity_score": 3,
        "grammar_sentence_scores": [
          0,
          0,
          0,
          0,
          0
        ],
        "max_sent_len": 20,
        "mean_sent_len": 16.0,
        "n_orth_error": 0,
        "n_spell_error": 0,
        "numwds": 73,
        "sentences": [
          {
            "both_err": [],
            "cor_sent": "I think students should not have any homework.",
            "err_ids": [
              6,
              9
            ],
            "error_span": 0,
            "error_types": [
              "Orig: [6, 7, 'no'], Cor: [6, 7, 'any'], Type: 'R:DET'",
              "Orig: [9, 10, ' '], Cor: [9, 9, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 1,
            "grammar_err": [],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 0,
            "org_sent": "I think students should not have no homework.  ",
            "r_err": 0.0,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "Opinion",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [],
            "cor_sent": "First, it is important for children to have free time and choose what they want after school.",
            "err_ids": [
              19
            ],
            "error_span": 0,
            "error_types": [
              "Orig: [19, 20, ' '], Cor: [19, 19, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 1,
            "grammar_err": [],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 0,
            "org_sent": "First, it is important for children to have free time and choose what they want after school.  ",
            "r_err": 0.0,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "R0",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [],
            "cor_sent": "In addition, not having homework will save teachers' time.",
            "err_ids": [
              4,
              12
            ],
            "error_span": 0,
            "error_types": [
              "Orig: [3, 3, ''], Cor: [3, 4, 'not'], Type: 'M:ADV'",
              "Orig: [4, 5, 'no'], Cor: [5, 5, ''], Type: 'U:DET'",
              "Orig: [12, 13, ' '], Cor: [12, 12, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 1,
            "org_sent": "In addition, having no homework will save teachers' time.  ",
            "r_err": 0.1111111111111111,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "R1",
            "vocabulary_err": [
              "M:ADV"
            ],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [],
            "cor_sent": "If there is homework, teachers spend a lot of time on it.",
            "err_ids": [
              7,
              10,
              12
            ],
            "error_span": 2,
            "error_types": [
              "Orig: [7, 8, 'many'], Cor: [7, 10, 'a lot of'], Type: 'R:OTHER'",
              "Orig: [10, 11, 'homework'], Cor: [12, 13, 'it'], Type: 'R:OTHER'",
              "Orig: [12, 13, ' '], Cor: [14, 14, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 1,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 1,
            "grammar_err": [],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 1,
            "n_err": 2,
            "org_sent": "If there is homework, teachers spend many time on homework.  ",
            "r_err": 0.2,
            "sentence_score": 1,
            "sentence_w_verr": 0,
            "tag": "EX1",
            "vocabulary_err": [
              "R:OTHER",
              "R:OTHER"
            ],
            "vocabulary_sentence_score": 1
          },
          {
            "both_err": [],
            "cor_sent": "Sometimes it takes more than one or two hours to grade homework.",
            "err_ids": [
              0,
              13
            ],
            "error_span": 1,
            "error_types": [
              "Orig: [0, 1, 'Sometime'], Cor: [0, 1, 'Sometimes'], Type: 'R:MORPH'",
              "Orig: [13, 14, ' '], Cor: [13, 13, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [
              "R:MORPH"
            ],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 1,
            "org_sent": "Sometime it takes more than one or two hours to grade homework.  ",
            "r_err": 0.08333333333333333,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "EX1",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [],
            "cor_sent": "In conclusion, no homework will make both students and teachers happy, so students should not have any homework.",
            "err_ids": [
              17
            ],
            "error_span": 0,
            "error_types": [
              "Orig: [16, 16, ''], Cor: [16, 17, 'not'], Type: 'M:ADV'",
              "Orig: [17, 18, 'no'], Cor: [18, 19, 'any'], Type: 'R:DET'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 1,
            "grammar_err": [],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 1,
            "org_sent": "In conclusion, no homework will make both students and teachers happy, so students should have no homework.",
            "r_err": 0.058823529411764705,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "ELSE",
            "vocabulary_err": [
              "M:ADV"
            ],
            "vocabulary_sentence_score": 0
          }
        ],
        "sys_grammar": 4,
        "sys_grammar_max_sentence_score": 0,
        "sys_grammar_mean_sentence_score": 0.0,
        "sys_vocabulary": 3,
        "sys_vocabulary_max_sentence_score": 1,
        "sys_vocabulary_mean_sentence_score": 0.2,
        "vocabulary_checklist_items": {
          "vocabulary_checklist_30": 0,
          "vocabulary_checklist_31": 0,
          "vocabulary_checklist_32": 0,
          "vocabulary_checklist_33": 0,
          "vocabulary_checklist_34": 1,
          "vocabulary_checklist_35": 0,
          "vocabulary_checklist_36": 0,
          "vocabulary_checklist_37": 0,
          "vocabulary_checklist_38": 0
        },
        "vocabulary_complexity_score": 0.038461538461538464,
        "vocabulary_sentence_scores": [
          0,
          0,
          1,
          0,
          0
        ]
      },
      "input": {
        "input_text": "I think students should not have no homework.  First, it is important for children to have free time and choose what they want after school.  In addition, having no homework will save teachers' time.  If there is homework, teachers spend many time on homework.  Sometime it takes more than one or two hours to grade homework.  In conclusion, no homework will make both students and teachers happy, so students should have no homework. ",
        "opinion": "I think students should not have no homework.",
        "opinion_position": "no",
        "p0": {
          "EX": "",
          "EX_sent": [],
          "R": "First, it is important for children to have free time and choose what they want after school.",
          "R_sent": [
            {
              "both_err": [],
              "cor_sent": "First, it is important for children to have free time and choose what they want after school.",
              "cor_tokens": {
                "ents": [
                  {
                    "end": 5,
                    "label": "ORDINAL",
                    "start": 0
                  }
                ],
                "sents": [
                  {
                    "end": 93,
                    "start": 0
                  }
                ],
                "text": "First, it is important for children to have free time and choose what they want after school.",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 5,
                    "head": 3,
                    "id": 0,
                    "lemma": "first",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "punct",
                    "end": 6,
                    "head": 3,
                    "id": 1,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 5,
                    "tag": ","
                  },
                  {
                    "dep": "nsubj",
                    "end": 9,
                    "head": 3,
                    "id": 2,
                    "lemma": "it",
                    "morph": "Case=Nom|Gender=Neut|Number=Sing|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 7,
                    "tag": "PRP"
                  },
                  {
                    "dep": "ROOT",
                    "end": 12,
                    "head": 3,
                    "id": 3,
                    "lemma": "be",
                    "morph": "Mood=Ind|Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "AUX",
                    "start": 10,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "acomp",
                    "end": 22,
                    "head": 3,
                    "id": 4,
                    "lemma": "important",
                    "morph": "Degree=Pos",
                    "pos": "ADJ",
                    "start": 13,
                    "tag": "JJ"
                  },
                  {
                    "dep": "mark",
                    "end": 26,
                    "head": 8,
                    "id": 5,
                    "lemma": "for",
                    "morph": "",
                    "pos": "SCONJ",
                    "start": 23,
                    "tag": "IN"
                  },
                  {
                    "dep": "nsubj",
                    "end": 35,
                    "head": 8,
                    "id": 6,
                    "lemma": "child",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 27,
                    "tag": "NNS"
                  },
                  {
                    "dep": "aux",
                    "end": 38,
                    "head": 8,
                    "id": 7,
                    "lemma": "to",
                    "morph": "",
                    "pos": "PART",
                    "start": 36,
                    "tag": "TO"
                  },
                  {
                    "dep": "advcl",
                    "end": 43,
                    "head": 3,
                    "id": 8,
                    "lemma": "have",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 39,
                    "tag": "VB"
                  },
                  {
                    "dep": "amod",
                    "end": 48,
                    "head": 10,
                    "id": 9,
                    "lemma": "free",
                    "morph": "Degree=Pos",
                    "pos": "ADJ",
                    "start": 44,
                    "tag": "JJ"
                  },
                  {
                    "dep": "dobj",
                    "end": 53,
                    "head": 8,
                    "id": 10,
                    "lemma": "time",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 49,
                    "tag": "NN"
                  },
                  {
                    "dep": "cc",
                    "end": 57,
                    "head": 8,
                    "id": 11,
                    "lemma": "and",
                    "morph": "ConjType=Cmp",
                    "pos": "CCONJ",
                    "start": 54,
                    "tag": "CC"
                  },
                  {
                    "dep": "conj",
                    "end": 64,
                    "head": 8,
                    "id": 12,
                    "lemma": "choose",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 58,
                    "tag": "VB"
                  },
                  {
                    "dep": "dobj",
                    "end": 69,
                    "head": 15,
                    "id": 13,
                    "lemma": "what",
                    "morph": "",
                    "pos": "PRON",
                    "start": 65,
                    "tag": "WP"
                  },
                  {
                    "dep": "nsubj",
                    "end": 74,
                    "head": 15,
                    "id": 14,
                    "lemma": "they",
                    "morph": "Case=Nom|Number=Plur|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 70,
                    "tag": "PRP"
                  },
                  {
                    "dep": "ccomp",
                    "end": 79,
                    "head": 12,
                    "id": 15,
                    "lemma": "want",
                    "morph": "Tense=Pres|VerbForm=Fin",
                    "pos": "VERB",
                    "start": 75,
                    "tag": "VBP"
                  },
                  {
                    "dep": "prep",
                    "end": 85,
                    "head": 15,
                    "id": 16,
                    "lemma": "after",
                    "morph": "",
                    "pos": "ADP",
                    "start": 80,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 92,
                    "head": 16,
                    "id": 17,
                    "lemma": "school",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 86,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 93,
                    "head": 3,
                    "id": 18,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 92,
                    "tag": "."
                  }
                ]
              },
              "edits": [
                "Orig: [19, 20, ' '], Cor: [19, 19, ''], Type: 'U:SPACE'"
              ],
              "err_ids": [
                19
              ],
              "error_span": 0,
              "error_types": [
                "Orig: [19, 20, ' '], Cor: [19, 19, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 1,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_startwith_because": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "First, it is important for children to have free time and choose what they want after school.  ",
              "org_tokens": {
                "ents": [
                  {
                    "end": 5,
                    "label": "ORDINAL",
                    "start": 0
                  }
                ],
                "sents": [
                  {
                    "end": 95,
                    "start": 0
                  }
                ],
                "text": "First, it is important for children to have free time and choose what they want after school.  ",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 5,
                    "head": 3,
                    "id": 0,
                    "lemma": "first",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "punct",
                    "end": 6,
                    "head": 3,
                    "id": 1,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 5,
                    "tag": ","
                  },
                  {
                    "dep": "nsubj",
                    "end": 9,
                    "head": 3,
                    "id": 2,
                    "lemma": "it",
                    "morph": "Case=Nom|Gender=Neut|Number=Sing|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 7,
                    "tag": "PRP"
                  },
                  {
                    "dep": "ROOT",
                    "end": 12,
                    "head": 3,
                    "id": 3,
                    "lemma": "be",
                    "morph": "Mood=Ind|Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "AUX",
                    "start": 10,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "acomp",
                    "end": 22,
                    "head": 3,
                    "id": 4,
                    "lemma": "important",
                    "morph": "Degree=Pos",
                    "pos": "ADJ",
                    "start": 13,
                    "tag": "JJ"
                  },
                  {
                    "dep": "mark",
                    "end": 26,
                    "head": 8,
                    "id": 5,
                    "lemma": "for",
                    "morph": "",
                    "pos": "SCONJ",
                    "start": 23,
                    "tag": "IN"
                  },
                  {
                    "dep": "nsubj",
                    "end": 35,
                    "head": 8,
                    "id": 6,
                    "lemma": "child",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 27,
                    "tag": "NNS"
                  },
                  {
                    "dep": "aux",
                    "end": 38,
                    "head": 8,
                    "id": 7,
                    "lemma": "to",
                    "morph": "",
                    "pos": "PART",
                    "start": 36,
                    "tag": "TO"
                  },
                  {
                    "dep": "advcl",
                    "end": 43,
                    "head": 3,
                    "id": 8,
                    "lemma": "have",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 39,
                    "tag": "VB"
                  },
                  {
                    "dep": "amod",
                    "end": 48,
                    "head": 10,
                    "id": 9,
                    "lemma": "free",
                    "morph": "Degree=Pos",
                    "pos": "ADJ",
                    "start": 44,
                    "tag": "JJ"
                  },
                  {
                    "dep": "dobj",
                    "end": 53,
                    "head": 8,
                    "id": 10,
                    "lemma": "time",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 49,
                    "tag": "NN"
                  },
                  {
                    "dep": "cc",
                    "end": 57,
                    "head": 8,
                    "id": 11,
                    "lemma": "and",
                    "morph": "ConjType=Cmp",
                    "pos": "CCONJ",
                    "start": 54,
                    "tag": "CC"
                  },
                  {
                    "dep": "conj",
                    "end": 64,
                    "head": 8,
                    "id": 12,
                    "lemma": "choose",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 58,
                    "tag": "VB"
                  },
                  {
                    "dep": "dobj",
                    "end": 69,
                    "head": 15,
                    "id": 13,
                    "lemma": "what",
                    "morph": "",
                    "pos": "PRON",
                    "start": 65,
                    "tag": "WP"
                  },
                  {
                    "dep": "nsubj",
                    "end": 74,
                    "head": 15,
                    "id": 14,
                    "lemma": "they",
                    "morph": "Case=Nom|Number=Plur|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 70,
                    "tag": "PRP"
                  },
                  {
                    "dep": "ccomp",
                    "end": 79,
                    "head": 12,
                    "id": 15,
                    "lemma": "want",
                    "morph": "Tense=Pres|VerbForm=Fin",
                    "pos": "VERB",
                    "start": 75,
                    "tag": "VBP"
                  },
                  {
                    "dep": "prep",
                    "end": 85,
                    "head": 15,
                    "id": 16,
                    "lemma": "after",
                    "morph": "",
                    "pos": "ADP",
                    "start": 80,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 92,
                    "head": 16,
                    "id": 17,
                    "lemma": "school",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 86,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 93,
                    "head": 3,
                    "id": 18,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 92,
                    "tag": "."
                  },
                  {
                    "dep": "dep",
                    "end": 95,
                    "head": 18,
                    "id": 19,
                    "lemma": " ",
                    "morph": "",
                    "pos": "SPACE",
                    "start": 94,
                    "tag": "_SP"
                  }
                ]
              },
              "r_err": 0.0,
              "sentence_score": 0,
              "sentence_w_verr": 0,
              "simple_sent_1": 0,
              "simple_sent_2": 0,
              "tag": "R0",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            }
          ],
          "co_EX": "",
          "co_R": "First, having free time and choosing what children want after school is important for children.",
          "no_conj_EX": "",
          "no_conj_R": "It is important for children to have free time and choose what they want after school.",
          "no_conj_co_EX": "",
          "no_conj_co_R": "Having free time and choosing what children want after school is important for children."
        },
        "p1": {
          "EX": "If there is homework, teachers spend many time on homework. Sometime it takes more than one or two hours to grade homework.",
          "EX_sent": [
            {
              "both_err": [],
              "cor_sent": "If there is homework, teachers spend a lot of time on it.",
              "cor_tokens": {
                "ents": [],
                "sents": [
                  {
                    "end": 57,
                    "start": 0
                  }
                ],
                "text": "If there is homework, teachers spend a lot of time on it.",
                "tokens": [
                  {
                    "dep": "mark",
                    "end": 2,
                    "head": 2,
                    "id": 0,
                    "lemma": "if",
                    "morph": "",
                    "pos": "SCONJ",
                    "start": 0,
                    "tag": "IN"
                  },
                  {
                    "dep": "expl",
                    "end": 8,
                    "head": 2,
                    "id": 1,
                    "lemma": "there",
                    "morph": "",
                    "pos": "PRON",
                    "start": 3,
                    "tag": "EX"
                  },
                  {
                    "dep": "advcl",
                    "end": 11,
                    "head": 6,
                    "id": 2,
                    "lemma": "be",
                    "morph": "Mood=Ind|Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "VERB",
                    "start": 9,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "attr",
                    "end": 20,
                    "head": 2,
                    "id": 3,
                    "lemma": "homework",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 12,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 21,
                    "head": 6,
                    "id": 4,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 20,
                    "tag": ","
                  },
                  {
                    "dep": "nsubj",
                    "end": 30,
                    "head": 6,
                    "id": 5,
                    "lemma": "teacher",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 22,
                    "tag": "NNS"
                  },
                  {
                    "dep": "ROOT",
                    "end": 36,
                    "head": 6,
                    "id": 6,
                    "lemma": "spend",
                    "morph": "Tense=Pres|VerbForm=Fin",
                    "pos": "VERB",
                    "start": 31,
                    "tag": "VBP"
                  },
                  {
                    "dep": "det",
                    "end": 38,
                    "head": 8,
                    "id": 7,
                    "lemma": "a",
                    "morph": "Definite=Ind|PronType=Art",
                    "pos": "DET",
                    "start": 37,
                    "tag": "DT"
                  },
                  {
                    "dep": "dobj",
                    "end": 42,
                    "head": 6,
                    "id": 8,
                    "lemma": "lot",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 39,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 45,
                    "head": 8,
                    "id": 9,
                    "lemma": "of",
                    "morph": "",
                    "pos": "ADP",
                    "start": 43,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 50,
                    "head": 9,
                    "id": 10,
                    "lemma": "time",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 46,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 53,
                    "head": 6,
                    "id": 11,
                    "lemma": "on",
                    "morph": "",
                    "pos": "ADP",
                    "start": 51,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 56,
                    "head": 11,
                    "id": 12,
                    "lemma": "it",
                    "morph": "Case=Acc|Gender=Neut|Number=Sing|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 54,
                    "tag": "PRP"
                  },
                  {
                    "dep": "punct",
                    "end": 57,
                    "head": 6,
                    "id": 13,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 56,
                    "tag": "."
                  }
                ]
              },
              "edits": [
                "Orig: [7, 8, 'many'], Cor: [7, 10, 'a lot of'], Type: 'R:OTHER'",
                "Orig: [10, 11, 'homework'], Cor: [12, 13, 'it'], Type: 'R:OTHER'",
                "Orig: [12, 13, ' '], Cor: [14, 14, ''], Type: 'U:SPACE'"
              ],
              "err_ids": [
                7,
                10,
                12
              ],
              "error_span": 2,
              "error_types": [
                "Orig: [7, 8, 'many'], Cor: [7, 10, 'a lot of'], Type: 'R:OTHER'",
                "Orig: [10, 11, 'homework'], Cor: [12, 13, 'it'], Type: 'R:OTHER'",
                "Orig: [12, 13, ' '], Cor: [14, 14, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 1,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 1,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_startwith_because": 0,
              "is_vocabulary_error_critical": 1,
              "n_err": 2,
              "org_sent": "If there is homework, teachers spend many time on homework.  ",
              "org_tokens": {
                "ents": [],
                "sents": [
                  {
                    "end": 61,
                    "start": 0
                  }
                ],
                "text": "If there is homework, teachers spend many time on homework.  ",
                "tokens": [
                  {
                    "dep": "mark",
                    "end": 2,
                    "head": 2,
                    "id": 0,
                    "lemma": "if",
                    "morph": "",
                    "pos": "SCONJ",
                    "start": 0,
                    "tag": "IN"
                  },
                  {
                    "dep": "expl",
                    "end": 8,
                    "head": 2,
                    "id": 1,
                    "lemma": "there",
                    "morph": "",
                    "pos": "PRON",
                    "start": 3,
                    "tag": "EX"
                  },
                  {
                    "dep": "advcl",
                    "end": 11,
                    "head": 6,
                    "id": 2,
                    "lemma": "be",
                    "morph": "Mood=Ind|Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "VERB",
                    "start": 9,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "attr",
                    "end": 20,
                    "head": 2,
                    "id": 3,
                    "lemma": "homework",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 12,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 21,
                    "head": 6,
                    "id": 4,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 20,
                    "tag": ","
                  },
                  {
                    "dep": "nsubj",
                    "end": 30,
                    "head": 6,
                    "id": 5,
                    "lemma": "teacher",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 22,
                    "tag": "NNS"
                  },
                  {
                    "dep": "ROOT",
                    "end": 36,
                    "head": 6,
                    "id": 6,
                    "lemma": "spend",
                    "morph": "Tense=Pres|VerbForm=Fin",
                    "pos": "VERB",
                    "start": 31,
                    "tag": "VBP"
                  },
                  {
                    "dep": "amod",
                    "end": 41,
                    "head": 8,
                    "id": 7,
                    "lemma": "many",
                    "morph": "Degree=Pos",
                    "pos": "ADJ",
                    "start": 37,
                    "tag": "JJ"
                  },
                  {
                    "dep": "dobj",
                    "end": 46,
                    "head": 6,
                    "id": 8,
                    "lemma": "time",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 42,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 49,
                    "head": 6,
                    "id": 9,
                    "lemma": "on",
                    "morph": "",
                    "pos": "ADP",
                    "start": 47,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 58,
                    "head": 9,
                    "id": 10,
                    "lemma": "homework",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 50,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 59,
                    "head": 6,
                    "id": 11,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 58,
                    "tag": "."
                  },
                  {
                    "dep": "dep",
                    "end": 61,
                    "head": 11,
                    "id": 12,
                    "lemma": " ",
                    "morph": "",
                    "pos": "SPACE",
                    "start": 60,
                    "tag": "_SP"
                  }
                ]
              },
              "r_err": 0.2,
              "sentence_score": 1,
              "sentence_w_verr": 0,
              "simple_sent_1": 0,
              "simple_sent_2": 0,
              "tag": "EX1",
              "vocabulary_err": [
                "R:OTHER",
                "R:OTHER"
              ],
              "vocabulary_sentence_score": 1
            },
            {
              "both_err": [],
              "cor_sent": "Sometimes it takes more than one or two hours to grade homework.",
              "cor_tokens": {
                "ents": [
                  {
                    "end": 32,
                    "label": "CARDINAL",
                    "start": 19
                  },
                  {
                    "end": 45,
                    "label": "TIME",
                    "start": 36
                  }
                ],
                "sents": [
                  {
                    "end": 64,
                    "start": 0
                  }
                ],
                "text": "Sometimes it takes more than one or two hours to grade homework.",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 9,
                    "head": 2,
                    "id": 0,
                    "lemma": "sometimes",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "nsubj",
                    "end": 12,
                    "head": 2,
                    "id": 1,
                    "lemma": "it",
                    "morph": "Case=Nom|Gender=Neut|Number=Sing|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 10,
                    "tag": "PRP"
                  },
                  {
                    "dep": "ROOT",
                    "end": 18,
                    "head": 2,
                    "id": 2,
                    "lemma": "take",
                    "morph": "Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "VERB",
                    "start": 13,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "amod",
                    "end": 23,
                    "head": 5,
                    "id": 3,
                    "lemma": "more",
                    "morph": "Degree=Cmp",
                    "pos": "ADJ",
                    "start": 19,
                    "tag": "JJR"
                  },
                  {
                    "dep": "quantmod",
                    "end": 28,
                    "head": 5,
                    "id": 4,
                    "lemma": "than",
                    "morph": "",
                    "pos": "ADP",
                    "start": 24,
                    "tag": "IN"
                  },
                  {
                    "dep": "nummod",
                    "end": 32,
                    "head": 8,
                    "id": 5,
                    "lemma": "one",
                    "morph": "NumType=Card",
                    "pos": "NUM",
                    "start": 29,
                    "tag": "CD"
                  },
                  {
                    "dep": "cc",
                    "end": 35,
                    "head": 5,
                    "id": 6,
                    "lemma": "or",
                    "morph": "ConjType=Cmp",
                    "pos": "CCONJ",
                    "start": 33,
                    "tag": "CC"
                  },
                  {
                    "dep": "conj",
                    "end": 39,
                    "head": 5,
                    "id": 7,
                    "lemma": "two",
                    "morph": "NumType=Card",
                    "pos": "NUM",
                    "start": 36,
                    "tag": "CD"
                  },
                  {
                    "dep": "dobj",
                    "end": 45,
                    "head": 2,
                    "id": 8,
                    "lemma": "hour",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 40,
                    "tag": "NNS"
                  },
                  {
                    "dep": "prep",
                    "end": 48,
                    "head": 8,
                    "id": 9,
                    "lemma": "to",
                    "morph": "",
                    "pos": "ADP",
                    "start": 46,
                    "tag": "IN"
                  },
                  {
                    "dep": "compound",
                    "end": 54,
                    "head": 11,
                    "id": 10,
                    "lemma": "grade",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 49,
                    "tag": "NN"
                  },
                  {
                    "dep": "pobj",
                    "end": 63,
                    "head": 9,
                    "id": 11,
                    "lemma": "homework",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 55,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 64,
                    "head": 2,
                    "id": 12,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 63,
                    "tag": "."
                  }
                ]
              },
              "edits": [
                "Orig: [0, 1, 'Sometime'], Cor: [0, 1, 'Sometimes'], Type: 'R:MORPH'",
                "Orig: [13, 14, ' '], Cor: [13, 13, ''], Type: 'U:SPACE'"
              ],
              "err_ids": [
                0,
                13
              ],
              "error_span": 1,
              "error_types": [
                "Orig: [0, 1, 'Sometime'], Cor: [0, 1, 'Sometimes'], Type: 'R:MORPH'",
                "Orig: [13, 14, ' '], Cor: [13, 13, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [
                "R:MORPH"
              ],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_startwith_because": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "Sometime it takes more than one or two hours to grade homework.  ",
              "org_tokens": {
                "ents": [
                  {
                    "end": 31,
                    "label": "CARDINAL",
                    "start": 18
                  },
                  {
                    "end": 44,
                    "label": "TIME",
                    "start": 35
                  }
                ],
                "sents": [
                  {
                    "end": 65,
                    "start": 0
                  }
                ],
                "text": "Sometime it takes more than one or two hours to grade homework.  ",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 8,
                    "head": 2,
                    "id": 0,
                    "lemma": "sometime",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "nsubj",
                    "end": 11,
                    "head": 2,
                    "id": 1,
                    "lemma": "it",
                    "morph": "Case=Nom|Gender=Neut|Number=Sing|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 9,
                    "tag": "PRP"
                  },
                  {
                    "dep": "ROOT",
                    "end": 17,
                    "head": 2,
                    "id": 2,
                    "lemma": "take",
                    "morph": "Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "VERB",
                    "start": 12,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "amod",
                    "end": 22,
                    "head": 5,
                    "id": 3,
                    "lemma": "more",
                    "morph": "Degree=Cmp",
                    "pos": "ADJ",
                    "start": 18,
                    "tag": "JJR"
                  },
                  {
                    "dep": "quantmod",
                    "end": 27,
                    "head": 5,
                    "id": 4,
                    "lemma": "than",
                    "morph": "",
                    "pos": "ADP",
                    "start": 23,
                    "tag": "IN"
                  },
                  {
                    "dep": "nummod",
                    "end": 31,
                    "head": 8,
                    "id": 5,
                    "lemma": "one",
                    "morph": "NumType=Card",
                    "pos": "NUM",
                    "start": 28,
                    "tag": "CD"
                  },
                  {
                    "dep": "cc",
                    "end": 34,
                    "head": 5,
                    "id": 6,
                    "lemma": "or",
                    "morph": "ConjType=Cmp",
                    "pos": "CCONJ",
                    "start": 32,
                    "tag": "CC"
                  },
                  {
                    "dep": "conj",
                    "end": 38,
                    "head": 5,
                    "id": 7,
                    "lemma": "two",
                    "morph": "NumType=Card",
                    "pos": "NUM",
                    "start": 35,
                    "tag": "CD"
                  },
                  {
                    "dep": "dobj",
                    "end": 44,
                    "head": 2,
                    "id": 8,
                    "lemma": "hour",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 39,
                    "tag": "NNS"
                  },
                  {
                    "dep": "prep",
                    "end": 47,
                    "head": 8,
                    "id": 9,
                    "lemma": "to",
                    "morph": "",
                    "pos": "ADP",
                    "start": 45,
                    "tag": "IN"
                  },
                  {
                    "dep": "compound",
                    "end": 53,
                    "head": 11,
                    "id": 10,
                    "lemma": "grade",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 48,
                    "tag": "NN"
                  },
                  {
                    "dep": "pobj",
                    "end": 62,
                    "head": 9,
                    "id": 11,
                    "lemma": "homework",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 54,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 63,
                    "head": 2,
                    "id": 12,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 62,
                    "tag": "."
                  },
                  {
                    "dep": "dep",
                    "end": 65,
                    "head": 12,
                    "id": 13,
                    "lemma": " ",
                    "morph": "",
                    "pos": "SPACE",
                    "start": 64,
                    "tag": "_SP"
                  }
                ]
              },
              "r_err": 0.08333333333333333,
              "sentence_score": 0,
              "sentence_w_verr": 0,
              "simple_sent_1": 0,
              "simple_sent_2": 0,
              "tag": "EX1",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            }
          ],
          "R": "In addition, having no homework will save teachers' time.",
          "R_sent": [
            {
              "both_err": [],
              "cor_sent": "In addition, not having homework will save teachers' time.",
              "cor_tokens": {
                "ents": [],
                "sents": [
                  {
                    "end": 58,
                    "start": 0
                  }
                ],
                "text": "In addition, not having homework will save teachers' time.",
                "tokens": [
                  {
                    "dep": "prep",
                    "end": 2,
                    "head": 7,
                    "id": 0,
                    "lemma": "in",
                    "morph": "",
                    "pos": "ADP",
                    "start": 0,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 11,
                    "head": 0,
                    "id": 1,
                    "lemma": "addition",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 3,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 12,
                    "head": 7,
                    "id": 2,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 11,
                    "tag": ","
                  },
                  {
                    "dep": "neg",
                    "end": 16,
                    "head": 4,
                    "id": 3,
                    "lemma": "not",
                    "morph": "Polarity=Neg",
                    "pos": "PART",
                    "start": 13,
                    "tag": "RB"
                  },
                  {
                    "dep": "csubj",
                    "end": 23,
                    "head": 7,
                    "id": 4,
                    "lemma": "have",
                    "morph": "Aspect=Prog|Tense=Pres|VerbForm=Part",
                    "pos": "VERB",
                    "start": 17,
                    "tag": "VBG"
                  },
                  {
                    "dep": "dobj",
                    "end": 32,
                    "head": 4,
                    "id": 5,
                    "lemma": "homework",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 24,
                    "tag": "NN"
                  },
                  {
                    "dep": "aux",
                    "end": 37,
                    "head": 7,
                    "id": 6,
                    "lemma": "will",
                    "morph": "VerbForm=Fin",
                    "pos": "AUX",
                    "start": 33,
                    "tag": "MD"
                  },
                  {
                    "dep": "ROOT",
                    "end": 42,
                    "head": 7,
                    "id": 7,
                    "lemma": "save",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 38,
                    "tag": "VB"
                  },
                  {
                    "dep": "poss",
                    "end": 51,
                    "head": 10,
                    "id": 8,
                    "lemma": "teacher",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 43,
                    "tag": "NNS"
                  },
                  {
                    "dep": "case",
                    "end": 52,
                    "head": 8,
                    "id": 9,
                    "lemma": "'",
                    "morph": "",
                    "pos": "PART",
                    "start": 51,
                    "tag": "POS"
                  },
                  {
                    "dep": "dobj",
                    "end": 57,
                    "head": 7,
                    "id": 10,
                    "lemma": "time",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 53,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 58,
                    "head": 7,
                    "id": 11,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 57,
                    "tag": "."
                  }
                ]
              },
              "edits": [
                "Orig: [3, 3, ''], Cor: [3, 4, 'not'], Type: 'M:ADV'",
                "Orig: [4, 5, 'no'], Cor: [5, 5, ''], Type: 'U:DET'",
                "Orig: [12, 13, ' '], Cor: [12, 12, ''], Type: 'U:SPACE'"
              ],
              "err_ids": [
                4,
                12
              ],
              "error_span": 0,
              "error_types": [
                "Orig: [3, 3, ''], Cor: [3, 4, 'not'], Type: 'M:ADV'",
                "Orig: [4, 5, 'no'], Cor: [5, 5, ''], Type: 'U:DET'",
                "Orig: [12, 13, ' '], Cor: [12, 12, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_startwith_because": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "In addition, having no homework will save teachers' time.  ",
              "org_tokens": {
                "ents": [],
                "sents": [
                  {
                    "end": 59,
                    "start": 0
                  }
                ],
                "text": "In addition, having no homework will save teachers' time.  ",
                "tokens": [
                  {
                    "dep": "prep",
                    "end": 2,
                    "head": 7,
                    "id": 0,
                    "lemma": "in",
                    "morph": "",
                    "pos": "ADP",
                    "start": 0,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 11,
                    "head": 0,
                    "id": 1,
                    "lemma": "addition",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 3,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 12,
                    "head": 7,
                    "id": 2,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 11,
                    "tag": ","
                  },
                  {
                    "dep": "csubj",
                    "end": 19,
                    "head": 7,
                    "id": 3,
                    "lemma": "have",
                    "morph": "Aspect=Prog|Tense=Pres|VerbForm=Part",
                    "pos": "VERB",
                    "start": 13,
                    "tag": "VBG"
                  },
                  {
                    "dep": "det",
                    "end": 22,
                    "head": 5,
                    "id": 4,
                    "lemma": "no",
                    "morph": "",
                    "pos": "DET",
                    "start": 20,
                    "tag": "DT"
                  },
                  {
                    "dep": "dobj",
                    "end": 31,
                    "head": 3,
                    "id": 5,
                    "lemma": "homework",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 23,
                    "tag": "NN"
                  },
                  {
                    "dep": "aux",
                    "end": 36,
                    "head": 7,
                    "id": 6,
                    "lemma": "will",
                    "morph": "VerbForm=Fin",
                    "pos": "AUX",
                    "start": 32,
                    "tag": "MD"
                  },
                  {
                    "dep": "ROOT",
                    "end": 41,
                    "head": 7,
                    "id": 7,
                    "lemma": "save",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 37,
                    "tag": "VB"
                  },
                  {
                    "dep": "poss",
                    "end": 50,
                    "head": 10,
                    "id": 8,
                    "lemma": "teacher",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 42,
                    "tag": "NNS"
                  },
                  {
                    "dep": "case",
                    "end": 51,
                    "head": 8,
                    "id": 9,
                    "lemma": "'",
                    "morph": "",
                    "pos": "PART",
                    "start": 50,
                    "tag": "POS"
                  },
                  {
                    "dep": "dobj",
                    "end": 56,
                    "head": 7,
                    "id": 10,
                    "lemma": "time",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 52,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 57,
                    "head": 7,
                    "id": 11,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 56,
                    "tag": "."
                  },
                  {
                    "dep": "dep",
                    "end": 59,
                    "head": 11,
                    "id": 12,
                    "lemma": " ",
                    "morph": "",
                    "pos": "SPACE",
                    "start": 58,
                    "tag": "_SP"
                  }
                ]
              },
              "r_err": 0.1111111111111111,
              "sentence_score": 0,
              "sentence_w_verr": 0,
              "simple_sent_1": 0,
              "simple_sent_2": 0,
              "tag": "R1",
              "vocabulary_err": [
                "M:ADV"
              ],
              "vocabulary_sentence_score": 0
            }
          ],
          "co_EX": "If there is homework, teachers spend much time on homework. Sometimes homework takes more than homework or two hours to grade homework.",
          "co_R": "In addition, having no homework will save teachers' time.",
          "no_conj_EX": "If there is homework teachers spend many time on homework Sometime it takes more than one or two hours to grade homework.",
          "no_conj_R": "Having no homework will save teachers' time.",
          "no_conj_co_EX": "If there is homework teachers spend much time on homework Sometimes homework takes more than homework or two hours to grade homework.",
          "no_conj_co_R": "Having no homework will save teachers' time."
        },
        "p2": {},
        "question_data": {
          "keywords": [
            ""
          ],
          "question_text": "Should students have homework?"
        }
      },
      "scoring_status": "Step4:scoring success"
    }
  },
  "level2": {
    "1": {
      "coherence": {
        "all_error_num": 0,
        "all_error_ratio": 0.0,
        "bf_error_pairs": [],
        "checklist_1": 0,
        "checklist_18": 0,
        "checklist_19": 0,
        "checklist_2": 0,
        "checklist_20": 0,
        "checklist_21": 0,
        "checklist_22": 0,
        "checklist_23": 1,
        "checklist_24": 0,
        "checklist_25": 0,
        "checklist_26": 0,
        "checklist_27": 0,
        "checklist_28": 0,
        "correct_sent_num": 6,
        "minimal_essay_score": 0,
        "num_wo_conj_sent": 2,
        "para_score": 0,
        "ratio_simple_conj": 1.0,
        "ratio_wo_conj_sent": 0.5,
        "sys_coherence": 3
      },
      "content": {
        "abnormal_response_score": null,
        "checklist_15": null,
        "checklist_16": null,
        "checklist_17": null,
        "len_filter_score": -1.0,
        "non_english_score": -1,
        "offtopic_score": -1,
        "opinion_label": 0,
        "p0_checklist_results": {
          "checklist_10": 0,
          "checklist_11": 0,
          "checklist_12": 0,
          "checklist_345": 0,
          "checklist_4": 0,
          "checklist_9": 0,
          "checklist_all": 0,
          "explanation_len": 8,
          "relevance_score": 1.0,
          "sent_feats": [
            0.0,
            0.0
          ]
        },
        "p1_checklist_results": {
          "checklist_10": 1,
          "checklist_11": 0,
          "checklist_12": 1,
          "checklist_345": 1,
          "checklist_4": 0,
          "checklist_9": 1,
          "checklist_all": 1,
          "explanation_len": 0,
          "relevance_score": 1.0,
          "sent_feats": [
            1.0,
            10
          ]
        },
        "sys_content": 3
      },
      "grammar_vocabulary": {
        "grammar_checklist_items": {
          "grammar_checklist_30": 0,
          "grammar_checklist_31": 0,
          "grammar_checklist_32": 0,
          "grammar_checklist_33": 0,
          "grammar_checklist_34": 1,
          "grammar_checklist_35": 0,
          "grammar_checklist_36": 0,
          "grammar_checklist_37": 0,
          "grammar_checklist_38": 0
        },
        "grammar_complexity_score": 0,
        "grammar_sentence_scores": [
          0,
          0,
          1,
          0
        ],
        "max_sent_len": 13,
        "mean_sent_len": 10.75,
        "n_orth_error": 0,
        "n_spell_error": 0,
        "numwds": 52,
        "sentences": [
          {
            "both_err": [],
            "cor_sent": "I think traveling with friends is better than traveling with family for high school students.",
            "err_ids": [
              16
            ],
            "error_span": 0,
            "error_types": [
              "Orig: [16, 17, ' '], Cor: [16, 16, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 1,
            "grammar_err": [],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 0,
            "org_sent": "I think traveling with friends is better than traveling with family for high school students.  ",
            "r_err": 0.0,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "Opinion",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [],
            "cor_sent": "I have two reasons.",
            "err_ids": [
              5
            ],
            "error_span": 0,
            "error_types": [
              "Orig: [5, 6, ' '], Cor: [5, 5, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 0,
            "org_sent": "I have two reasons.  ",
            "r_err": 0.0,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "Template",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [],
            "cor_sent": "First, it is fun to plan a trip with friends.",
            "err_ids": [
              12
            ],
            "error_span": 0,
            "error_types": [
              "Orig: [12, 13, ' '], Cor: [12, 12, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 0,
            "org_sent": "First, it is fun to plan a trip with friends.  ",
            "r_err": 0.0,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "R0",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [],
            "cor_sent": "We can talk about school and club activities.",
            "err_ids": [
              9
            ],
            "error_span": 0,
            "error_types": [
              "Orig: [9, 10, ' '], Cor: [9, 9, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 0,
            "org_sent": "We can talk about school and club activities.  ",
            "r_err": 0.0,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "EX0",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [
              "R:VERB"
            ],
            "cor_sent": "Second, we can save money.",
            "err_ids": [
              4,
              5,
              7
            ],
            "error_span": 2,
            "error_types": [
              "Orig: [4, 5, 'cost'], Cor: [4, 5, 'save'], Type: 'R:VERB'",
              "Orig: [5, 6, 'down'], Cor: [5, 6, 'money'], Type: 'R:OTHER'",
              "Orig: [7, 8, ' '], Cor: [7, 7, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 1,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [],
            "grammar_sentence_score": 1,
            "is_grammar_error_critical": 1,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 1,
            "n_err": 2,
            "org_sent": "Second, we can cost down.  ",
            "r_err": 0.4,
            "sentence_score": 1,
            "sentence_w_verr": 1,
            "tag": "R1",
            "vocabulary_err": [
              "R:OTHER"
            ],
            "vocabulary_sentence_score": 1
          },
          {
            "both_err": [],
            "cor_sent": "Therefore, traveling with friends is better for high school students.",
            "err_ids": [],
            "error_span": 0,
            "error_types": [],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 0,
            "org_sent": "Therefore, traveling with friends is better for high school students.",
            "r_err": 0.0,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "ELSE",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          }
        ],
        "sys_grammar": 3,
        "sys_grammar_max_sentence_score": 1,
        "sys_grammar_mean_sentence_score": 0.25,
        "sys_vocabulary": 3,
        "sys_vocabulary_max_sentence_score": 1,
        "sys_vocabulary_mean_sentence_score": 0.25,
        "vocabulary_checklist_items": {
          "vocabulary_checklist_30": 0,
          "vocabulary_checklist_31": 0,
          "vocabulary_checklist_32": 0,
          "vocabulary_checklist_33": 0,
          "vocabulary_checklist_34": 1,
          "vocabulary_checklist_35": 0,
          "vocabulary_checklist_36": 0,
          "vocabulary_checklist_37": 0,
          "vocabulary_checklist_38": 0
        },
        "vocabulary_complexity_score": 0.058823529411764705,
        "vocabulary_sentence_scores": [
          0,
          0,
          1,
          0
        ]
      },
      "input": {
        "input_text": "I think traveling with friends is better than traveling with family for high school students.  I have two reasons.  First, it is fun to plan a trip with friends.  We can talk about school and club activities.  Second, we can cost down.  Therefore, traveling with friends is better for high school students. ",
        "opinion": "I think traveling with friends is better than traveling with family for high school students.",
        "opinion_position": "yes",
        "p0": {
          "EX": "We can talk about school and club activities.",
          "EX_sent": [
            {
              "both_err": [],
              "cor_sent": "We can talk about school and club activities.",
              "cor_tokens": {
                "ents": [],
                "sents": [
                  {
                    "end": 45,
                    "start": 0
                  }
                ],
                "text": "We can talk about school and club activities.",
                "tokens": [
                  {
                    "dep": "nsubj",
                    "end": 2,
                    "head": 2,
                    "id": 0,
                    "lemma": "we",
                    "morph": "Case=Nom|Number=Plur|Person=1|PronType=Prs",
                    "pos": "PRON",
                    "start": 0,
                    "tag": "PRP"
                  },
                  {
                    "dep": "aux",
                    "end": 6,
                    "head": 2,
                    "id": 1,
                    "lemma": "can",
                    "morph": "VerbForm=Fin",
                    "pos": "AUX",
                    "start": 3,
                    "tag": "MD"
                  },
                  {
                    "dep": "ROOT",
                    "end": 11,
                    "head": 2,
                    "id": 2,
                    "lemma": "talk",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 7,
                    "tag": "VB"
                  },
                  {
                    "dep": "prep",
                    "end": 17,
                    "head": 2,
                    "id": 3,
                    "lemma": "about",
                    "morph": "",
                    "pos": "ADP",
                    "start": 12,
                    "tag": "IN"
                  },
                  {
                    "dep": "nmod",
                    "end": 24,
                    "head": 7,
                    "id": 4,
                    "lemma": "school",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 18,
                    "tag": "NN"
                  },
                  {
                    "dep": "cc",
                    "end": 28,
                    "head": 4,
                    "id": 5,
                    "lemma": "and",
                    "morph": "ConjType=Cmp",
                    "pos": "CCONJ",
                    "start": 25,
                    "tag": "CC"
                  },
                  {
                    "dep": "conj",
                    "end": 33,
                    "head": 4,
                    "id": 6,
                    "lemma": "club",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 29,
                    "tag": "NN"
                  },
                  {
                    "dep": "pobj",
                    "end": 44,
                    "head": 3,
                    "id": 7,
                    "lemma": "activity",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 34,
                    "tag": "NNS"
                  },
                  {
                    "dep": "punct",
                    "end": 45,
                    "head": 2,
                    "id": 8,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 44,
                    "tag": "."
                  }
                ]
              },
              "edits": [
                "Orig: [9, 10, ' '], Cor: [9, 9, ''], Type: 'U:SPACE'"
              ],
              "err_ids": [
                9
              ],
              "error_span": 0,
              "error_types": [
                "Orig: [9, 10, ' '], Cor: [9, 9, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_startwith_because": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "We can talk about school and club activities.  ",
              "org_tokens": {
                "ents": [],
                "sents": [
                  {
                    "end": 47,
                    "start": 0
                  }
                ],
                "text": "We can talk about school and club activities.  ",
                "tokens": [
                  {
                    "dep": "nsubj",
                    "end": 2,
                    "head": 2,
                    "id": 0,
                    "lemma": "we",
                    "morph": "Case=Nom|Number=Plur|Person=1|PronType=Prs",
                    "pos": "PRON",
                    "start": 0,
                    "tag": "PRP"
                  },
                  {
                    "dep": "aux",
                    "end": 6,
                    "head": 2,
                    "id": 1,
                    "lemma": "can",
                    "morph": "VerbForm=Fin",
                    "pos": "AUX",
                    "start": 3,
                    "tag": "MD"
                  },
                  {
                    "dep": "ROOT",
                    "end": 11,
                    "head": 2,
                    "id": 2,
                    "lemma": "talk",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 7,
                    "tag": "VB"
                  },
                  {
                    "dep": "prep",
                    "end": 17,
                    "head": 2,
                    "id": 3,
                    "lemma": "about",
                    "morph": "",
                    "pos": "ADP",
                    "start": 12,
                    "tag": "IN"
                  },
                  {
                    "dep": "nmod",
                    "end": 24,
                    "head": 7,
                    "id": 4,
                    "lemma": "school",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 18,
                    "tag": "NN"
                  },
                  {
                    "dep": "cc",
                    "end": 28,
                    "head": 4,
                    "id": 5,
                    "lemma": "and",
                    "morph": "ConjType=Cmp",
                    "pos": "CCONJ",
                    "start": 25,
                    "tag": "CC"
                  },
                  {
                    "dep": "conj",
                    "end": 33,
                    "head": 4,
                    "id": 6,
                    "lemma": "club",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 29,
                    "tag": "NN"
                  },
                  {
                    "dep": "pobj",
                    "end": 44,
                    "head": 3,
                    "id": 7,
                    "lemma": "activity",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 34,
                    "tag": "NNS"
                  },
                  {
                    "dep": "punct",
                    "end": 45,
                    "head": 2,
                    "id": 8,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 44,
                    "tag": "."
                  },
                  {
                    "dep": "dep",
                    "end": 47,
                    "head": 8,
                    "id": 9,
                    "lemma": " ",
                    "morph": "",
                    "pos": "SPACE",
                    "start": 46,
                    "tag": "_SP"
                  }
                ]
              },
              "r_err": 0.0,
              "sentence_score": 0,
              "sentence_w_verr": 0,
              "simple_sent_1": 0,
              "simple_sent_2": 0,
              "tag": "EX0",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            }
          ],
          "R": "First, it is fun to plan a trip with friends.",
          "R_sent": [
            {
              "both_err": [],
              "cor_sent": "First, it is fun to plan a trip with friends.",
              "cor_tokens": {
                "ents": [
                  {
                    "end": 5,
                    "label": "ORDINAL",
                    "start": 0
                  }
                ],
                "sents": [
                  {
                    "end": 45,
                    "start": 0
                  }
                ],
                "text": "First, it is fun to plan a trip with friends.",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 5,
                    "head": 3,
                    "id": 0,
                    "lemma": "first",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "punct",
                    "end": 6,
                    "head": 3,
                    "id": 1,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 5,
                    "tag": ","
                  },
                  {
                    "dep": "nsubj",
                    "end": 9,
                    "head": 3,
                    "id": 2,
                    "lemma": "it",
                    "morph": "Case=Nom|Gender=Neut|Number=Sing|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 7,
                    "tag": "PRP"
                  },
                  {
                    "dep": "ROOT",
                    "end": 12,
                    "head": 3,
                    "id": 3,
                    "lemma": "be",
                    "morph": "Mood=Ind|Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "AUX",
                    "start": 10,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "acomp",
                    "end": 16,
                    "head": 3,
                    "id": 4,
                    "lemma": "fun",
                    "morph": "Degree=Pos",
                    "pos": "ADJ",
                    "start": 13,
                    "tag": "JJ"
                  },
                  {
                    "dep": "aux",
                    "end": 19,
                    "head": 6,
                    "id": 5,
                    "lemma": "to",
                    "morph": "",
                    "pos": "PART",
                    "start": 17,
                    "tag": "TO"
                  },
                  {
                    "dep": "xcomp",
                    "end": 24,
                    "head": 3,
                    "id": 6,
                    "lemma": "plan",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 20,
                    "tag": "VB"
                  },
                  {
                    "dep": "det",
                    "end": 26,
                    "head": 8,
                    "id": 7,
                    "lemma": "a",
                    "morph": "Definite=Ind|PronType=Art",
                    "pos": "DET",
                    "start": 25,
                    "tag": "DT"
                  },
                  {
                    "dep": "dobj",
                    "end": 31,
                    "head": 6,
                    "id": 8,
                    "lemma": "trip",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 27,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 36,
                    "head": 8,
                    "id": 9,
                    "lemma": "with",
                    "morph": "",
                    "pos": "ADP",
                    "start": 32,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 44,
                    "head": 9,
                    "id": 10,
                    "lemma": "friend",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 37,
                    "tag": "NNS"
                  },
                  {
                    "dep": "punct",
                    "end": 45,
                    "head": 3,
                    "id": 11,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 44,
                    "tag": "."
                  }
                ]
              },
              "edits": [
                "Orig: [12, 13, ' '], Cor: [12, 12, ''], Type: 'U:SPACE'"
              ],
              "err_ids": [
                12
              ],
              "error_span": 0,
              "error_types": [
                "Orig: [12, 13, ' '], Cor: [12, 12, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_startwith_because": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 0,
              "org_sent": "First, it is fun to plan a trip with friends.  ",
              "org_tokens": {
                "ents": [
                  {
                    "end": 5,
                    "label": "ORDINAL",
                    "start": 0
                  }
                ],
                "sents": [
                  {
                    "end": 47,
                    "start": 0
                  }
                ],
                "text": "First, it is fun to plan a trip with friends.  ",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 5,
                    "head": 3,
                    "id": 0,
                    "lemma": "first",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "punct",
                    "end": 6,
                    "head": 3,
                    "id": 1,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 5,
                    "tag": ","
                  },
                  {
                    "dep": "nsubj",
                    "end": 9,
                    "head": 3,
                    "id": 2,
                    "lemma": "it",
                    "morph": "Case=Nom|Gender=Neut|Number=Sing|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 7,
                    "tag": "PRP"
                  },
                  {
                    "dep": "ROOT",
                    "end": 12,
                    "head": 3,
                    "id": 3,
                    "lemma": "be",
                    "morph": "Mood=Ind|Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "AUX",
                    "start": 10,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "acomp",
                    "end": 16,
                    "head": 3,
                    "id": 4,
                    "lemma": "fun",
                    "morph": "Degree=Pos",
                    "pos": "ADJ",
                    "start": 13,
                    "tag": "JJ"
                  },
                  {
                    "dep": "aux",
                    "end": 19,
                    "head": 6,
                    "id": 5,
                    "lemma": "to",
                    "morph": "",
                    "pos": "PART",
                    "start": 17,
                    "tag": "TO"
                  },
                  {
                    "dep": "xcomp",
                    "end": 24,
                    "head": 3,
                    "id": 6,
                    "lemma": "plan",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 20,
                    "tag": "VB"
                  },
                  {
                    "dep": "det",
                    "end": 26,
                    "head": 8,
                    "id": 7,
                    "lemma": "a",
                    "morph": "Definite=Ind|PronType=Art",
                    "pos": "DET",
                    "start": 25,
                    "tag": "DT"
                  },
                  {
                    "dep": "dobj",
                    "end": 31,
                    "head": 6,
                    "id": 8,
                    "lemma": "trip",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 27,
                    "tag": "NN"
                  },
                  {
                    "dep": "prep",
                    "end": 36,
                    "head": 8,
                    "id": 9,
                    "lemma": "with",
                    "morph": "",
                    "pos": "ADP",
                    "start": 32,
                    "tag": "IN"
                  },
                  {
                    "dep": "pobj",
                    "end": 44,
                    "head": 9,
                    "id": 10,
                    "lemma": "friend",
                    "morph": "Number=Plur",
                    "pos": "NOUN",
                    "start": 37,
                    "tag": "NNS"
                  },
                  {
                    "dep": "punct",
                    "end": 45,
                    "head": 3,
                    "id": 11,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 44,
                    "tag": "."
                  },
                  {
                    "dep": "dep",
                    "end": 47,
                    "head": 11,
                    "id": 12,
                    "lemma": " ",
                    "morph": "",
                    "pos": "SPACE",
                    "start": 46,
                    "tag": "_SP"
                  }
                ]
              },
              "r_err": 0.0,
              "sentence_score": 0,
              "sentence_w_verr": 0,
              "simple_sent_1": 0,
              "simple_sent_2": 0,
              "tag": "R0",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            }
          ],
          "co_EX": "High school students can talk about school and club activities.",
          "co_R": "First, planning a trip with friends is fun.",
          "no_conj_EX": "We can talk about school and club activities.",
          "no_conj_R": "It is fun to plan a trip with friends.",
          "no_conj_co_EX": "High school students can talk about school and club activities.",
          "no_conj_co_R": "Planning a trip with friends is fun."
        },
        "p1": {
          "EX": "",
          "EX_sent": [],
          "R": "Second, we can cost down.",
          "R_sent": [
            {
              "both_err": [
                "R:VERB"
              ],
              "cor_sent": "Second, we can save money.",
              "cor_tokens": {
                "ents": [
                  {
                    "end": 6,
                    "label": "ORDINAL",
                    "start": 0
                  }
                ],
                "sents": [
                  {
                    "end": 26,
                    "start": 0
                  }
                ],
                "text": "Second, we can save money.",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 6,
                    "head": 4,
                    "id": 0,
                    "lemma": "second",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "punct",
                    "end": 7,
                    "head": 4,
                    "id": 1,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 6,
                    "tag": ","
                  },
                  {
                    "dep": "nsubj",
                    "end": 10,
                    "head": 4,
                    "id": 2,
                    "lemma": "we",
                    "morph": "Case=Nom|Number=Plur|Person=1|PronType=Prs",
                    "pos": "PRON",
                    "start": 8,
                    "tag": "PRP"
                  },
                  {
                    "dep": "aux",
                    "end": 14,
                    "head": 4,
                    "id": 3,
                    "lemma": "can",
                    "morph": "VerbForm=Fin",
                    "pos": "AUX",
                    "start": 11,
                    "tag": "MD"
                  },
                  {
                    "dep": "ROOT",
                    "end": 19,
                    "head": 4,
                    "id": 4,
                    "lemma": "save",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 15,
                    "tag": "VB"
                  },
                  {
                    "dep": "dobj",
                    "end": 25,
                    "head": 4,
                    "id": 5,
                    "lemma": "money",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 20,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 26,
                    "head": 4,
                    "id": 6,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 25,
                    "tag": "."
                  }
                ]
              },
              "edits": [
                "Orig: [4, 5, 'cost'], Cor: [4, 5, 'save'], Type: 'R:VERB'",
                "Orig: [5, 6, 'down'], Cor: [5, 6, 'money'], Type: 'R:OTHER'",
                "Orig: [7, 8, ' '], Cor: [7, 7, ''], Type: 'U:SPACE'"
              ],
              "err_ids": [
                4,
                5,
                7
              ],
              "error_span": 2,
              "error_types": [
                "Orig: [4, 5, 'cost'], Cor: [4, 5, 'save'], Type: 'R:VERB'",
                "Orig: [5, 6, 'down'], Cor: [5, 6, 'money'], Type: 'R:OTHER'",
                "Orig: [7, 8, ' '], Cor: [7, 7, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 1,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 1,
              "is_grammar_error_critical": 1,
              "is_incomplete_sentence": 0,
              "is_startwith_because": 0,
              "is_vocabulary_error_critical": 1,
              "n_err": 2,
              "org_sent": "Second, we can cost down.  ",
              "org_tokens": {
                "ents": [
                  {
                    "end": 6,
                    "label": "ORDINAL",
                    "start": 0
                  }
                ],
                "sents": [
                  {
                    "end": 27,
                    "start": 0
                  }
                ],
                "text": "Second, we can cost down.  ",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 6,
                    "head": 4,
                    "id": 0,
                    "lemma": "second",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "punct",
                    "end": 7,
                    "head": 4,
                    "id": 1,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 6,
                    "tag": ","
                  },
                  {
                    "dep": "nsubj",
                    "end": 10,
                    "head": 4,
                    "id": 2,
                    "lemma": "we",
                    "morph": "Case=Nom|Number=Plur|Person=1|PronType=Prs",
                    "pos": "PRON",
                    "start": 8,
                    "tag": "PRP"
                  },
                  {
                    "dep": "aux",
                    "end": 14,
                    "head": 4,
                    "id": 3,
                    "lemma": "can",
                    "morph": "VerbForm=Fin",
                    "pos": "AUX",
                    "start": 11,
                    "tag": "MD"
                  },
                  {
                    "dep": "ROOT",
                    "end": 19,
                    "head": 4,
                    "id": 4,
                    "lemma": "cost",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 15,
                    "tag": "VB"
                  },
                  {
                    "dep": "prt",
                    "end": 24,
                    "head": 4,
                    "id": 5,
                    "lemma": "down",
                    "morph": "",
                    "pos": "ADP",
                    "start": 20,
                    "tag": "RP"
                  },
                  {
                    "dep": "punct",
                    "end": 25,
                    "head": 4,
                    "id": 6,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 24,
                    "tag": "."
                  },
                  {
                    "dep": "dep",
                    "end": 27,
                    "head": 6,
                    "id": 7,
                    "lemma": " ",
                    "morph": "",
                    "pos": "SPACE",
                    "start": 26,
                    "tag": "_SP"
                  }
                ]
              },
              "r_err": 0.4,
              "sentence_score": 1,
              "sentence_w_verr": 1,
              "simple_sent_1": 0,
              "simple_sent_2": 1,
              "tag": "R1",
              "vocabulary_err": [
                "R:OTHER"
              ],
              "vocabulary_sentence_score": 1
            }
          ],
          "co_EX": "",
          "co_R": "Second, we can cost down.",
          "no_conj_EX": "",
          "no_conj_R": "We can cost down.",
          "no_conj_co_EX": "",
          "no_conj_co_R": "We can cost down."
        },
        "p2": {},
        "question_data": {
          "keywords": [
            ""
          ],
          "question_text": "Do you think traveling with friends is better than traveling with family for high school students?"
        }
      },
      "scoring_status": "Step4:scoring success"
    }
  },
  "level1": {
    "1": {
      "coherence": {
        "all_error_num": 0,
        "all_error_ratio": 0.0,
        "bf_error_pairs": [],
        "checklist_1": 0,
        "checklist_18": 0,
        "checklist_19": 0,
        "checklist_2": 0,
        "checklist_20": 0,
        "checklist_21": 0,
        "checklist_22": 0,
        "checklist_23": 0,
        "checklist_24": 0,
        "checklist_25": 0,
        "checklist_26": 0,
        "checklist_27": 0,
        "checklist_28": 0,
        "correct_sent_num": 4,
        "minimal_essay_score": 0,
        "num_wo_conj_sent": 1,
        "para_score": 0,
        "ratio_simple_conj": 1.0,
        "ratio_wo_conj_sent": 0.3333333333333333,
        "sys_coherence": 4
      },
      "content": {
        "abnormal_response_score": null,
        "checklist_15": null,
        "checklist_16": null,
        "checklist_17": null,
        "len_filter_score": -1.0,
        "non_english_score": -1,
        "offtopic_score": -1,
        "opinion_label": 0,
        "p0_checklist_results": {
          "checklist_10": 0,
          "checklist_11": 0,
          "checklist_12": 0,
          "checklist_345": 0,
          "checklist_4": 0,
          "checklist_9": 0,
          "checklist_all": 0,
          "explanation_len": 6,
          "relevance_score": 1.0,
          "sent_feats": [
            1.0,
            1
          ]
        },
        "p1_checklist_results": {
          "checklist_10": 0,
          "checklist_11": 0,
          "checklist_12": 0,
          "checklist_345": 0,
          "checklist_4": 0,
          "checklist_9": 0,
          "checklist_all": 0,
          "explanation_len": 9,
          "relevance_score": 1.0,
          "sent_feats": [
            0.0,
            1
          ]
        },
        "sys_content": 4
      },
      "grammar_vocabulary": {
        "grammar_checklist_items": {
          "grammar_checklist_30": 0,
          "grammar_checklist_31": 0,
          "grammar_checklist_32": 0,
          "grammar_checklist_33": 0,
          "grammar_checklist_34": 1,
          "grammar_checklist_35": 0,
          "grammar_checklist_36": 0,
          "grammar_checklist_37": 0,
          "grammar_checklist_38": 0
        },
        "grammar_complexity_score": 0,
        "grammar_sentence_scores": [
          0,
          1,
          0
        ],
        "max_sent_len": 12,
        "mean_sent_len": 10.666666666666666,
        "n_orth_error": 0,
        "n_spell_error": 0,
        "numwds": 29,
        "sentences": [
          {
            "both_err": [
              "R:WO"
            ],
            "cor_sent": "The living room is the best place for me to study.",
            "err_ids": [
              0,
              6,
              7,
              8,
              9,
              11
            ],
            "error_span": 4,
            "error_types": [
              "Orig: [0, 1, 'Living'], Cor: [0, 2, 'The living'], Type: 'M:DET'",
              "Orig: [6, 10, 'to study for me'], Cor: [7, 11, 'for me to study'], Type: 'R:WO'",
              "Orig: [11, 12, ' '], Cor: [12, 12, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 1,
            "org_sent": "Living room is the best place to study for me.  ",
            "r_err": 0.1,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "Opinion",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [],
            "cor_sent": "I have two reasons.",
            "err_ids": [
              5
            ],
            "error_span": 0,
            "error_types": [
              "Orig: [5, 6, ' '], Cor: [5, 5, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 0,
            "org_sent": "I have two reasons.  ",
            "r_err": 0.0,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "Template",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          },
          {
            "both_err": [
              "M:OTHER"
            ],
            "cor_sent": "First, my mother teaches me how to study.",
            "err_ids": [
              8
            ],
            "error_span": 0,
            "error_types": [
              "Orig: [6, 6, ''], Cor: [6, 8, 'how to'], Type: 'M:OTHER'",
              "Orig: [8, 9, ' '], Cor: [10, 10, ''], Type: 'U:SPACE'"
            ],
            "gpt_sentence_score": 1,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [],
            "grammar_sentence_score": 1,
            "is_grammar_error_critical": 1,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 1,
            "n_err": 1,
            "org_sent": "First, my mother teaches me study.  ",
            "r_err": 0.16666666666666666,
            "sentence_score": 1,
            "sentence_w_verr": 0,
            "tag": "R0",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 1
          },
          {
            "both_err": [],
            "cor_sent": "Second, it is very quiet and I can relax.",
            "err_ids": [
              2
            ],
            "error_span": 1,
            "error_types": [
              "Orig: [2, 3, 'there'], Cor: [2, 3, 'it'], Type: 'R:PRON'"
            ],
            "gpt_sentence_score": 0,
            "gpt_unintelligible_score": -1,
            "grammar_complexity_label": 0,
            "grammar_err": [
              "R:PRON"
            ],
            "grammar_sentence_score": 0,
            "is_grammar_error_critical": 0,
            "is_incomplete_sentence": 0,
            "is_vocabulary_error_critical": 0,
            "n_err": 1,
            "org_sent": "Second, there is very quiet and I can relax.",
            "r_err": 0.1111111111111111,
            "sentence_score": 0,
            "sentence_w_verr": 0,
            "tag": "R1",
            "vocabulary_err": [],
            "vocabulary_sentence_score": 0
          }
        ],
        "sys_grammar": 3,
        "sys_grammar_max_sentence_score": 1,
        "sys_grammar_mean_sentence_score": 0.3333333333333333,
        "sys_vocabulary": 3,
        "sys_vocabulary_max_sentence_score": 1,
        "sys_vocabulary_mean_sentence_score": 0.3333333333333333,
        "vocabulary_checklist_items": {
          "vocabulary_checklist_30": 0,
          "vocabulary_checklist_31": 0,
          "vocabulary_checklist_32": 0,
          "vocabulary_checklist_33": 0,
          "vocabulary_checklist_34": 1,
          "vocabulary_checklist_35": 0,
          "vocabulary_checklist_36": 0,
          "vocabulary_checklist_37": 0,
          "vocabulary_checklist_38": 0
        },
        "vocabulary_complexity_score": 0.0,
        "vocabulary_sentence_scores": [
          0,
          1,
          0
        ]
      },
      "input": {
        "input_text": "Living room is the best place to study for me.  I have two reasons.  First, my mother teaches me study.  Second, there is very quiet and I can relax. ",
        "opinion": "Living room is the best place to study for me.",
        "opinion_position": "living room",
        "p0": {
          "EX": "",
          "EX_sent": [],
          "R": "First, my mother teaches me study.",
          "R_sent": [
            {
              "both_err": [
                "M:OTHER"
              ],
              "cor_sent": "First, my mother teaches me how to study.",
              "cor_tokens": {
                "ents": [
                  {
                    "end": 5,
                    "label": "ORDINAL",
                    "start": 0
                  }
                ],
                "sents": [
                  {
                    "end": 41,
                    "start": 0
                  }
                ],
                "text": "First, my mother teaches me how to study.",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 5,
                    "head": 4,
                    "id": 0,
                    "lemma": "first",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "punct",
                    "end": 6,
                    "head": 4,
                    "id": 1,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 5,
                    "tag": ","
                  },
                  {
                    "dep": "poss",
                    "end": 9,
                    "head": 3,
                    "id": 2,
                    "lemma": "my",
                    "morph": "Number=Sing|Person=1|Poss=Yes|PronType=Prs",
                    "pos": "PRON",
                    "start": 7,
                    "tag": "PRP$"
                  },
                  {
                    "dep": "nsubj",
                    "end": 16,
                    "head": 4,
                    "id": 3,
                    "lemma": "mother",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 10,
                    "tag": "NN"
                  },
                  {
                    "dep": "ROOT",
                    "end": 24,
                    "head": 4,
                    "id": 4,
                    "lemma": "teach",
                    "morph": "Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "VERB",
                    "start": 17,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "dobj",
                    "end": 27,
                    "head": 4,
                    "id": 5,
                    "lemma": "I",
                    "morph": "Case=Acc|Number=Sing|Person=1|PronType=Prs",
                    "pos": "PRON",
                    "start": 25,
                    "tag": "PRP"
                  },
                  {
                    "dep": "advmod",
                    "end": 31,
                    "head": 8,
                    "id": 6,
                    "lemma": "how",
                    "morph": "",
                    "pos": "SCONJ",
                    "start": 28,
                    "tag": "WRB"
                  },
                  {
                    "dep": "aux",
                    "end": 34,
                    "head": 8,
                    "id": 7,
                    "lemma": "to",
                    "morph": "",
                    "pos": "PART",
                    "start": 32,
                    "tag": "TO"
                  },
                  {
                    "dep": "xcomp",
                    "end": 40,
                    "head": 4,
                    "id": 8,
                    "lemma": "study",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 35,
                    "tag": "VB"
                  },
                  {
                    "dep": "punct",
                    "end": 41,
                    "head": 4,
                    "id": 9,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 40,
                    "tag": "."
                  }
                ]
              },
              "edits": [
                "Orig: [6, 6, ''], Cor: [6, 8, 'how to'], Type: 'M:OTHER'",
                "Orig: [8, 9, ' '], Cor: [10, 10, ''], Type: 'U:SPACE'"
              ],
              "err_ids": [
                8
              ],
              "error_span": 0,
              "error_types": [
                "Orig: [6, 6, ''], Cor: [6, 8, 'how to'], Type: 'M:OTHER'",
                "Orig: [8, 9, ' '], Cor: [10, 10, ''], Type: 'U:SPACE'"
              ],
              "gpt_sentence_score": 1,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [],
              "grammar_sentence_score": 1,
              "is_grammar_error_critical": 1,
              "is_incomplete_sentence": 0,
              "is_startwith_because": 0,
              "is_vocabulary_error_critical": 1,
              "n_err": 1,
              "org_sent": "First, my mother teaches me study.  ",
              "org_tokens": {
                "ents": [
                  {
                    "end": 5,
                    "label": "ORDINAL",
                    "start": 0
                  }
                ],
                "sents": [
                  {
                    "end": 36,
                    "start": 0
                  }
                ],
                "text": "First, my mother teaches me study.  ",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 5,
                    "head": 4,
                    "id": 0,
                    "lemma": "first",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "punct",
                    "end": 6,
                    "head": 4,
                    "id": 1,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 5,
                    "tag": ","
                  },
                  {
                    "dep": "poss",
                    "end": 9,
                    "head": 3,
                    "id": 2,
                    "lemma": "my",
                    "morph": "Number=Sing|Person=1|Poss=Yes|PronType=Prs",
                    "pos": "PRON",
                    "start": 7,
                    "tag": "PRP$"
                  },
                  {
                    "dep": "nsubj",
                    "end": 16,
                    "head": 4,
                    "id": 3,
                    "lemma": "mother",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 10,
                    "tag": "NN"
                  },
                  {
                    "dep": "ROOT",
                    "end": 24,
                    "head": 4,
                    "id": 4,
                    "lemma": "teach",
                    "morph": "Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "VERB",
                    "start": 17,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "dative",
                    "end": 27,
                    "head": 4,
                    "id": 5,
                    "lemma": "I",
                    "morph": "Case=Acc|Number=Sing|Person=1|PronType=Prs",
                    "pos": "PRON",
                    "start": 25,
                    "tag": "PRP"
                  },
                  {
                    "dep": "dobj",
                    "end": 33,
                    "head": 4,
                    "id": 6,
                    "lemma": "study",
                    "morph": "Number=Sing",
                    "pos": "NOUN",
                    "start": 28,
                    "tag": "NN"
                  },
                  {
                    "dep": "punct",
                    "end": 34,
                    "head": 4,
                    "id": 7,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 33,
                    "tag": "."
                  },
                  {
                    "dep": "dep",
                    "end": 36,
                    "head": 7,
                    "id": 8,
                    "lemma": " ",
                    "morph": "",
                    "pos": "SPACE",
                    "start": 35,
                    "tag": "_SP"
                  }
                ]
              },
              "r_err": 0.16666666666666666,
              "sentence_score": 1,
              "sentence_w_verr": 0,
              "simple_sent_1": 0,
              "simple_sent_2": 0,
              "tag": "R0",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 1
            }
          ],
          "co_EX": "",
          "co_R": "First, my mother teaches Living room study.",
          "no_conj_EX": "",
          "no_conj_R": "My mother teaches me study.",
          "no_conj_co_EX": "",
          "no_conj_co_R": "My mother teaches Living room study."
        },
        "p1": {
          "EX": "",
          "EX_sent": [],
          "R": "Second, there is very quiet and I can relax.",
          "R_sent": [
            {
              "both_err": [],
              "cor_sent": "Second, it is very quiet and I can relax.",
              "cor_tokens": {
                "ents": [
                  {
                    "end": 6,
                    "label": "ORDINAL",
                    "start": 0
                  }
                ],
                "sents": [
                  {
                    "end": 41,
                    "start": 0
                  }
                ],
                "text": "Second, it is very quiet and I can relax.",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 6,
                    "head": 3,
                    "id": 0,
                    "lemma": "second",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "punct",
                    "end": 7,
                    "head": 3,
                    "id": 1,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 6,
                    "tag": ","
                  },
                  {
                    "dep": "nsubj",
                    "end": 10,
                    "head": 3,
                    "id": 2,
                    "lemma": "it",
                    "morph": "Case=Nom|Gender=Neut|Number=Sing|Person=3|PronType=Prs",
                    "pos": "PRON",
                    "start": 8,
                    "tag": "PRP"
                  },
                  {
                    "dep": "ROOT",
                    "end": 13,
                    "head": 3,
                    "id": 3,
                    "lemma": "be",
                    "morph": "Mood=Ind|Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "AUX",
                    "start": 11,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "advmod",
                    "end": 18,
                    "head": 5,
                    "id": 4,
                    "lemma": "very",
                    "morph": "",
                    "pos": "ADV",
                    "start": 14,
                    "tag": "RB"
                  },
                  {
                    "dep": "acomp",
                    "end": 24,
                    "head": 3,
                    "id": 5,
                    "lemma": "quiet",
                    "morph": "Degree=Pos",
                    "pos": "ADJ",
                    "start": 19,
                    "tag": "JJ"
                  },
                  {
                    "dep": "cc",
                    "end": 28,
                    "head": 3,
                    "id": 6,
                    "lemma": "and",
                    "morph": "ConjType=Cmp",
                    "pos": "CCONJ",
                    "start": 25,
                    "tag": "CC"
                  },
                  {
                    "dep": "nsubj",
                    "end": 30,
                    "head": 9,
                    "id": 7,
                    "lemma": "I",
                    "morph": "Case=Nom|Number=Sing|Person=1|PronType=Prs",
                    "pos": "PRON",
                    "start": 29,
                    "tag": "PRP"
                  },
                  {
                    "dep": "aux",
                    "end": 34,
                    "head": 9,
                    "id": 8,
                    "lemma": "can",
                    "morph": "VerbForm=Fin",
                    "pos": "AUX",
                    "start": 31,
                    "tag": "MD"
                  },
                  {
                    "dep": "conj",
                    "end": 40,
                    "head": 3,
                    "id": 9,
                    "lemma": "relax",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 35,
                    "tag": "VB"
                  },
                  {
                    "dep": "punct",
                    "end": 41,
                    "head": 9,
                    "id": 10,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 40,
                    "tag": "."
                  }
                ]
              },
              "edits": [
                "Orig: [2, 3, 'there'], Cor: [2, 3, 'it'], Type: 'R:PRON'"
              ],
              "err_ids": [
                2
              ],
              "error_span": 1,
              "error_types": [
                "Orig: [2, 3, 'there'], Cor: [2, 3, 'it'], Type: 'R:PRON'"
              ],
              "gpt_sentence_score": 0,
              "gpt_unintelligible_score": -1,
              "grammar_complexity_label": 0,
              "grammar_err": [
                "R:PRON"
              ],
              "grammar_sentence_score": 0,
              "is_grammar_error_critical": 0,
              "is_incomplete_sentence": 0,
              "is_startwith_because": 0,
              "is_vocabulary_error_critical": 0,
              "n_err": 1,
              "org_sent": "Second, there is very quiet and I can relax.",
              "org_tokens": {
                "ents": [
                  {
                    "end": 6,
                    "label": "ORDINAL",
                    "start": 0
                  }
                ],
                "sents": [
                  {
                    "end": 44,
                    "start": 0
                  }
                ],
                "text": "Second, there is very quiet and I can relax.",
                "tokens": [
                  {
                    "dep": "advmod",
                    "end": 6,
                    "head": 3,
                    "id": 0,
                    "lemma": "second",
                    "morph": "",
                    "pos": "ADV",
                    "start": 0,
                    "tag": "RB"
                  },
                  {
                    "dep": "punct",
                    "end": 7,
                    "head": 3,
                    "id": 1,
                    "lemma": ",",
                    "morph": "PunctType=Comm",
                    "pos": "PUNCT",
                    "start": 6,
                    "tag": ","
                  },
                  {
                    "dep": "expl",
                    "end": 13,
                    "head": 3,
                    "id": 2,
                    "lemma": "there",
                    "morph": "",
                    "pos": "PRON",
                    "start": 8,
                    "tag": "EX"
                  },
                  {
                    "dep": "ROOT",
                    "end": 16,
                    "head": 3,
                    "id": 3,
                    "lemma": "be",
                    "morph": "Mood=Ind|Number=Sing|Person=3|Tense=Pres|VerbForm=Fin",
                    "pos": "VERB",
                    "start": 14,
                    "tag": "VBZ"
                  },
                  {
                    "dep": "advmod",
                    "end": 21,
                    "head": 5,
                    "id": 4,
                    "lemma": "very",
                    "morph": "",
                    "pos": "ADV",
                    "start": 17,
                    "tag": "RB"
                  },
                  {
                    "dep": "acomp",
                    "end": 27,
                    "head": 3,
                    "id": 5,
                    "lemma": "quiet",
                    "morph": "Degree=Pos",
                    "pos": "ADJ",
                    "start": 22,
                    "tag": "JJ"
                  },
                  {
                    "dep": "cc",
                    "end": 31,
                    "head": 3,
                    "id": 6,
                    "lemma": "and",
                    "morph": "ConjType=Cmp",
                    "pos": "CCONJ",
                    "start": 28,
                    "tag": "CC"
                  },
                  {
                    "dep": "nsubj",
                    "end": 33,
                    "head": 9,
                    "id": 7,
                    "lemma": "I",
                    "morph": "Case=Nom|Number=Sing|Person=1|PronType=Prs",
                    "pos": "PRON",
                    "start": 32,
                    "tag": "PRP"
                  },
                  {
                    "dep": "aux",
                    "end": 37,
                    "head": 9,
                    "id": 8,
                    "lemma": "can",
                    "morph": "VerbForm=Fin",
                    "pos": "AUX",
                    "start": 34,
                    "tag": "MD"
                  },
                  {
                    "dep": "conj",
                    "end": 43,
                    "head": 3,
                    "id": 9,
                    "lemma": "relax",
                    "morph": "VerbForm=Inf",
                    "pos": "VERB",
                    "start": 38,
                    "tag": "VB"
                  },
                  {
                    "dep": "punct",
                    "end": 44,
                    "head": 9,
                    "id": 10,
                    "lemma": ".",
                    "morph": "PunctType=Peri",
                    "pos": "PUNCT",
                    "start": 43,
                    "tag": "."
                  }
                ]
              },
              "r_err": 0.1111111111111111,
              "sentence_score": 0,
              "sentence_w_verr": 0,
              "simple_sent_1": 0,
              "simple_sent_2": 0,
              "tag": "R1",
              "vocabulary_err": [],
              "vocabulary_sentence_score": 0
            }
          ],
          "co_EX": "",
          "co_R": "Second, Living room is very quiet and I can relax.",
          "no_conj_EX": "",
          "no_conj_R": "There is very quiet and I can relax.",
          "no_conj_co_EX": "",
          "no_conj_co_R": "Living room is very quiet and I can relax."
        },
        "p2": {},
        "question_data": {
          "checklist_91": "Is your best study place _",
          "checklist_92": "Is it why _ is your best place to study",
          "checklist_93": "Do you like to study in _",
          "keywords": [
            ""
          ],
          "question_text": "Where is the best place to study for you?"
        }
      },
      "scoring_status": "Step4:scoring success"
    }
  }
};

export {
  Statement,
  SampleEssay,
  SampleResult,
  LengthLimitation
}
