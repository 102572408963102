import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck as farCircleCheck } from '@fortawesome/free-regular-svg-icons'
import HeaderedScore from './HeaderedScore';

class ContentCommentary extends React.Component {

  render() {
    if (!this.props.content) {
      return null;
    }
    let details = []
    
    if (this.props.content.offtopic_score == 0) {
      details.push('質問に関係のない内容の解答です。');
    }
    if (this.props.content.checklist_15) {
      details.push('適切なリード文がありません。');
    }
    if (this.props.content.checklist_16) {
      details.push('適切な結論がありません。');
    }
    if (this.props.content.checklist_17) {
      details.push('リード文と結論の関係性が明確ではありません。');
    }
    let contentDetails = details.map((datail, i) => {
      return (
        <div key={i}>
          <FontAwesomeIcon icon={farCircleCheck}/><span> {datail}</span>
        </div>
      );
    });
    
    let details_optional = []
    if (this.props.content.opinion_label) {
      details_optional.push('意見がないか、意見と質問との関係性が強くありません。');
    }
    let contentOptionalDetails = null;
    let contentOptionalHeader = null;
    if (details_optional.length > 0) {
      contentOptionalDetails = details_optional.map((datail, i) => {
        return (
          <div key={i}>
            <FontAwesomeIcon icon={farCircleCheck}/><span> {datail}</span>
          </div>
        )
      });
      contentOptionalHeader = (
        <div className='mt-1'>
          <span>以下の点に気をつけると更に良くなるかもしれません。</span>
        </div>
      )
    }
    return (
      <Container className="p-3">
        <Card>
          <Card.Header as="h5">評価：内容</Card.Header>
          <Card.Body>
            <HeaderedScore score={this.props.content.sys_content} header="内容："/>
            {contentDetails}
            {contentOptionalHeader}
            {contentOptionalDetails}
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

export default ContentCommentary;
