export const criteriaData = {
  1: {
    0: {
      'description': ' 納豆の作り方について分かったことを、【作文】の内容から、言葉や文を取り上げて書く。',
      'components': {
        'criteria1': {
          'type': 'no-show',
          'neg': '納豆の作り方について分かったことを、【作文】から言葉や文を取り上げて書きましょう。',
          'pos': '納豆の作り方について分かったことを、【作文】から言葉や文を取り上げて書けています。',
        }
      }
    },
    1: {
      'description':'丁寧な言葉で書く。',
      'components': {
        'is_right_japanese': {
          'type': 'negative-only',
          'neg': '文が不明瞭です。'
        },
        'right_revision_detection_in_polite_style': {
          'type': 'binary-negbasis',
          'neg': '次のように丁寧語で書きましょう。',
          'pos': '丁寧語で書けています。',
        },
        'polite_style': {
          'type': 'binary-negbasis',
          'neg': '次のように丁寧語で書きましょう。',
          'pos': '丁寧語で書けています。',
        }
      }
    },
    2: {
      'description':'四十字以上、六十字以内にまとめて書く。',
      'components': {
        'length_limit': {
          'type': 'no-show',
          'neg': '四十字以上、六十字以内にまとめて書きましょう。',
          'pos': '四十字以上、六十字以内にまとめて書けています。'
        }
      }
    },
    3: {
      'description':'正しい文法や言葉を使う。',
      'components': {
        'validate_right_japanese': {
          'type': 'no-show'
        },
        'is_right_japanese': {
          'type': 'negative-only',
          'neg': '文が不明瞭です。'
        },
        'grammatical_error_detection': {
          'type': 'tn-list-show-diff',
          'neg': '正しい文法や言葉を使いましょう。',
          'nut': '正しい文法や言葉を使えていますが、以下のように直すとより良くなるかもしれません。',
          'pos': '正しい文法や言葉を使えています。',
          'supplimental': '特に以下の点に気をつけましょう',
          'correction_commentary':{
            '平仮名の漢字への訂正': 'ひらがなを漢字にしましょう。',
            '内容語の訂正・追加・削除': '適切な言葉を使いましょう。',
            '機能語の訂正・追加・削除': '正しい文法を使いましょう。',
            '句読点の使い方の訂正': '正しく句読点を使いましょう。',
            '敬語表現の訂正': '丁寧な言葉に直しましょう。',
            '書き言葉[です等]から話し言葉[ですね等]への訂正': '話し言葉に直しましょう。',
            'その他の訂正': null
          }
        }
      }
    },
    4: {
      'description':'【作文】を読んだ感想を書く。',
      'components': {
        'include_impressions': {
          'type': 'no-show',
          'neg': '作文の感想を述べるようにしましょう。',
          'pos': '作文の感想を述べられています。'
        }
      }
    }
  }
}