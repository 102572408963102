export const errors = {
    'ADJ': '形容詞を正しく使いましょう。',
    'ADJ:FORM': '比較級・最上級を正しく使いましょう。２つのものを比較するときには"-er"を使用します。例："nicer"。３つ以上を比較するときには"-est"を使用します。例："nicest"。',
    'ADV': '副詞を正しく使いましょう。',
    'CONJ': '接続詞を正しく使いましょう。',
    'CONTR': '短縮形を正しく使いましょう。例：dont (X), don\'t (○)',
    'DET': '限定詞を正しく使いましょう。',
    'MORPH': '語の形を正しく使いましょう。',
    'NOUN': '名詞を正しく使いましょう。',
    'NOUN:INFL': '不可算名詞を正しく使いましょう。例：baggages(X), baggage(O)',
    'NOUN:NUM': '複数形を正しく使いましょう。',
    'NOUN:POSS': '所有格を正しく使いましょう。例：my school volunteer work(X), my school\'s volunteer work(O)',
    'ORTH': '大文字小文字やスペースを正しく使いましょう。',
    'PART': '熟語を正しく使いましょう。',
    'PREP': '前置詞を正しく使いましょう。例：He agreed with my demands. (X),  He agreed to my demands. (O)',
    'PRON': '代名詞を正しく使いましょう。例：There is relaxing (X), It is relaxing (O)',
    'PUNCT': '句読点を正しく使いましょう。',
    'SPELL': '正しいスペルで書きましょう。',
    'VERB': '動詞を正しく使いましょう。',
    'VERB:FORM': '不定詞、動名詞、分詞を正しく使いましょう。例：I have free time enjoy them (X), I have free time to enjoy them (O).',
    'VERB:INFL': '動詞の形を正しく使いましょう。例：getted (X), got (O)',
    'VERB:SVA': '主語に合わせて動詞を正しく選びましょう。主語が単数形の時は動詞も単数形。主語が複数形の時は動詞も複数形。',
    'VERB:TENSE': '動詞の時制を正しく使いましょう',
    'WO': '正しい語順で書きましょう',
};