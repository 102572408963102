import Star from './Star'

function StarLine(props) {
    if (props.total == null || props.numStar == null) {
        return null;
    }
    let stars = [];
    for (let i = 0; i < props.total; i++) {
        if (i < props.numStar) {
            stars.push(<Star score={1} />);
        }
        else{
            stars.push(<Star score={0} />);
        }
    }
    return (
        <div>
            {stars}
        </div>
    )
}

export default StarLine;