import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck as farCircleCheck } from '@fortawesome/free-regular-svg-icons'
import HeaderedScore from './HeaderedScore';
import TextCorrection from './TextCorrection';

class CoherenceCommentary extends React.Component {

  render() {
    if (!this.props.coherence) {
      return null;
    }
    let org_txt = this.props.org_txt ?? '';
    let cor_txt = this.props.cor_txt ?? '';

    let details = []
    if (this.props.coherence.checklist_1) {
      details.push('設問の指示に従った構成になっていません。');
    }
    if (this.props.coherence.checklist_2) {
      details.push('文書が短すぎます。');
    }
    if (this.props.coherence.checklist_18) {
      details.push('主張の理由にあたる部分がありません。');
    }
    if (this.props.coherence.checklist_19) {
      details.push('文のつながりが明確ではありません。');
    }
    if (this.props.coherence.checklist_20) {
      details.push('文書の長さが十分ではありません。');
    }
    if (this.props.coherence.checklist_21) {
      details.push('論理的な文章になっていません。');
    }
    if (this.props.coherence.checklist_22) {
      details.push('文が行ったり来たりしています。');
    }
    if (this.props.coherence.checklist_23) {
      details.push('リード文、理由、理由を裏付ける説明のいずれかが欠落しています。');
    }
    if (this.props.coherence.checklist_24) {
      details.push('接続詞を間違って使用しています。');
    }
    if (this.props.coherence.checklist_25) {
      details.push('シンプルな接続詞を使用しています。');
    }
    if (this.props.coherence.checklist_26) {
      details.push('文同士のつながりが明確ではありません。');
    }
    if (this.props.coherence.checklist_27) {
      details.push('段落分けされていません。');
    }
    if (this.props.coherence.checklist_28) {
      details.push('明確な段落分けがされていません');
    }
    let coherenceDetails;
    if (details.length == 0) {
      coherenceDetails = (
        <div>
          コメントはありません。
        </div>
      );
    } else {
      coherenceDetails = details.map((datail, i) => {
          return (
            <div key={i}>
              <FontAwesomeIcon icon={farCircleCheck}/><span> {datail}</span>
            </div>
          );
        });
    }

    return (
      <Container className="p-3">
        <Card>
          <Card.Header as="h5">評価・解説：構成</Card.Header>
          <Card.Body>
            <HeaderedScore score={this.props.coherence.sys_coherence} header="構成："/>
            <h5>
              <TextCorrection org_txt={org_txt} cor_txt={cor_txt}/>
            </h5>
            {coherenceDetails}
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

export default CoherenceCommentary;
