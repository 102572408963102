import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck as farCircleCheck } from '@fortawesome/free-regular-svg-icons'
import { errors } from './ErrorDescriptions';


function ErrorDetail(props) {
  
  let errorCode = props.code.substring(2);
  let errorText = errors[errorCode]
  if (!errorText) {
    return null;
  }
  return (
    <div>
      <FontAwesomeIcon icon={farCircleCheck}/><span> {errorText}</span>
    </div>
  )
}

export default ErrorDetail;
