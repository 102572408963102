import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaceSmile as farFaceSmile } from '@fortawesome/free-regular-svg-icons'
import { faFaceFrown as farFaceFrown } from '@fortawesome/free-regular-svg-icons'

function ComplexityScore(props) {
  if (props.complex == null) {
    return null;
  }
  if (props.complex == true) {
    return <div><h5>{props.header}複雑度：<FontAwesomeIcon icon={farFaceSmile} style={{color: "green"}}/></h5></div>
  }
  return <div><h5>{props.header}複雑度：<FontAwesomeIcon icon={farFaceFrown} style={{color: "red"}}/></h5></div>
}

export default ComplexityScore;
