import { Statement } from './SampleData';
import QuestionData from './QuestionData';

function QuestionStatement(props) {
  const points = QuestionData[props.selected_level][props.question_no]["keywords"].map(
    (point, index) => {
      if (point == "") {
        return null;
      }
      else {
        return <li>{point}</li>
      }
    }
  ).filter(x => x != null);
  let points_list = null
  if (points.length != 0) {
    points_list = <h5><ul>{points}</ul></h5>
  }
  return (
    <div>
      <h5>{Statement[props.selected_level]}</h5>
      {points_list}
      <h5 style={{'marginTop': '2rem'}}>質問</h5>
      <h5>{QuestionData[props.selected_level][props.question_no]["question_text"]}</h5>
    </div>
  )
}

export default QuestionStatement;