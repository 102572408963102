import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons'
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons'

function Star(props) {
  return (
    <span>
      <FontAwesomeIcon icon={fasStar} style={{color: "orange", display: props.score>0 ? '' : 'none'}} />
      <FontAwesomeIcon icon={farStar} style={{display: props.score==0 ? '' : 'none'}} />
    </span>
  )
}

export default Star;
