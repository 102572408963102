import Score from './Score';

function HeaderedScore(props) {
  return (
    <div>
      <h5>{props.header} <Score score={props.score}/></h5>
    </div>
  );
}

export default HeaderedScore;
