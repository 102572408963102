import React from 'react';
import ReactDOM from 'react-dom/client';

import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter
} from 'react-router-dom';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "bootstrap/dist/js/bootstrap.bundle.min";

import JapaneseApp from './japanese/App';
import EnglishApp from './english/App';
import reportWebVitals from './reportWebVitals';
import NaviBar from './NaviBar';
import App from './App';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {path: "/", element: <EnglishApp />},
      {path: "/japanese", element: <JapaneseApp />},
      {path: "/english", element: <EnglishApp />}
    ]
  },
  // {
  //   path: "japanese",
  //   element: <JapaneseApp />,
  // },
  // {
  //   path: "english",
  //   element: <EnglishApp />,
  // }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
