import './App.css';

import React, { Component } from "react";
import ReactDOM from 'react-dom/client'
import ReactDOMServer from 'react-dom/server'
import Container from 'react-bootstrap/Container';
import { Button, Row, Col } from 'react-bootstrap';
import { Dna } from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleDot as fasCircle } from '@fortawesome/free-solid-svg-icons'
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ComplexityScore from './ComplexityScore';
import HeaderedScore from './HeaderedScore';
import SentenceCommentary from './SentenceCommentary';
import ContentCommentary from './ContentCommentary';
import ReasonContentCommentary from './ReasonContentCommentary';
import CoherenceCommentary from './CoherenceCommentary';
import { SampleEssay, SampleResult, LengthLimitation } from './SampleData';
import QuestionStatement from './QuestionStatement';

class Content extends React.Component {
  constructor(props) {
    super(props);
    const initial_level = 'level3';
    const initial_question_no = '1';
    this.textareaRef = React.createRef();
    this.state = {
      essay: SampleEssay[initial_level][initial_question_no],
      selected_level: initial_level,
      question_no: initial_question_no,
      length_limitation: LengthLimitation[initial_level],
      loading : false,
      content: 0,
      coherence: 0,
      grammar: 0,
      vocabulary: 0,
      grammar_complexity: null,
      vocabulary_complex: null,
      contentResult : {
        p0_checklist_results: null,
        p1_checklist_results: null,
      },
      coherenceResult: null,
      showResult : true
    };
  }

  componentDidMount() {
    this.displayResult(SampleResult[this.state.selected_level][this.state.question_no]);
  }

  handleQuestionChange = (event) => {
    this.setState({
      essay: SampleEssay[event][this.state.question_no],
      selected_level: event,
      length_limitation: LengthLimitation[event],
      loading : false,
      showResult : true,
    });
    this.displayResult(SampleResult[event][this.state.question_no]);
    this.textareaRef.current.value = SampleEssay[event][this.state.question_no];
    this.textareaRef.current.focus();
  }

  handleEssayChange = (event) => {
    this.setState({
      essay : event.target.value,
      loading : false,
      content: this.state.content,
      coherence: this.state.coherence,
      grammar: this.state.grammar,
      vocabulary: this.state.vocabulary,
      grammar_complex: this.state.grammar_complex,
      vocabulary_complex: this.state.vocabulary_complex,
      contentResult : this.state.contentResult,
      coherenceResult : this.state.coherenceResult,
      showResult : true,
    });
  }

  handleContentSubmit = async (event) => {
    this.setState({
      essay : this.state.essay,
      loading : true,
      content: 0,
      coherence: 0,
      grammar: 0,
      vocabulary: 0,
      grammar_complex: false,
      vocabulary_complex: false,
      contentResult : this.state.contentResult,
      coherenceResult : this.state.coherenceResult,
      showResult : false,
    });

    const reqOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        mode: 'cors',
        body: JSON.stringify({
          essay: this.state.essay,
          selected_level: this.state.selected_level,
          question_no: this.state.question_no
        })
    };
    const response = await fetch("/score/english", reqOptions);
    const result = await response.json();

    this.displayResult(result);
  }

  displayResult(result) {
    let grade = this.state.selected_level;

    let grammar_complex = null;
    if (grade == "level4") {
      grammar_complex = result.grammar_vocabulary.grammar_complexity_score >= 4;
    } else if (['level1', 'level2', 'level3'].includes(grade)) {
      grammar_complex = result.grammar_vocabulary.grammar_complexity_score >= 1;
    }

    let vocabulary_complex = null;
    if (grade == "level4") {
      vocabulary_complex = result.grammar_vocabulary.vocabulary_complexity_score >= 0.37;
    } else if (['level1', 'level2', 'level3'].includes(grade)) {
      vocabulary_complex = result.grammar_vocabulary.vocabulary_complexity_score >= 0.15;
    }

    let p0_org_txt = '';
    let p0_cor_txt = '';
    let p1_org_txt = '';
    let p1_cor_txt = '';
    let org_txt = '';
    let cor_txt = '';
    let sentences = result.grammar_vocabulary.sentences;
    if (sentences) {
      for (let i = 0; i < sentences.length; i++) {
        org_txt += sentences[i].org_sent + ' ';
        cor_txt += sentences[i].cor_sent + ' ';
        if (sentences[i].tag == 'R0' || sentences[i].tag == 'EX0') {
          p0_org_txt += sentences[i].org_sent + ' ';
          p0_cor_txt += sentences[i].cor_sent + ' ';
        }
        if (sentences[i].tag == 'R1' || sentences[i].tag == 'EX1') {
          p1_org_txt += sentences[i].org_sent + ' ';
          p1_cor_txt += sentences[i].cor_sent + ' ';
        }
      }
    }

    this.setState({
      loading : false,
      content: result.content.sys_content,
      coherence: result.coherence.sys_coherence,
      grammar: result.grammar_vocabulary.sys_grammar,
      vocabulary: result.grammar_vocabulary.sys_vocabulary,
      grammar_complex: grammar_complex,
      vocabulary_complex: vocabulary_complex,
      contentResult: result.content,
      coherenceResult: result.coherence,
      p0_org_txt: p0_org_txt,
      p0_cor_txt: p0_cor_txt,
      p1_org_txt: p1_org_txt,
      p1_cor_txt: p1_cor_txt,
      org_txt: org_txt,
      cor_txt: cor_txt,
      showResult : true,
    })
    const breakdown = document.getElementById('breakdown');
    const fragment = document.createDocumentFragment();
    if (result.grammar_vocabulary.sentences) {
      for (let sent of result.grammar_vocabulary.sentences) {
        const div_sent = document.createElement('div');
        div_sent.innerHTML = ReactDOMServer.renderToString(<SentenceCommentary sentence={sent}/>);

        fragment.appendChild(div_sent);
      }
    }
    breakdown.replaceChildren(fragment);

    const content_breakdown = document.getElementById('content_breakdown');
    const fragment_content = document.createDocumentFragment();
    const div_content = document.createElement('div');
    if (result.content.offtopic_score == -1){
      let content1 = ReactDOMServer.renderToString(
        <ReasonContentCommentary 
          checklist_results={result.content.p0_checklist_results} 
          header='解説：内容（理由１）' 
          org_txt={p0_org_txt} 
          cor_txt={p0_cor_txt}/>
      );
      let content2 = ReactDOMServer.renderToString(
        <ReasonContentCommentary 
          checklist_results={result.content.p1_checklist_results} 
          header='解説：内容（理由２）' 
          org_txt={p1_org_txt} 
          cor_txt={p1_cor_txt}/>
      );
      div_content.innerHTML = content1+content2
    }
    fragment_content.appendChild(div_content);
    content_breakdown.replaceChildren(fragment_content);
  }

  handleTextAreaSize(e) {
    e.target.style.height = 'inherit';
    e.target.style.height = `${e.target.scrollHeight}px`;
    // e.target.style.height = `${Math.min(e.target.scrollHeight, 800)}px`;
  }

  render() {
    return (
      <Container className="p-5">
        <Container className="p-3">
          <h1>英作文自動採点サービス（デモ版）</h1>
        </Container>
        <Container className="p-3 justify-content-start">
          <Row className="align-items-center p-0 m-0">
            <Col xs="auto" className='p-0 m-0'>
              <h5 className='m-1'>問題レベル：</h5>
            </Col>
            <Col xs="auto" className='p-0 m-0'>
              <DropdownButton 
                id="dropdown-basic-button" 
                title={this.state.selected_level.replace('level', 'レベル')} 
                onSelect={this.handleQuestionChange} 
                variant="secondary"
              >
                {
                  ['レベル4', 'レベル3', 'レベル2', 'レベル1'].map((level, index) => {
                    const ekey = level.replace('レベル', 'level');
                    if (ekey == this.state.selected_level) {
                      return <Dropdown.Item key={index} eventKey={ekey} active>{level}</Dropdown.Item>
                    }
                    else {
                      return <Dropdown.Item key={index} eventKey={ekey}>{level}</Dropdown.Item>
                    }
                  })
                }
              </DropdownButton>
            </Col>
          </Row>
        </Container>
        <Container className="p-3">
          <Card>
            <Card.Header as="h5">設問</Card.Header>
            <Card.Body>
              <QuestionStatement selected_level={this.state.selected_level} question_no={this.state.question_no} />
            </Card.Body>
          </Card>
          </Container>
        <Container className="p-3">
          <textarea 
            className="form-control" 
            ref={this.textareaRef} 
            id="text" 
            name="text" 
            value={this.state.essay} 
            onChange={this.handleEssayChange} 
            rows={3} 
            maxLength={this.state.length_limitation} 
            onKeyDown={this.handleTextAreaSize} 
            placeholder="Enter the essay." 
            autoFocus={true} 
            onFocus={this.handleTextAreaSize} 
          />
        </Container>
        <Container className="p-3">
          <button className={`text-capitalize btn btn-primary btn-md fw-bold`} disabled={!this.state.essay} onClick={this.handleContentSubmit}>採点</button>
        </Container>
        <Container className="p-3" style={{display: this.state.loading ? '' : 'none'}}>
          <Dna height="80" width="80" ariaLabel="dna-loading" wrapperStyle={{}} wrapperClass="dna-wrapper"/>
        </Container>
        <Container className="p-3" style={{display: this.state.showResult ? '' : 'none'}} >
          <Card>
            <Card.Header as="h3">総合点：</Card.Header>
            <Card.Body>
              <HeaderedScore score={this.state.content} header="内容："/>
              <HeaderedScore score={this.state.coherence} header="構成："/>
              <HeaderedScore score={this.state.grammar} header="文法："/>
              <HeaderedScore score={this.state.vocabulary} header="語彙："/>
            </Card.Body>
          </Card>
        </Container>
        <div style={{display: this.state.showResult ? '' : 'none'}}>
          <ContentCommentary content={this.state.contentResult}/>
          <span id="content_breakdown" />
          <CoherenceCommentary coherence={this.state.coherenceResult} style={{display: this.state.showResult ? '' : 'none'}} org_txt={this.state.org_txt} cor_txt={this.state.cor_txt}/>
          <Container className="p-3">
            <Card>
              <Card.Header as="h5">評価：文法と語彙</Card.Header>
              <Card.Body>
                <HeaderedScore score={this.state.grammar} header="文法："/>
                <HeaderedScore score={this.state.vocabulary} header="語彙："/>
                <ComplexityScore header="文法" complex={this.state.grammar_complex}/>
                <ComplexityScore header="語彙" complex={this.state.vocabulary_complex}/>
              </Card.Body>
            </Card>
          </Container>
          <span id="breakdown" />
        </div>
      </Container>
    );
  }
}

class App extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: '#F4F7FD'}}>
        <Content/>
      </div>
    );
  }
}


export default App;
