import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleCheck as farCircleCheck } from '@fortawesome/free-regular-svg-icons'
import TextCorrection from './TextCorrection';

class ReasonContentCommentary extends React.Component {

  render() {
    let org_txt = this.props.org_txt ?? '';
    let cor_txt = this.props.cor_txt ?? '';
    let details = []
    if (this.props.checklist_results) {
      if (this.props.checklist_results.checklist_345) {
        details.push('理由の解説がありません。');
      }
      if (this.props.checklist_results.checklist_4) {
        details.push('理由がありません。');
      }
      if (this.props.checklist_results.checklist_9) {
        details.push('トピックと理由の関係性が明確ではありません。');
      }
      if (this.props.checklist_results.checklist_10) {
        details.push('主観的な意見に留まり、理由が不十分です。');
      }
      if (this.props.checklist_results.checklist_11) {
        details.push('英単語を使用してください。');
      }
      if (this.props.checklist_results.checklist_12) {
        details.push('理由が不完全な文です。');
      }
    }
    let contentDetails;
    if (details.length == 0) {
      contentDetails = (
        <div>
          コメントはありません。
        </div>
      );
    } else {
      contentDetails = details.map((datail, i) => {
        return (
          <div key={i}>
            <FontAwesomeIcon icon={farCircleCheck}/><span> {datail}</span>
          </div>
        );
      });
    }

    return (
      <Container className="p-3" style={this.props.style}>
        <Card>
          <Card.Header as="h5">{this.props.header}</Card.Header>
          <Card.Body>
            <h5>
              <TextCorrection org_txt={org_txt} cor_txt={cor_txt}/>
            </h5>
            {contentDetails}
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

export default ReasonContentCommentary;
