var SampleQuestion = {
  "question_no": 1
};

var SampleEssay = {
  '誤答例': "納豆は、聖徳太子が初めて作ったことが分かった。そんなに古い時代から納豆に作られていることを知らなかった。",
  '正答例': "納豆は、蒸した大豆に納豆菌をかけて保温すると作れることが分かりました。菌だけで大豆が納豆に変わることにおどろきました。",
};

var SampleResult = {
  "誤答例": {
    "criteria": [
      {
        "No": 3,
        "is_satisfied": false,
        "components": {
          "grammatical_error_detection": [
            false,
            [
              [
                "納豆に",
                "納豆が",
                "機能語の訂正・追加・削除",
                true
              ]
            ]
          ],
          "is_right_japanese": true
        },
        "cot": {
          "grammatical_error_detection": null,
          "is_right_japanese": null
        },
        "auxiliary_components": {
          "grammatical_error_correction": [
            false,
            "\"納豆は、聖徳太子が初めて作ったことが分かった。そんなに古い時代から納豆が作られていることを知らなかった。\""
          ]
        },
        "auxiliary_cot": {
          "grammatical_error_correction": {
            "原文": "\"納豆は、聖徳太子が初めて作ったことが分かった。そんなに古い時代から納豆に作られていることを知らなかった。\""
          }
        }
      },
      {
        "No": 4,
        "is_satisfied": true,
        "components": {
          "include_impressions": true
        },
        "cot": {
          "include_impressions": null
        },
        "auxiliary_components": null,
        "auxiliary_cot": null
      },
      {
        "No": 0,
        "is_satisfied": false,
        "components": {
          "criteria1": false
        },
        "cot": {
          "criteria1": null
        },
        "auxiliary_components": null,
        "auxiliary_cot": null
      },
      {
        "No": 1,
        "is_satisfied": false,
        "components": {
          "right_revision_detection_in_polite_style": [
            false,
            [
              [
                "分かった",
                "分かりました",
                "敬語への訂正",
                true
              ],
              [
                "知らなかった",
                "知りませんでした",
                "敬語への訂正",
                true
              ]
            ]
          ],
          "is_right_japanese": true
        },
        "cot": {
          "right_revision_detection_in_polite_style": null,
          "is_right_japanese": null
        },
        "auxiliary_components": {
          "polite_style": [
            false,
            "\"分かった\"→\"分かりました\", \"知らなかった\"→\"知りませんでした\""
          ]
        },
        "auxiliary_cot": {
          "polite_style": {
            "1. 全ての文末の列挙": "\"分かった\", \"知らなかった\""
          }
        }
      },
      {
        "No": 2,
        "is_satisfied": true,
        "components": {
          "length_limit": true
        },
        "cot": {
          "length_limit": null
        },
        "auxiliary_components": null,
        "auxiliary_cot": null
      }
    ],
    "ruikei": "D",
    "holistic_score": false,
    "answer_text": "納豆は、聖徳太子が初めて作ったことが分かった。そんなに古い時代から納豆に作られていることを知らなかった。",
    "question_no": 1
  },
  '正答例': {
    "criteria": [
      {
        "No": 3,
        "is_satisfied": true,
        "components": {
          "grammatical_error_detection": [
            true,
            [
              [
                "おどろきました。",
                "驚きました。",
                "平仮名から漢字への変換",
                false
              ]
            ]
          ],
          "is_right_japanese": true
        },
        "cot": {
          "grammatical_error_detection": null,
          "is_right_japanese": null
        },
        "auxiliary_components": {
          "grammatical_error_correction": [
            false,
            "\"納豆は、蒸した大豆に納豆菌をかけて保温すると作れることが分かりました。菌だけで大豆が納豆に変わることに驚きました。\""
          ]
        },
        "auxiliary_cot": {
          "grammatical_error_correction": {
            "原文": "\"納豆は、蒸した大豆に納豆菌をかけて保温すると作れることが分かりました。菌だけで大豆が納豆に変わることにおどろきました。\""
          }
        }
      },
      {
        "No": 4,
        "is_satisfied": true,
        "components": {
          "include_impressions": true
        },
        "cot": {
          "include_impressions": null
        },
        "auxiliary_components": null,
        "auxiliary_cot": null
      },
      {
        "No": 0,
        "is_satisfied": true,
        "components": {
          "criteria1": true
        },
        "cot": {
          "criteria1": null
        },
        "auxiliary_components": null,
        "auxiliary_cot": null
      },
      {
        "No": 1,
        "is_satisfied": true,
        "components": {
          "right_revision_detection_in_polite_style": [
            true,
            null
          ],
          "is_right_japanese": true
        },
        "cot": {
          "right_revision_detection_in_polite_style": null,
          "is_right_japanese": null
        },
        "auxiliary_components": {
          "polite_style": [
            true,
            null
          ]
        },
        "auxiliary_cot": {
          "polite_style": {
            "1. 全ての文末の列挙": "\"分かりました\", \"おどろきました\""
          }
        }
      },
      {
        "No": 2,
        "is_satisfied": true,
        "components": {
          "length_limit": true
        },
        "cot": {
          "length_limit": null
        },
        "auxiliary_components": null,
        "auxiliary_cot": null
      }
    ],
    "ruikei": "A",
    "holistic_score": true,
    "answer_text": "納豆は、蒸した大豆に納豆菌をかけて保温すると作れることが分かりました。菌だけで大豆が納豆に変わることにおどろきました。",
    "question_no": 1
  }  
}

export {
  SampleQuestion,
  SampleEssay,
  SampleResult
}
