import React from "react";
import { Outlet } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import NaviBar from './NaviBar'
import Footer from './Footer'

class App extends React.Component {
  render() {
    return (
      <div>
        <NaviBar />
        <Outlet />
        <Footer />
      </div>
    )
  }
}
export default App;