import React, { Component } from "react";
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import ComplexityScore from './ComplexityScore';
import SentenceScore from './SentenceScore';
import ErrorDetails from './ErrorDetails';
import TextCorrection from './TextCorrection';


class SentenceCommentary extends React.Component {
  render() {
    let sentence = this.props.sentence;
    const org_sent = sentence.org_sent
    const cor_sent = sentence.cor_sent
    const tag = sentence.tag


    // sentence header
    let headerTexts = {
      'Intro': '解説：文法と語彙（リード文）',
      'Opinion': '解説：文法と語彙（リード文）：',
      'Template': '解説：文法と語彙（リード文続き）',
      'R0': '解説：文法と語彙（理由１）',
      'EX0': '解説：文法と語彙（理由１続き）',
      'R1': '解説：文法と語彙（理由２）',
      'EX1': '解説：文法と語彙（理由２続き）',
      'ELSE': '解説：文法と語彙（結論）',
      'Conclusion': '解説：文法と語彙（結論）',
    }
    let headerText = headerTexts[tag]

    return (
      <Container className="p-3">
        <Card>
          <Card.Header as="h5">{headerText}</Card.Header>
          <Card.Body>
            <h5>
              <TextCorrection org_txt={org_sent} cor_txt={cor_sent}/>
            </h5>
            <Container className="p-2">
              <SentenceScore score={sentence.grammar_sentence_score} header='文法：'/>
              <ComplexityScore header="文法" complex={sentence.grammar_complexity_label == 1}/>
              <ErrorDetails errors={sentence.grammar_err} header='文法のアドバイス：'/>
              <SentenceScore score={sentence.vocabulary_sentence_score} header='語彙：'/>
              <ErrorDetails errors={sentence.vocabulary_err} header='語彙のアドバイス：'/>
              <ErrorDetails errors={sentence.both_err} header='共通のアドバイス：'/>
            </Container>
          </Card.Body>
        </Card>
      </Container>
    );
  }
}

export default SentenceCommentary;
