import React from "react";
import HeaderedPosNegScore from "./HeaderedPosNegScore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFaceSmile as farFaceSmile } from '@fortawesome/free-regular-svg-icons'
import { faFaceFrown as farFaceFrown } from '@fortawesome/free-regular-svg-icons'
import TextCorrection from './TextCorrection';
import { faCircleCheck as farCircleCheck } from '@fortawesome/free-regular-svg-icons'
import WordTransition from "./WordTransition";

class CriteriaEachCommentary extends React.Component {
  render() {
    if (
      this.props.criteria == null 
      || this.props.criteriaData == null
      || this.props.original_essay == null
    ) {
      return null;
    }
    let criteriaComponents = [];
    const binaryOnly = 'binary-only';
    const binaryNegBasis = 'binary-negbasis';
    const BinaryNegRef = 'binary-negref';
    const NoShow = 'no-show';
    const TNListShowDiff = 'tn-list-show-diff';
    const NegativeOnly = 'negative-only';
    for (const key in this.props.criteria.components) {
      if (this.props.criteriaData.components[key] == null){
        continue;
      }
      if (this.props.criteriaData.components[key].type == binaryOnly){
        if (this.props.criteria.components[key] === false){
          criteriaComponents.push([
            false, 
            this.props.criteriaData.components[key].neg, 
            null,
            null,
            null
          ])
        }
        else{
          criteriaComponents.push([
            true, 
            this.props.criteriaData.components[key].pos, 
            null,
            null,
            null
          ])
        }
      }
      else if (this.props.criteriaData.components[key].type == NegativeOnly) {
        if (this.props.criteria.components[key] === false){
          criteriaComponents.push([
            false, 
            this.props.criteriaData.components[key].neg, 
            null,
            null,
            null
          ])
        }
      }
      else if (this.props.criteriaData.components[key].type == binaryNegBasis) {
        if (this.props.criteria.components[key][0] === false){
          let correctionList = null;
          if (Array.isArray(this.props.criteria.components[key][1])){
            correctionList = this.props.criteria.components[key][1].map((item, index) => {
              if (item[3]){
                return (
                  <div><WordTransition before={item[0]} after={item[1]}/></div>
                )
              }
              else{
                return null;
              }
            });
            correctionList = correctionList.filter(item => item !== null);
          } 
          else {
            correctionList = [<div>{this.props.criteria.components[key][1]}</div>]
          }
          criteriaComponents.push([
            false, 
            this.props.criteriaData.components[key].neg, 
            correctionList,
            null,
            null
          ])
        }
        else{
          ;
        }
      }
      else if (this.props.criteriaData.components[key].type == BinaryNegRef) {
        if (this.props.criteria.components[key] === false){
          criteriaComponents.push([
            false, 
            this.props.criteriaData.components[key].neg, 
            [this.props.criteria.cot[key][this.props.criteriaData.components[key].negref]],
            null,
            null
          ])
        }
        else{
          criteriaComponents.push([
            true,
            this.props.criteriaData.components[key].pos, 
            null,
            null,
            null
          ])
        }
      }
      else if (this.props.criteriaData.components[key].type == TNListShowDiff) {
        let correctionDiff = null;
        try{
           correctionDiff = (
            <div className="ms-4 mt-2 mb-2 h5">
              <TextCorrection 
                org_txt={this.props.original_essay} 
                cor_txt={this.props.criteria.auxiliary_components.grammatical_error_correction[1].slice(1, -1)} 
              />
            </div>
          );
        } catch (error) {
          correctionDiff = null;
        }
        let componentJudgement = true;
        let criticalErrorsList = [];
        for (const element of this.props.criteria.components[key][1]){
          if (element[3] === true){
            componentJudgement = false;
            criticalErrorsList.push(
              <div>
                {this.props.criteriaData.components[key].correction_commentary[element[2]] != null 
                 ? <div>{this.props.criteriaData.components[key].correction_commentary[element[2]]}</div>
                 : null}
                <div><WordTransition before={element[0]} after={element[1]}/></div>
              </div>
            )
          }
        }
        let commentary = null;
        let supplimental = null;
        if (componentJudgement === false){
          commentary = this.props.criteriaData.components[key].neg;
          supplimental = this.props.criteriaData.components[key].supplimental;
        }
        else if (this.props.criteria.components[key][1].length > 0) {
          commentary = this.props.criteriaData.components[key].nut;
        }
        else {
          commentary = null;
          correctionDiff = null;
        }
        if (commentary != null) {
          criteriaComponents.push([
            componentJudgement,
            commentary,
            criticalErrorsList.length > 0 ? criticalErrorsList : null,
            correctionDiff,
            supplimental
          ])
        }
        else{
          ;
        }
      }
      else if (this.props.criteriaData.components[key].type == NoShow) {
        ;
      }
      else{
        ;
      }
    }
    
    let componentsCommentary = criteriaComponents.map((component, i) => {
      let elements = null;
      if (component[2] != null){
        let elementList = component[2].map((element, j) => {
          return (
            <li>{element}</li>
          )
        });
        elements = <ul className="ms-3">{elementList}</ul>
      }
      else{
        elements = null;
      }
      let diff = null;
      if (component[3] != null){
        diff = component[3];
      }
      else{
        diff = null;
      }
      let supplimental = null;
      if (component[4] != null){
        supplimental = component[4];
      }
      return (
        <div className="ms-2">
          <FontAwesomeIcon icon={farCircleCheck} /><span> {component[1]}</span>
          {diff}
          <FontAwesomeIcon icon={farCircleCheck} style={{'visibility': 'hidden'}}/><span> {supplimental}</span>
          {elements}
        </div>
      )
    });

    return (
      <div>
        <HeaderedPosNegScore 
          header={this.props.criteriaData.description} 
          score={this.props.criteria.is_satisfied}/>
        {componentsCommentary}
      </div>
    );
  }
}

export default CriteriaEachCommentary;