const Diff = require('diff');
class TextDiff {
  mergeDiffs(diff, addSpace) {
    const mergedDiff = [];
    let currentRemovedWords = '';
    let currentAddedWords = '';
    diff.forEach((part) => {
      // console.log(part);
      if (!part.removed && !part.added) {
        if (part.value != ' ') {
          if (currentRemovedWords) {
            mergedDiff.push({
              value: currentRemovedWords,
              added: false,
              removed: true,
            });
            if (addSpace) {
              mergedDiff.push({
                value: ' ',
                added: false,
                removed: false
              })
            }
          }
          if (currentAddedWords) {
            mergedDiff.push({
              value: currentAddedWords,
              added: true,
              removed: false,
            });
          }
          mergedDiff.push(part);
          currentRemovedWords = '';
          currentAddedWords = '';
        } else {
          currentAddedWords += ' ';
          currentRemovedWords += ' ';
        }
      } else if (part.removed) {
        currentRemovedWords += part.value;
      } else if (part.added) {
        currentAddedWords += part.value;
      }
    });
    if (currentRemovedWords) {
      mergedDiff.push({
        value: currentRemovedWords,
        added: false,
        removed: true,
      });
      if (addSpace) {
        mergedDiff.push({
          value: ' ',
          added: false,
          removed: false
        })
      }
    }
    if (currentAddedWords) {
      mergedDiff.push({
        value: currentAddedWords,
        added: true,
        removed: false,
      });
    }

    return mergedDiff;
  }

  getDiff(org_txt, corrected_txt) {
    const diff = Diff.diffWords(org_txt, corrected_txt);
    const mergedDiff = this.mergeDiffs(diff, true);
    return mergedDiff;
  }
}

export default TextDiff;
