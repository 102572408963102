import Container from 'react-bootstrap/Container';
import ErrorDetail from './ErrorDetail';
import { errors as errorLists} from './ErrorDescriptions';

function removeDuplicates(arr) {
  return arr.filter((item, index) => arr.indexOf(item) === index);
}

function ErrorDetails(props) {
  let errors = props.errors;
  if (!errors || errors.length == 0) {
    return null;
  }
  let errorCount = 0;
  errors = removeDuplicates(errors);
  let errorDetails = errors.map((error, i) => {
    let errorText = errorLists[error.substring(2)];
    if (errorText) {
      errorCount += 1;
    }
    return (<ErrorDetail code={error} key={i}/>);
  });
  if (errorCount == 0) {
    return null;
  }
  return (
    <Container className="p-3">
      <span>{props.header}</span>
      {errorDetails}
    </Container>
  );
}

export default ErrorDetails;
